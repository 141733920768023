import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, DatePicker, Form, Image, Input, Row, Select, Skeleton, Switch, message } from 'antd';
import { getServicesDetailsList, getServicesDetailsListError, getServicesDetailsListLoading } from '../../../../redux/selectors/selectors';
import { editServiceAmount, editServiceBookBefore, editServiceDate, editServiceNotes, editServiceStatus, editServiceTitle, editServiceType,editServiceCapacity, fetchDailyHoroscope, fetchNotificationDetails, fetchRegionalNotifi, fetchServicesDetails, updateNotificationDetails } from '../../../../redux/actions/acions';
import deftemples from '../../../../assets/images/templeicon.jpg'
import { useTranslation } from 'react-i18next';
import { getImageAPI } from "../../../../http-common";
import Error from '../error';
import { EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import moment from 'moment';
import { Option } from 'antd/es/mentions';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import Footer from '../footer/footer';

const ServiceDetails = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { id } = useParams()
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [amount, setAmount] = useState();
    const [notes, setNotes] = useState();
    const [title, setTitle] = useState();
    const [bookbefore, setBookbefore] = useState();
    const [capacity, setCapacity] = useState();
    const [servdt, setServdt] = useState(null);
    const userid = localStorage.getItem('urole')
    const defaultDate = rList && rList.servdt ? moment(rList.servdt) : moment();

    useEffect(() => {
        if (rList) {
            setTitle(rList.title || '');
            setAmount(rList.amount || '');
            setNotes(rList.notes || '');
        }
    }, [rList]);

    const success = () => {
        showToast('success', t('label_editservice_success'))
        // messageApi.open({
        //     type: 'success',
        //     content: 'SUCCESSFULLY EDITED',
        // });
        form.resetFields()
    };

    const failure = (msg) => {
        showToast('fail', t('label_editservice_failure'))
        // messageApi.open({
        //     type: 'failed',
        //     content: msg,
        // });
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getServicesDetailsList(state),
        rLoading: getServicesDetailsListLoading(state),
        rError: getServicesDetailsListError(state),
    }));


    useEffect(() => {
        dispatch(fetchServicesDetails(id));
    }, [storedLanguage, dispatch]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const [formData, setFormData] = useState({
        // fullname: sDetails.fullname || '',
        // gender: sDetails.gender || '',
        // lang: sDetails.lang || '',
    });

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const token = localStorage.getItem('token');

    const handleCancelClick = () => {
        setTitle(rList.title || '');
        setAmount(rList.amount || '');
        setNotes(rList.notes || '');
        setEditable(false);
    };

    const handleEditClick = () => {
        setEditable(true);
    };

    const handleLinkClick = () => {
        message.info('Please Login to use additional features');
    };

    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
    };

    const servicesData = {
        Darshan: {
            dar: ['Free Darshan', 'Special Darshan', 'VIP Darshan'],
        },
        Puja: {
            special_pujas: ['Daily Pujas', 'Vip Darshan'],
            daily_pujas: ['Daily Gothra Puja', 'Monthly Gothra Puja', 'Abhishekam'],
        },
        Decoration: {
            flowers: ['Flowers'],
            gandham: ['Gandham'],
        },
        Seva: {
            kalyanam: ['Kalyanam'],
            '108_nama_japam': ['108 Nama Japam'],
        },
        Utsav: {
            pallaki_seva: ['Pallaki Seva'],
            grama_utshav: ['Grama Utshav'],
        },
        Accommodation: {
            non_ac_room: ['NON-AC Room'],
            ac_room: ['AC Room'],
            non_ac_dormitory: ['NON-AC Dormitory'],
            ac_dormitory: ['AC Dormitory'],
        },
    };

    const handleType = (values) => {
        const dataToSend = {
            "ctgry": values.ctgry,
            "servtype": values.servtype
        };
        dispatch(editServiceType(id, dataToSend));
    };

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (rList && rList.active !== undefined) {
            setIsActive(rList.active);
        }
    }, [rList]);

    const handleSelectChange = () => {
        const newValue = !isActive;
        dispatch(editServiceStatus(id, { active: newValue }, success, failure));
        setIsActive(newValue);
    };

    const handleBookBeforeChange = (bookbefore) => {
        dispatch(editServiceBookBefore(id, { bookbefore: bookbefore }, success, failure));
    };

    if (!rList) {
        return null;
    }

    const handleSaveClick = () => {
        const updatedData = {
            title: title !== rList.title ? title : undefined,
            amount: amount !== rList.amount ? amount : undefined,
            notes: notes !== rList.notes ? notes : undefined,
            bookbefore: bookbefore !== rList.bookbefore ? bookbefore : undefined,
            capacity: capacity !== rList.capacity ? capacity : undefined,
        };
    
        const updatePromises = Object.keys(updatedData)
            .filter(key => updatedData[key] !== undefined)
            .map(key => {
                const actionCreator = {
                    title: editServiceTitle,
                    amount: editServiceAmount,
                    notes: editServiceNotes,
                    bookbefore: editServiceBookBefore,
                    capacity: editServiceCapacity,
                }[key];
                return dispatch(actionCreator(id, { [key]: updatedData[key] }));
            });
        Promise.all(updatePromises)
            .then(() => {
                success();
            })
            .catch((err) => {
                failure(err.message);
            });
    
        setEditable(false);
    };
    


    const handleAmount = (e) => {
        setAmount(e.target.value)

    };

    const updateAmount = () => {
        dispatch(editServiceAmount(id, { amount: amount }, success, failure));
    };

    const handleNotesChange = (e) => {
        const updatedNotes = e.target.value;
        setNotes(updatedNotes);
        updateNotes(updatedNotes);
    };

    const handleTitleChange = (e) => {
        const updatedTitle = e.target.value;
        setTitle(updatedTitle);
        updateTitle(updatedTitle);
    };

    const updateTitle = (title) => {
        dispatch(editServiceTitle(id, { title: title }, success, failure));
    };

    const updateNotes = (updatedNotes) => {
        dispatch(editServiceNotes(id, { notes: updatedNotes }, success, failure));
    };

    const handleServdt = (date, dateString) => {
        setServdt(dateString);
    };

    const updateServdt = (dateString) => {
        dispatch(editServiceDate(id, { servdt: dateString }, success, failure));
    };

    const updateCapacity = (dateString) => {
        dispatch(editServiceCapacity(id, { capacity: dateString }, success, failure));
    };


    return (
        <div className='main'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_services')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}
            
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> -
                {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}

                &nbsp;  <span onClick={handleGoBack} className="bc-active-link" >{t('label_services')}</span> -
                &nbsp; <span className="bc-link">{rList && rList.ctgry}</span>
            </div >
            <div class="bg-details-highlight">
                <div class=" details-headertxt container">
                    <div class="header_txt"> {rList && rList.title}</div>
                    <div class="">

                        {token ? (
                            <>
                                <a href="#" class="button_move"
                                    onClick={editable ? handleCancelClick : handleEditClick}
                                    style={{ marginRight: '10px' }}> {/* Adjust the gap as needed */}
                                    {editable ? '{t("label_cancel")}' : 'Edit'}
                                </a>
                                {editable && (
                                    <a href="#" class="button_move"
                                        onClick={handleSaveClick}>
                                        Save
                                    </a>
                                )}
                            </>
                        ) : (
                            <a href="#" class="button_move"
                                onClick={handleLinkClick}>
                                {buttonText}
                            </a>
                        )}



                        {(userid === 'TA' || userid === 'TU') ? (
                            <Link
                                to={`/serviceRegistrationPOS/${rList && rList.capacity}/${rList.amount}/${rList.servid}/${rList.title}`}
                                style={{ marginLeft: '5px', marginRight: '5px' }}
                                className="button_move"
                            >
                                {t('label_servicereg_pos')}
                            </Link>
                        ) : null}
                        <Link to={`/serviceRegistration/${rList && rList.capacity}/${rList.amount}/${rList.servid}/${rList.bookbefore}/${rList.title}`} style={{ marginLeft: '5px', marginRight: '5px' }} class="button_move" >{t('label_register_service')}</Link>
                        {userid === 'TA' || 'TU' ? (
                            <>
                                <Link to={`/templeServiceRegistration/0`} class="button_move">{t('label_temple_service_registration')}</Link>
                            </>
                        ) : (
                            <Link to={`/userServiceRegistration/0`} class="button_move">{t('label_user_service_registration')}</Link>
                        )}
                    </div>
                </div>
            </div>
            <div className='container'>
                {/* <Link to={`/editHoroscope/${rList&&rList.hid}`}>
                    <EditOutlined className="edit-icon" title="Edit" />
                </Link> */}
                {rLoading ? (
                    <p>Loading...</p>
                ) : (
                    rList && (
                        <div className='horoscope-item' >
                            <div class="container" ><h5 class="card-text-title">{t("label_details")}<span>| {t('label_category_link_source')}</span></h5></div>
                            {/* <h2>{rList.author}</h2> */}
                            <Form form={form}>
                                <div class="active-details">
                                    <div className="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t('label_title')}</Typography.Title>}
                                            name="title"
                                        >
                                            {editable ? (
                                                <Input
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    defaultValue={rList.title}
                                                    style={{ height: '30px' }}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList && rList.title}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_categ")}</Typography.Title>}
                                            name="ctgry"
                                        >
                                            {editable ? (
                                                <Select
                                                    placeholder="Select category"
                                                    onChange={handleCategoryChange}
                                                    defaultValue={rList.ctgry}
                                                >
                                                    {Object.keys(servicesData).map((category) => (
                                                        <Option key={category} value={category}>
                                                            {category}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.ctgry}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_service_type")}</Typography.Title>}
                                            name="servtype"

                                        >
                                            {editable ? (
                                                <Select
                                                    placeholder="Select Type"
                                                    disabled={!selectedCategory}
                                                    onChange={(value) => handleType({ ctgry: selectedCategory, servtype: value })}
                                                    defaultValue={rList.servtype}
                                                >
                                                    {selectedCategory &&
                                                        Object.keys(servicesData[selectedCategory]).map((subCategory) =>
                                                            servicesData[selectedCategory][subCategory].map((serviceType) => (
                                                                <Option key={serviceType} value={serviceType} >
                                                                    {serviceType}
                                                                </Option>
                                                            ))
                                                        )}
                                                </Select>
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.servtype}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div className="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>Amount</Typography.Title>}
                                            name="amount"
                                        >
                                            {editable ? (
                                                <Input
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    defaultValue={rList.amount}
                                                    style={{ height: '30px' }}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className="typ">{rList && rList.amount}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div className="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_bookbefore")}</Typography.Title>}
                                            name="bookbefore"
                                        >
                                            {editable ? (
                                                <Input
                                                    value={bookbefore}
                                                    onChange={(e) => setBookbefore(e.target.value)}
                                                    defaultValue={rList.bookbefore}
                                                    style={{ height: '30px' }}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className="typ">
                                                    {rList.bookbefore}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_capacity")}</Typography.Title>}
                                            name="capacity"
                                        >
                                            {editable ? (
                                                <Input
                                                value={capacity}
                                                onChange={(e) => setCapacity(e.target.value)}
                                                defaultValue={rList.capacity}
                                                style={{ height: '30px' }}
                                            />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.capacity}</Typography.Text>
                                            )}
                                        </Form.Item>

                                    </div>
                                    <div className="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_status")}</Typography.Title>}
                                            name="status"
                                        >
                                            {editable ? (
                                                <Switch
                                                    defaultChecked={isActive}
                                                    onChange={handleSelectChange}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>
                                                    {isActive ? "True" : "False"}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_date")}</Typography.Title>}
                                            name="servdt"
                                        >
                                            {editable ? (
                                                <DatePicker
                                                    style={{ height: '30px' }}
                                                    onChange={(date, dateString) => {
                                                        handleServdt(date, dateString);
                                                        updateServdt(dateString);
                                                    }}
                                                    defaultValue={rList.servdt ? dayjs(rList.servdt) : dayjs()}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>
                                                    {rList.servdt ? rList.servdt : dayjs().format('YYYY-MM-DD')}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div className="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_notes")}</Typography.Title>}
                                            name="notes"
                                        >
                                            {editable ? (
                                                <Input.TextArea
                                                    value={notes}
                                                    onChange={(e) => setNotes(e.target.value)}
                                                    defaultValue={rList.notes}
                                                    style={{ height: '100px' }}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList && rList.notes}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>

                                </div>
                            </Form>

                        </div>
                    )
                )}
            </div>
            <Footer />
        </div >

    );
};

export default ServiceDetails;
