import {
  Button, Form, Input, Typography, Dropdown, Space, message,
  Upload,
  Modal,
  Image,
  Col,
  Row,
  Select,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import "../../addTemple.css"
import { Link, useNavigate } from 'react-router-dom';
import { DownOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGodNames, postTemples, postUploadImageRequest, retrieveGodNames } from '../../../../redux/actions/acions';
import Cropper from 'cropperjs';
import ImgCrop from 'antd-img-crop';
import ReactCrop from 'react-image-crop';
import { useTranslation } from 'react-i18next';
import ganesh from '../../../../assets/icons/ganesh.jpg'
import durga from '../../../../assets/icons/durga.jpg'
import vishnu from '../../../../assets/icons/narayana.jpg'
import lakshmi from '../../../../assets/icons/lakshmi.jpg'
import rama from '../../../../assets/icons/rama.jpg'
import shiva from '../../../../assets/icons/siva.jpg'
import krishna from '../../../../assets/icons/krishna.jpg'
import kartikeya from '../../../../assets/icons/kumara.jpg'
import hanuma from '../../../../assets/icons/hanuman.jpg'
import brahma from '../../../../assets/icons/bhrahma.jpg'
import saraswati from '../../../../assets/icons/saraswati.jpg'
import ayyappa from '../../../../assets/icons/ayyapa.jpg'
import saibaba from '../../../../assets/icons/saibaba.jpg'
import nrusimha from '../../../../assets/icons/narashima.jpg'
import surya from '../../../../assets/icons/surya.jpg'
import budda from '../../../../assets/icons/bhudha.jpg'
import aum from '../../../../assets/icons/om.jpg'
import naga from '../../../../assets/icons/naga.jpg'
import gurudev from '../../../../assets/icons/gurudev.webp'
import statesData from '../../states.json';
import { GoogleMap, InfoWindow, Marker, LoadScript } from '@react-google-maps/api';
import TextArea from 'antd/es/input/TextArea';
import i18next from 'i18next';
import Footer from '../footer/footer';
import { getGodNames, getGodNamesError, getGodNamesLoading } from '../../../../redux/selectors/selectors';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import AP_LANGUAGES from "../../../common/appLangs"

const normFile = (e) => {

  if (Array.isArray(e)) {
    return e;
  }
  return e.fileList;
};

const AddPage1 = () => {
  const [showMap, setShowMap] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);
  const [locationSelected, setLocationSelected] = useState(false);
  const [mapKey, setMapKey] = useState(1);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [error, setError] = useState(false);
  const [currentLocationClicked, setCurrentLocationClicked] = useState(false);
  const states = statesData.states.map((state) => state.name);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const { t } = useTranslation();
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
  const [selectedDeity, setSelectedDeity] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const localEnv = false;
  const [markerVisible, setMarkerVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [newImagePath, setNewImagePath] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  const { godCtgryList, godCtgryListLoading, godCtgryListError } = useSelector(state => ({
    godCtgryList: getGodNames(state) || [],
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
  }))

  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])

  const onImageLoaded = (image) => {
    console.warn(image);
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (crop, pixelCrop) => {
    console.warn(crop, pixelCrop);
  };

  const tid = localStorage.getItem('templeid')


  const onFileChange = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    fileInput.addEventListener('change', (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        // Convert the image to Base64 for storage in localStorage
        const reader = new FileReader();
        reader.onload = () => {
          const base64Image = reader.result; // This will contain the Base64 string
          localStorage.setItem('selectedImage', base64Image); // Store the image in localStorage
        };
        reader.readAsDataURL(selectedFile);
      }

      // Clean up the file input element after processing
      document.body.removeChild(fileInput);
    });

    // Append file input to the DOM and trigger the file dialog
    document.body.appendChild(fileInput);
    fileInput.click();
  };



  const dispatch = useDispatch()
  const [form] = Form.useForm();

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    showToast('success', t('label_add_temple_sucess'))
    form.resetFields()
  };

  const failure = (msg) => {
    showToast('failure', msg)
  };

  const handleImageLinkClick = () => {
    showToast('error', t(`Please login to upload image.`))
  };

  const handleCameraClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.capture = 'environment'; // Use 'user' for front camera and 'environment' for back camera

    fileInput.addEventListener('change', (event) => {
      const selectedFile = event.target.files[0];

      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile);

        dispatch(postUploadImageRequest(tid, formData, () => {
          const uploadedImagePath = URL.createObjectURL(selectedFile);
          setNewImagePath(uploadedImagePath);
          console.warn('Image uploaded successfully');
        }, (errorMsg) => {
          console.error('Image upload failed:', errorMsg);
        }));
      } else {
        console.warn('No file selected');
      }

      document.body.removeChild(fileInput); // Clean up the file input
    });


    document.body.appendChild(fileInput);
    fileInput.click();
  };

  const [cropper, setCropper] = useState(null);
  const imageRef = useRef();

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  const handleGalleryClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    fileInput.addEventListener('change', (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage(reader.result); // Show image in modal
          setIsModalVisible(true); // Open modal
        };
        reader.readAsDataURL(selectedFile);
      }
    });

    fileInput.click();
  };

  const handleImageCloseModal = () => {
    setIsModalVisible(false);
    if (cropper) {
      cropper.destroy(); // Destroy cropper instance when modal is closed
    }
  };

  const handleCropAndUpload = () => {
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        const formData = new FormData();
        formData.append('file', blob);

        // Replace the following line with your image upload logic
        dispatch(postUploadImageRequest(tid, formData, () => {
          const uploadedImagePath = URL.createObjectURL(blob);
          setNewImagePath(uploadedImagePath);
          handleImageCloseModal(); // Close modal after uploading
        }, (errorMsg) => {
          console.error('Image upload failed:', errorMsg);
        }));
      });
    }
  };

  useEffect(() => {
    if (isModalVisible && imageRef.current) {
      const cropperInstance = new Cropper(imageRef.current, {
        aspectRatio: 16 / 9, // Adjust as needed for the aspect ratio of your choice
        viewMode: 1,
        autoCropArea: 1, // Ensure the crop area covers the whole image
        responsive: true, // Ensure responsiveness
        scalable: false,
        zoomable: true,
        cropBoxResizable: true,
        minCropBoxHeight: 200, // Min height as per your style
        maxCropBoxHeight: 200, // Max height as per your style
        minCropBoxWidth: imageRef.current.clientWidth, // Min width should cover the full width
        maxCropBoxWidth: imageRef.current.clientWidth, // Max width should cover the full width
      });
      setCropper(cropperInstance);
    }

    return () => {
      if (cropper) {
        cropper.destroy(); // Cleanup when the modal is closed or component unmounts
      }
    };
  }, [isModalVisible]);

  const storedLanguage = localStorage.getItem('lng') || 5; // Get the stored language or set default to 'te'
  const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);

  // const onFinish = () => {
  //   form
  //     .validateFields()
  //     .then(() => {
  //       const values = form.getFieldsValue();
  //       const data = {
  //         "tname": values.tname,
  //         "deityname": values.deityname,
  //         "avatar": selectedDeity !== null ? selectedDeity : values.maindeity,
  //         "imgpath": imagePath || null, // Use the simulated image path
  //         "addr1": values.addr1,
  //         "addr2": values.addr2,
  //         "history": values.history || '',
  //         "descr": values.descr || '',
  //         "city": values.city,
  //         "st": values.st,
  //         "pincode": values.pincode,
  //         "country": "India",
  //         "website": values.website,
  //         "latit": parseFloat(values.lat),
  //         "longi": parseFloat(values.lon),
  //         "lang": values.lang,
  //         "otherdeities": values.otherdeities,
  //       };

  //       // Dispatch form submission and handle success/failure callbacks
  //       dispatch(postTemples(data, (response) => {
  //         const tid = localStorage.getItem('templeid')

  //         if (tid) {
  //           console.warn('Form submission successful, tid:', tid);
  //           // Call the onFileChange function and pass the tid
  //           onFileChange(tid);
  //         } else {
  //           console.error('tid not found in response.');
  //         }
  //       }, (error) => {
  //         console.error('Form submission failed:', error);
  //         // Handle form submission error
  //       }));
  //     })
  //     .catch((errorInfo) => {
  //       console.error('Validation failed:', errorInfo);
  //     });
  // };

  const handleUploadClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    // Event listener for file selection
    fileInput.addEventListener('change', (event) => {
      const file = event.target.files[0];
      if (file) {
        setImageFile(file); // Store the selected image file in state
        console.log('Selected image file:', file); // Log the selected file
      } else {
        console.error('No file selected.');
      }

      // Clean up file input element after processing
      document.body.removeChild(fileInput);
    });

    // Append file input to the DOM and trigger click to open file dialog
    document.body.appendChild(fileInput);
    fileInput.click(); // This triggers the file dialog
  };

  // const postUploadImageRequest = (tid, formData, onSuccess, onError) => {
  //   return (dispatch) => {
  //     const url = `https://api.templeswiki.com/templeswiki/api/v1/temple/${encodeURIComponent(tid)}/image`;

  //     fetch(url, {
  //       method: 'POST',
  //       body: formData,
  //     })
  //       .then(response => {
  //         if (!response.ok) {
  //           throw new Error('Network response was not ok');
  //         }
  //         return response.json();
  //       })
  //       .then(data => {
  //         onSuccess(data);
  //       })
  //       .catch(error => {
  //         onError(error.message);
  //       });
  //   };
  // };

  const onFinish = () => {
    form.validateFields()
      .then(() => {
        const values = form.getFieldsValue();
        const data = {
          "tname": values.tname,
          "deityname": values.deityname,
          "avatar": selectedDeity !== null ? selectedDeity : values.maindeity,
          "imgpath": imagePath || null,
          "addr1": values.addr1,
          "addr2": values.addr2,
          "history": values.history || '',
          "descr": values.descr || '',
          "city": values.city,
          "st": values.st,
          "pincode": values.pincode,
          "country": "India",
          "website": values.website,
          "latit": parseFloat(values.lat),
          "longi": parseFloat(values.lon),
          "lang": values.lang,
          "otherdeities": values.otherdeities,
        };
  
        // Dispatch form submission
        dispatch(postTemples(data, (tid) => {
          console.log('Form submission successful, tid:', tid);
  
          if (tid && imageFile) {
            // Automatically upload the image using tid
            uploadImageWithTid(tid, imageFile);
          } else {
            console.error('tid or image file not found. tid:', tid, 'imageFile:', imageFile);
          }
  
          form.resetFields(); // Reset the form fields after submission
        }))
        .catch((error) => {
          console.error('Form submission failed:', error);
        });
      })
      .catch((errorInfo) => {
        console.error('Validation failed:', errorInfo);
      });
  };
  


const uploadImageWithTid = (tid, file) => {
  const formData = new FormData();
  formData.append('file', file); // Directly append the selected image file

  console.log('Uploading image for tid:', tid);

  // Ensure tid and formData are correct
  if (tid && formData) {
      dispatch(postUploadImageRequest(tid, formData, (response) => {
          console.warn('Image upload successful:', response);
      }, (errorMsg) => {
          console.error('Image upload failed:', errorMsg);
      }));
  } else {
      console.error('Invalid tid or formData. Cannot proceed with image upload.');
  }
};






  const pincodeRegex = /^[0-9]{1,6}$/; // Matches 1 to 6 digits

  const validatePincode = (_, value) => {
    if (value && !pincodeRegex.test(value)) {
      return Promise.reject(new Error('Please enter a valid pincode! Only up to 6 digits are allowed.'));
    }
    if (value && value.length > 6) {
      return Promise.reject(new Error('Pincode cannot exceed 6 digits.'));
    }
    return Promise.resolve();
  };





  const deityImages = {
    0: aum,
    1: ganesh,
    2: vishnu,
    3: lakshmi,
    4: durga,
    5: rama,
    6: shiva,
    7: krishna,
    8: kartikeya,
    9: hanuma,
    10: brahma,
    11: saraswati,
    12: ayyappa,
    13: saibaba,
    14: nrusimha,
    15: surya,
    16: budda,
    17: naga,
    18: gurudev
  };

  const [showAllImages, setShowAllImages] = useState(false);

  const handleImageClick = () => {
    setShowAllImages(true);
  };

  const handleDeityImageClick = (deityNumber) => {
    setSelectedDeity(deityNumber);
    handleCloseModal(); // Close the modal after selecting the deity
  };


  const handleCloseModal = () => {
    setShowAllImages(false);
  };


  const handleStateChange = (value) => {
    // Update the selected state and list of cities
    const newState = statesData.states.find((state) => state.name === value);
    setSelectedState(newState);
    setCities(newState ? newState.cities : []);
  };

  useEffect(() => {
    if (selectedState) {
      form.setFieldsValue({ city: selectedState.cities[0] });
    }
  }, [selectedState]);

  const handleLocationButtonClick = () => {
    setShowMap(!showMap);
    setMarkerVisible(true);
    setLocationSelected(false);
  };

  // Handle click on the map to update the selectedLocation
  const handleMarkerDragEnd = (e) => {
    setCurrentLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });

    // Update latitude and longitude state variables
    setLat(e.latLng.lat().toFixed(6));
    setLon(e.latLng.lng().toFixed(6));
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setCurrentLocation({ lat: latitude, lng: longitude });
          setCurrentLocationClicked(true);
          setError(false);
          setLat(latitude.toFixed(6));
          setLon(longitude.toFixed(6));
          setShowMap(true);
          setMarkerVisible(true);
          setMapKey(prevKey => prevKey + 1); // Force map re-render
        },
        (error) => {
          console.error('Error getting current location:', error);
        }
      );
    } else {
      console.warn('Geolocation is not supported by this browser.');
    }
  };

  const handleGetLatLonFromMap = () => {
    if (currentLocation) {
      setLat(currentLocation.lat.toFixed(6));
      setLon(currentLocation.lng.toFixed(6));
      form.setFieldsValue({
        lat: currentLocation.lat.toFixed(6),
        lon: currentLocation.lng.toFixed(6),
      });

      // Close map after selection
      setShowMap(false);
    }
    if (!currentLocationClicked) {
      setError(true); // Show the error if 'Use Current Location' wasn't clicked first
    } else {
      setError(false); // No error if 'Use Current Location' was already clicked
      // Logic for using the selected location from the map
    }
  };

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const indiaCenter = { lat: 20.5937, lng: 78.9629 };

  const customButtonStyle = {
    marginBottom: '10px',
    flex: '1'
  };

  const handleCancelClick = () => {
    // Reset the form to its initial values
    form.resetFields();
    navigate(-1);
    // setEditable(false);
  };



  return (
    <>
      {contextHolder}
      
      <div class='mainsec'>
        <div class=" mob-nav fluid-container page-title justify-content-between">
          <div class="d-flex align-items-center left-sec-mob-nav">
            <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
            <span class="bc-link">{t('add_temple')}</span>
          </div>
          <div></div>
        </div>
        <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
          <Link style={{ color: 'black' }} to='/'>
            <span className="bc-active-link">{t('Home')}</span>
          </Link> -
          {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
          &nbsp; <span className="bc-active-link" onClick={handleGoBack}>{t('label_temples_list')}</span> -
          &nbsp; <span className="bc-link">{t("add_temple")}</span>
        </div>
        <div class="bg-details-highlight">
          <div class=" details-headertxt container">
            <div class="header_txt"> {t('label_add_new_tmpl')}</div>
            <div class="">
              <a href="#" class="button_move" onClick={handleCancelClick} style={{ marginRight: '5px' }}>
                {t('label_cancel')}
              </a>
              <a href="#" class="button_move" type="primary" htmlType="submit" onClick={onFinish}>
                {t('label_save')}
              </a>
            </div>
            <div class="d-flex flex-row header_star mr-5">
              <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
              </span>
            </div>
          </div>
        </div>
        <div class="mainsec-block-addtemple-detailspage">
          <div className='container'>
            {/* <div className='details-page'> */}
            {/* <Link to={'/'}><Typography.Text>Back</Typography.Text></Link> */}
            <Form form={form} name="nest-messages" onFinish={onFinish}
              initialValues={{
                lat: lat || undefined,
                lon: lon || undefined,
              }}
            // labelCol={{
            //   span: 7,
            // }}
            // wrapperCol={{
            //   span: 9,
            // }}
            >
              <div class="float-left w-100 mt-4" ><h5 class="card-text-title">{t("label_enter_tmpl_det")}
                {/* <span>| {t('label_temple_name_timings_pujas')}</span> */}
              </h5></div>
              {/* <Typography.Title level={4} style={{ color: "rgb(229 123 13)" }}>{t("label_enter_tmpl_det")}</Typography.Title> */}
              <div class="active-details">
                {/* <Row gutter={10}>
                  <Col span={16}> */}

                <div class="tiles-designs" >
                  <Form.Item
                    // labelCol={{
                    //   span: 10,
                    // }}
                    // wrapperCol={{
                    //   span: 18,
                    // }}
                    name='tname'
                    label={<Typography.Title level={4}>{t('label_temple_name')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_enter_temple_name')} />
                  </Form.Item>
                </div>

                {/* </Col>
                  <Col span={8}> */}
                <div className="tiles-designs">
                  <Form.Item
                    label={<Typography.Title level={4}>{t('label_sel_diety_img')}</Typography.Title>}
                    rules={[{ required: true }]}
                  // name='avatar'
                  >

                    <>
                      {/* Editing mode: show image from API or the selected image */}
                      <img
                        src={selectedDeity ? deityImages[selectedDeity] : deityImages[0]}
                        alt="Selected Deity Image"
                        onClick={handleImageClick}
                        class="add-temple-img"
                      />
                      <div class="popup-bg" style={{ display: showAllImages ? 'flex' : 'none' }}>
                        <div class="popup-main-filter animate__animated animate__fadeInUp ">
                          <div class="popup-main-header">
                            <span class="popup-main-header-text">{t('label_god_categ')}</span>
                            <div class="popup-close" onClick={handleCloseModal}><i class="fa-solid fa-xmark"></i></div>
                            <div class="popup-back" onClick={handleCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                          </div>
                          <div class="popup-main-container">
                            <div class="d-block htinner-popup-main-container">
                              <div className=" god-category-list">
                                <div className="all-gods-sec">{t('label_all')}</div>
                                <div className="god_select">
                                  <div className="god_pic">
                                    <img
                                      className="dietyImg"
                                      src={aum}
                                      alt="All"
                                      onClick={() => handleDeityImageClick(null)}
                                    />
                                  </div>
                                </div>
                                <div className="all-gods-sec">{t("label_filter_by_god")}</div>
                                <Row gutter={[16, 16]}>
                                  {godCtgryList && godCtgryList.map((godCtgry) => (
                                    <Col key={godCtgry.avatar}>
                                      <div className="god_select">
                                        <div className="god_pic">
                                          <img
                                            src={deityImages[godCtgry.avatar]}
                                            alt="Deity Image"
                                            style={{
                                              width: '80px',
                                              height: '80px',
                                              margin: '5px',
                                              cursor: 'pointer',
                                              border: '1px solid #ddd'
                                            }}
                                            onClick={() => handleDeityImageClick(godCtgry.avatar)}
                                          />
                                        </div>
                                        <p>{godCtgry.deity}</p>
                                      </div>
                                    </Col>
                                  ))}
                                </Row>
                              </div>
                            </div>
                          </div>
                          <div class="popup-main-footer">
                            <a className="button_move" onClick={handleCloseModal}>{t('label_cancel')}</a>

                          </div>
                        </div>
                      </div>
                      {/* <Modal
                        visible={showAllImages}
                        onCancel={handleCloseModal}
                        footer={null}
                        mask={false}
                      >
                        <div className="diag-header">
                          {t('label_god_categ')}
                          <span className="diag-close">
                            <i className="fa-solid fa-xmark" onClick={handleCloseModal}></i>
                          </span>
                        </div>
                        <div className="dialog-content god-category-list">
                          <div className="all-gods-sec">{t('label_all')}</div>
                          <div className="god_select">
                            <div className="god_pic">
                              <img
                                className="dietyImg"
                                src={aum}
                                alt="All"
                                onClick={() => handleDeityImageClick(null)}
                              />
                            </div>
                          </div>
                          <div className="all-gods-sec">{t("label_filter_by_god")}</div>
                          <Row gutter={[16, 16]}>
                            {godCtgryList && godCtgryList.map((godCtgry) => (
                              <Col key={godCtgry.avatar}>
                                <div className="god_select">
                                  <div className="god_pic">
                                    <img
                                      src={deityImages[godCtgry.avatar]}
                                      alt="Deity Image"
                                      style={{
                                        width: '100px',
                                        height: '100px',
                                        margin: '5px',
                                        cursor: 'pointer',
                                        border: '1px solid #ddd'
                                      }}
                                      onClick={() => handleDeityImageClick(godCtgry.avatar)}
                                    />
                                  </div>
                                  <p>{godCtgry.deity}</p>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      </Modal> */}
                    </>


                    {/* <img
                                            src={deityImages[tDetails && tDetails.avatar]}
                                            alt="Deity Image"
                                            style={{ width: '5%' }}
                                        /> */}

                  </Form.Item>
                </div>
                {/* </Col>
                </Row> */}
                {/* <Col span={16}> */}
                <div class="tiles-designs" >
                  <Form.Item
                    // labelCol={{
                    //   span: 11,
                    // }}
                    // wrapperCol={{
                    //   span: 18,
                    // }}
                    name='deityname'
                    label={<Typography.Title level={4}>{t('label_main_deity')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_enter_main_deity')} />
                  </Form.Item>
                </div>
                {/* </Col>
                <Col span={16}> */}
                <div class="tiles-designs" >
                  <Form.Item
                    // labelCol={{
                    //   span: 11,
                    // }}
                    // wrapperCol={{
                    //   span: 18,
                    // }}
                    name='otherdeities'
                    label={<Typography.Title level={4}>{t('label_other_deities')}</Typography.Title>}
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_enter_other_deities')} />
                  </Form.Item>
                </div>
                {/* </Col> */}
                <div class="tiles-designs" >
                  <Form.Item
                    name='descr'
                    label={<Typography.Title level={4}>{t('label_descr')}</Typography.Title>}
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                  >
                    <TextArea rows={4} placeholder={t('label_enter_details')} />
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    name='history'
                    label={<Typography.Title level={4}>{t("label_history")}</Typography.Title>}
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                  >
                    <TextArea rows={4} style={{ marginTop: '6px' }} placeholder={t('label_enter_history')} />
                  </Form.Item>
                </div>
              </div>
              {/* <Form.Item
                name='otherdeities'
                label={t('Other Diety in same temple')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name='descr' label="Temple History">

                <Input.TextArea />

              </Form.Item> */}
              <div class="float-left w-100 mt-4" ><h5 class="card-text-title">{t('label_address_location_details')}
                {/* <span>| {t('label_state_city_website')}</span> */}
              </h5></div>
              {/* <Typography.Title level={4} style={{ color: "rgb(229 123 13)" }}>{t('Address/Location Details')}</Typography.Title> */}
              <div class="active-details">
                {/* <Col span={24}> */}
                  <div class="tiles-designs" >
                    <Form.Item
                      name='addr1'
                      label={<Typography.Title level={4}>{t('label_village_area')}</Typography.Title>}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input style={{ height: '30px' }} placeholder={t('label_enter_village_locality')} />
                    </Form.Item>
                  </div>
                {/* </Col> */}
                {/* <Form.Item
                name='pincode'
                label={t('Pincode')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item> */}
                <div class="tiles-designs" >
                  <Form.Item
                    name='addr2'
                    label={<Typography.Title level={4}>{t('label_addr')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_enter_address')} />
                  </Form.Item>
                </div>
                {/* <Row gutter={12}>
                <Col span={12}> */}
                <div class="tiles-designs">
                  <Form.Item
                    label={<Typography.Title level={4}>{t('label_stat')}</Typography.Title>}
                    name="st"
                    rules={[
                      { required: true, message: 'Please select your state' },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      defaultValue={t('label_select_state')}
                      onChange={handleStateChange}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {states.map((state) => (
                        <Select.Option key={state} value={state}>
                          {state}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    label={<Typography.Title level={4}>{t("label_city")}</Typography.Title>}
                    name="city"
                    rules={[
                      { required: true, message: 'Please enter your city' },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      defaultValue={t('label_select_city')}
                      value={selectedState ? undefined : ''}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {cities.map((city) => (
                        <Select.Option key={city} value={city}>
                          {city}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    name='pincode'
                    label={<Typography.Title level={4}>{t('label_pin')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your pincode!',
                      },
                      {
                        validator: validatePincode,
                      },
                    ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_enter_pincode')} />
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    name='website'
                    label={<Typography.Title level={4}>{t('label_website')}</Typography.Title>}
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_enter_website')} />
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    name="lang"
                    label={<Typography.Title level={4}>{t("label_lang")}</Typography.Title>}
                    // initialValue={'ENGLISH'}
                    rules={[
                      {
                        required: true,
                        message: 'Please select a language.',
                      },
                    ]}
                  >
                    <Select options={[{ value: '', label: t('label_select_language') }, ...AP_LANGUAGES]}
                      placeholder={t('label_select_language')}
                      style={{ width: "30%" }}
                    />
                  </Form.Item>
                </div>
                {/* </Col>
              </Row> */}

                {/* <Form.Item
                name='country'
                label={t('Country')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item> */}
                {/* <div class="hr_line"></div> */}
                <div class="center-align">
                  <button type="button" class="button_move" onClick={handleLocationButtonClick}>{t('label_show_map')}
                    {/* <i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i> */}
                  </button>
                </div>
                {/* <span>Show Map</span> */}

                {showMap && (
                  <>
                    <div class="popup-bg" style={{ display: showMap ? 'flex' : 'none' }}>
                      <div class="popup-main animate__animated animate__fadeInUp ">
                        <div class="popup-main-header">
                          <span class="popup-main-header-text">{t('label_sel_loc_on_map')}</span>
                          <div class="popup-close" onClick={() => setShowMap(false)}><i class="fa-solid fa-xmark"></i></div>
                          <div class="popup-back" onClick={() => setShowMap(false)}><i class="fa-solid fa-chevron-left"></i></div>
                        </div>
                        <div class="popup-main-container">
                          <div class="d-block htinner-popup-main-container">
                            <GoogleMap
                              center={currentLocation || indiaCenter}
                              zoom={currentLocation ? 14 : 5}
                              mapContainerStyle={{ width: '100%', height: '400px' }}
                            >
                              {currentLocation && markerVisible && (
                                <Marker
                                  position={currentLocation}
                                  draggable={true}
                                  onDragEnd={handleMarkerDragEnd}
                                />
                              )}
                            </GoogleMap>
                          </div>
                        </div>
                        <div class="popup-main-footer">
                          <button key="back" class="button_move mr-3 " type="primary" onClick={() => setShowMap(false)} style={customButtonStyle} >
                            {t("label_cancel")}
                          </button>
                          <button key="button" class="button_move mr-3" type="primary" onClick={handleGetCurrentLocation} style={customButtonStyle}>
                            Use Current Location
                          </button>
                          <button key="submit" class="button_move" type="primary" onClick={handleGetLatLonFromMap} style={customButtonStyle}>
                            Use this Location
                          </button>

                        </div>
                        {error && (
                          <div className="popup-error" style={{ color: "red", marginTop: "10px" }}>
                            Error: You must click "Use Current Location" first.
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <Modal
                      visible={showMap}
                      onCancel={() => setShowMap(false)}
                      footer={[
                        <div class="map-buttons-bottom">
                          <button key="back" class="button_move mr-3 " type="primary" onClick={() => setShowMap(false)} style={customButtonStyle} >
                            {t("label_cancel")}
                          </button>
                          <button key="button" class="button_move mr-3" type="primary" onClick={handleGetCurrentLocation} style={customButtonStyle}>
                            Use Current Location
                          </button>
                          <button key="submit" class="button_move" type="primary" onClick={handleGetLatLonFromMap} style={customButtonStyle}>
                            Use this Location
                          </button>
                        </div>
                      ]}
                    >
                      <div class="diag-header">{t('label_select_location')}
                        <span class="diag-close"><i class="fa-solid fa-xmark" onClick={() => setShowMap(false)}></i></span>
                      </div>
                      <GoogleMap
                        center={currentLocation || indiaCenter}
                        zoom={currentLocation ? 14 : 5}
                        mapContainerStyle={{ width: '100%', height: '400px' }}
                      >
                        {currentLocation && markerVisible && (
                          <Marker
                            position={currentLocation}
                            draggable={true}
                            onDragEnd={handleMarkerDragEnd}
                          />
                        )}
                      </GoogleMap>
                    </Modal> */}
                  </>
                )}



                {/* {currentLocation && (
        <Button type="button" onClick={handleGetLatLonFromMap}>
          Add Location
        </Button>
      )} */}
                <div class="tiles-designs" >
                  <Form.Item name="lat" label={<Typography.Title level={4}>{t('label_latit')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                        message: 'Please select location on map.',
                      },
                    ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_select_lat')} />
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item name="lon" label={<Typography.Title level={4}>{t('label_langitude')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                        message: 'Please select location on map.',
                      },
                    ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_select_lon')} />
                  </Form.Item>
                </div>
              </div>

              <div class="float-left w-100 mt-4" ><h5 class="card-text-title">{t('label_add_remove_photos')}
                {/* <span>| {t('label_add_remove_image')}</span> */}
              </h5></div>

              <div className="tiles-designs">
                <Form.Item
                  name="img"
                  label={<Typography.Title level={4}>{t('label_add_img')}</Typography.Title>}
                  extra="Use PNG/JPG/JPEG format only"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <button
                    type="button"
                    onClick={handleUploadClick}
                    className="btn btn-outline-dark"
                    style={{ width: '10%' }}
                  >
                    <i className="fa-solid fa-upload"></i>&nbsp;{t('label_upload')}
                  </button>
                </Form.Item>
              </div>
            </Form>

            {/* <div className="active-details">
              <div className="tiles-designs">

                <div text="Add Image" >
                  {previewImage ? (
                    <img src={previewImage} alt="Captured Image" />
                  ) : (
                    <>
                      {isMobile && (
                        <button className="button_move" onClick={handleCameraClick} style={{ marginBottom: '8px' }}>
                          {t('label_camera')}
                        </button>
                      )}
                    </>
                  )}

                  <button className="button_move" onClick={handleGalleryClick} style={{ marginBottom: '8px', marginLeft: "5px" }}>
                    {t('label_upload')}
                  </button>

                </div>
                
                <div class="popup-bg" style={{ display: isModalVisible ? 'flex' : 'none' }}>
                  <div class="popup-main-filter animate__animated animate__fadeInUp ">
                    <div class="popup-main-header">
                      <span class="popup-main-header-text">Main header</span>
                      <div class="popup-close" onClick={handleImageCloseModal}><i class="fa-solid fa-xmark"></i></div>
                      <div class="popup-back" onClick={handleImageCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                    </div>
                    <div class="popup-main-container">
                      <div class="d-block htinner-popup-main-container">
                        
                        {selectedImage && <img ref={imageRef} id="imageToCrop" src={selectedImage} alt="To be cropped" />}
                      </div>
                    </div>
                    <div class="popup-main-footer">
                      <button class="button_move" onClick={handleCropAndUpload}>Crop and Upload</button>
                    </div>
                  </div>
                </div>

              </div>
            </div> */}
          </div>
          <Footer />
        </div>

      </div>

    </>
  );
};
export default AddPage1;