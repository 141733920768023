import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBooksByTag, fetchBooksFavourites, fetchFavourites, fetchGodNames, fetchLiveTv, fetchResourceByTxt, fetchVideoLinks, postBooksFavourites, postFavourites, removeBooksFavourites, removeFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import { getBooksByTagError, getBooksByTagList, getBooksByTagLoading, getBooksFavouritesError, getBooksFavouritesList, getBooksFavouritesLoading, getFavouritesError, getFavouritesList, getFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getLiveTvList, getLiveTvLoading, getResourceByTxtError, getResourceByTxtList, getResourceByTxtLoading, getVideoLinksList, getVideoLinksLoading } from '../../../../redux/selectors/selectors';
import { Row, Col, Card, message, FloatButton, Button, Select, Image, Modal, Input, Space, Result } from 'antd';
import deftemples from '../../../../assets/images/templeicon.png'
import { CloseCircleOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import temple from '../../../../assets/images/img.png'
import { getImageAPI } from "../../../../http-common";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import aum from '../../../../assets/icons/om.jpg'
import naga from '../../../../assets/icons/naga.jpg'
import spin from '../../../../assets/images/Spinner.gif'
import Error from '../error';
import Search from 'antd/es/transfer/search';
import Footer from '../footer/footer';
import YouTube from './youtube';
import nodata from '../../../../assets/images/nodata5.jpg';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import deityImages from '../../../common/godImages';

let last_rec = 0;
let selected_last_rec = 0;
let search_last_rec = 0;
function LiveDarshan() {
  const storedLanguage = localStorage.getItem('lng') || 5; // Get the stored language or set default to 'te'
  const localEnv = false;
  const { uid } = useParams()
  const [currentPageTemples, setCurrentPageTemples] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const records_per_page = 15;
  const selected_per_page = 15;
  const [did, setDid] = useState(0);
  const [lastRec, setLastRec] = useState(0);
  const [filterId, setFilterId] = useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [messageApi, contextHolder] = message.useMessage();
  const userid = localStorage.getItem('urole')
  const [refresh, setRefresh] = useState(false);
  const { Search } = Input;
  // const failure = (msg) => {
  //   messageApi.open({
  //     type: 'error',
  //     content: msg,
  //   });
  // };



  const { vDetails, vLoading, vError, godCtgryList, godCtgryListLoading, godCtgryListError } = useSelector(state => ({
    vDetails: getVideoLinksList(state),
    vLoading: getVideoLinksLoading(state),
    vError: getVideoLinksLoading(state),
    godCtgryList: getGodNames(state) || [],
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
  }))

  useEffect(() => {
    dispatch(fetchVideoLinks(videos, storedLanguage, did, 0))
  }, [storedLanguage, did])

  const { tDetails, tLoading, tError } = useSelector(state => ({
    tDetails: getBooksByTagList(state),
    tLoading: getBooksByTagLoading(state),
    tError: getBooksByTagError(state),
  }))

  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    //dispatch(fetchContributions(0, failure));
  }, [])

  // const nextSetRef = useRef(null);


  // useEffect(() => {
  //   // Add a scroll event listener to the window
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     // Remove the scroll event listener when the component unmounts
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const handleScroll = () => {
  //   const windowHeight =
  //     "innerHeight" in window
  //       ? window.innerHeight
  //       : document.documentElement.offsetHeight;
  //   const body = document.body;
  //   const html = document.documentElement;
  //   const docHeight = Math.max(
  //     body.scrollHeight,
  //     body.offsetHeight,
  //     html.clientHeight,
  //     html.scrollHeight,
  //     html.offsetHeight
  //   );
  //   const windowBottom = windowHeight + window.pageYOffset;

  //   // Detect if the user has scrolled to the bottom of the page
  //   if (windowBottom >= docHeight && !isLoading) {
  //     // Load more data when scrolling to the end
  //     nextTemplesList();
  //   }
  // };


  function filterTemplesList(gDid) {
    if (gDid === filterId) {
      setDid(0);
      setFilterId(0);
      // setCurrentPageTemples([]); // Clear the current data when the filter is cleared
    } else {
      setDid(gDid);
      setFilterId(gDid);
      // setCurrentPageTemples([]); // Clear the current data when a new filter is applied
    }
  }


  function getStyle(godCategoryId) {
    if (godCategoryId === filterId) {
      return {

        boxShadow: '#d3d3d3 3px -2px 4px',
        borderRadius: '8px',
        filter: 'saturate(1)',
        border: '3px solid var(--colororange2)',
      };
    } else {
      return {
        border: '1px solid #ddd',
      };
    }
  }

  // function getStyle(godCategoryId) {
  //   if (godCategoryId === did) {
  //     return {
  //       color: '#4096ff',
  //       borderColor: '#4096ff',
  //     };
  //   } else {
  //     return {
  //       borderColor: '#d9d9d9',
  //       color: '#000',
  //     };
  //   }
  // }

  // const [currentPage, setCurrentPage] = useState(1);


  // useEffect(() => {
  //   dispatch(fetchVideoLinks(storedLanguage, did, last_rec));
  // }, [storedLanguage, did, currentPage]);

  // useEffect(() => {

  //   if (!vLoading && vDetails) {
  //     setCurrentPageTemples([...currentPageTemples, ...vDetails]);
  //   }

  // }, [vLoading]);

  const [currentPage, setCurrentPage] = useState(0);
  const mainSecBlockRef = useRef(null);
  const nextTemplesList = async () => {
    setIsLoading(true);
    setCurrentPage(prevPage => {
      const newPage = prevPage + 1;

      if (filterId !== null && selectedItem === null) {
        const newLastRec = newPage * records_per_page;
        dispatch(fetchVideoLinks(videos, storedLanguage, did, newLastRec));
      }
      else if (selectedItem !== null) {
        const newSelectedLastRec = newPage * selected_per_page;
        dispatch(fetchBooksByTag(videos, storedLanguage, filterId, selectedItem, newSelectedLastRec));
      }
      else if (searchText !== null) {
        const newSearchLastRec = newPage * selected_per_page;
        dispatch(fetchResourceByTxt(storedLanguage, searchText, newSearchLastRec));
      }
      if (mainSecBlockRef.current) {
        mainSecBlockRef.current.scrollTop = 0;
      }
      return newPage;
    });
    setIsLoading(false);
  };

  // Previous button handler
  const prevTemplesList = () => {
    setIsLoading(true);
    setCurrentPage(prevPage => {
      if (prevPage > 0) { // Ensure we don't go below the first page
        const newPage = prevPage - 1; // Decrement page

        if (filterId !== null && selectedItem === null) {
          const newLastRec = newPage * records_per_page; // Calculate new offset for previous page
          dispatch(fetchVideoLinks(videos, storedLanguage, did, newLastRec));
        }
        else if (selectedItem !== null) {
          const newSelectedLastRec = newPage * selected_per_page; // Previous offset for selected items
          dispatch(fetchBooksByTag(videos, storedLanguage, filterId, selectedItem, newSelectedLastRec));
        }
        else if (searchText !== null) {
          const newSearchLastRec = newPage * selected_per_page; // Previous offset for search text
          dispatch(fetchResourceByTxt(storedLanguage, searchText, newSearchLastRec));
        }
        if (mainSecBlockRef.current) {
          mainSecBlockRef.current.scrollTop = 0;
        }
        return newPage; // Return updated page number
      }

      return prevPage; // If we're on the first page, don't decrement
    });
    setIsLoading(false);
  };





  function getYouTubeVideoId(url) {
    // This function extracts the YouTube video ID from a YouTube URL
    const regex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }

  const god = {
    0: 'Aum',
    1: 'Ganesha',
    2: 'Vishnu',
    3: 'Lakshmi',
    4: 'Durga',
    5: 'Sri Rama',
    6: 'Shiva',
    7: 'Krishna',
    8: 'Kartikeya',
    9: 'Hanuma',
    10: 'Brahma',
    11: 'Saraswati',
    12: 'Ayyappa',
    13: 'Saibaba',
    14: 'Narasimha',
    15: 'Surya',
    16: 'Budha',
    17: 'Naga',
  };


  const success = (title) => {
    showToast('success', t(`Added ${title} to favorites`))
  };
  const warning = (title) => {
    showToast('info', t(`Removed ${title} from favourites`))
  };



  const failure = (msg) => {
    showToast('error', msg = t('label_loginerror'))
  };

  const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
  const [filledTemples, setFilledTemples] = useState({});

  const { fList, fLoading, fError } = useSelector(state => ({
    fList: getBooksFavouritesList(state),
    fLoading: getBooksFavouritesLoading(state),
    fError: getBooksFavouritesError(state),
  }));

  useEffect(() => {
    dispatch(fetchBooksFavourites(videos));
  }, [getFavouriteTemples, refresh]);

  const [favList, setFavList] = useState([]);

  // useEffect(() => {
  //   if (fList) {
  //     setFavList(fList.fav_list);
  //   }
  // }, [fList]);

  useEffect(() => {
    if (fList && fList.fav_list) {
      setFavList(fList.fav_list);
    }
  }, [fList]);

  function addFavourites(rid) {
    dispatch(postBooksFavourites(videos,rid, failure))
  }

  function deleteFavourites(deleteId) {
    dispatch(removeBooksFavourites(videos, deleteId, failure));
  }

  const { sList, sLoading, sError } = useSelector(state => ({
    sList: getResourceByTxtList(state),
    sLoading: getResourceByTxtLoading(state),
    sError: getResourceByTxtError(state),
  }));

  // useEffect(() => {
  //   dispatch(fetchResourceByTxt(storedLanguage, searchText, 0));
  // }, []);

  // let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.rid);

  const getToken = Boolean(localStorage.getItem('token'));

  function toggleFavorite(temple) {
    if (getToken) {
      const templeId = temple.rid;
      if (favList.includes(templeId)) {
        // Temple is in favList, remove it
        deleteFavourites(templeId, temple.title);
        warning(temple.title);
        // Update favList by removing the temple ID
        setFavList(prevFavList => prevFavList.filter(id => id !== templeId));
      } else {
        // Temple is not in favList, add it
        addFavourites(templeId, temple.title);
        success(temple.title);
        // Update favList by adding the temple ID
        setFavList(prevFavList => [...prevFavList, templeId]);
      }
    } else {
      // Handle the case when there's no token (e.g., show a message or redirect to login)
      failure("Please log in to favorite temples");
    }
  }


  const [filled, setFilled] = useState({});

  function toggleFilled(id) {
    setFilledTemples((prevFilled) => ({
      ...prevFilled,
      [id]: !prevFilled[id],
    }));
  }


  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal and set the selected video URL
  const openVideoModal = (videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    setIsModalOpen(true);
  };

  // Function to close the modal and clear the selected video URL
  const closeVideoModal = () => {
    setSelectedVideoUrl(null);
    setIsModalOpen(false);
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const [videos, setvideos] = useState('videos');
  const handleButtonClick = (itemName) => {
    if (itemName === "All") {
      setSelectedItem(null);
      dispatch(fetchVideoLinks(videos, storedLanguage, did, 0));
    } else {
      setSelectedItem(itemName);
      dispatch(fetchBooksByTag(videos, storedLanguage, filterId, itemName, 0)); // Pass itemName as the tag
    }
  };

  const description = t("label_about_live_darshan");

  const categoryButtons = ['kids', 'temple', 'puran', 'dharma', 'god', 'puja', 'festival'];

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (uid) {
      setSearchText(uid);
      if (uid.length >= 5) {
        dispatch(fetchResourceByTxt(videos, storedLanguage, uid, 0)); 
      }
    }
  }, [uid, dispatch]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setSearchText(newValue);

    // Call the API only if the length of the input value is 5 or more
    if (newValue.length >= 5) {
      dispatch(fetchResourceByTxt(videos, storedLanguage, newValue, 0));
    }
  };


  const [isGodModalVisible, setIsGodModalVisible] = useState(false);
  const [selectedFilterGod, setSelectedFilterGod] = useState(null);
  const handleFilterGodClick = () => {
    setIsGodModalVisible(true);
  };

  const handleFilterGodCancel = () => {
    setIsGodModalVisible(false);
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading data
    const loadData = async () => {
      setIsLoading(true);
      // Assume a function fetchData to get your data
      // await fetchData();
      setIsLoading(false);
    };
    loadData();
  }, [searchText, selectedItem, sList, tDetails, vDetails]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="loading">
          <div className="spinner-container">
            <div className="loading-spinner">
            </div>
          </div>
        </div>
      )
    };

    if (!searchText && !selectedItem) {
      return vDetails && vDetails.length > 0 ? (
        vDetails.map((temple) => (
          <YouTube
            key={temple.id}
            temple={temple}
            toggleFavorite={toggleFavorite}
            toggleFilled={toggleFilled}
            favList={favList}
            getYouTubeVideoId={getYouTubeVideoId}
          />
        ))
      ) : (
        <Result
          status="404"
          title="No Data Found"
          subTitle="Sorry, no Live Darshan found."
        />
      );
    }

    if (uid >= 5 || (searchText && searchText.length >= 5)) {
      return sList && sList.length > 0 ? (
        sList.map((temple) => (
          <YouTube
            key={temple.id}
            temple={temple}
            toggleFavorite={toggleFavorite}
            toggleFilled={toggleFilled}
            favList={favList}
            getYouTubeVideoId={getYouTubeVideoId}
          />
        ))
      ) : (
        <Result
          status="404"
          title="No Data Found"
          subTitle="Sorry, no Live Darshan found."
        />
      );
    }

    if (selectedItem != null) {
      return tDetails && tDetails.length > 0 ? (
        tDetails.map((temple) => (
          <YouTube
            key={temple.id}
            temple={temple}
            toggleFavorite={toggleFavorite}
            toggleFilled={toggleFilled}
            favList={favList}
            getYouTubeVideoId={getYouTubeVideoId}
          />
        ))
      ) : (
        <Result
          status="404"
          title="No Data Found"
          subTitle="Sorry, no Live Darshan found."
        />
      );
    }

    return vDetails && vDetails.length > 0 ? (
      vDetails.map((temple) => (
        <YouTube
          key={temple.id}
          temple={temple}
          toggleFavorite={toggleFavorite}
          toggleFilled={toggleFilled}
          favList={favList}
          getYouTubeVideoId={getYouTubeVideoId}
        />
      ))
    ) : (
      <Result
        status="404"
        title="No Data Found"
        subTitle="Sorry, no Live Darshan found."
      />
    );
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {contextHolder}
      
      <div class="mainsec" ref={mainSecBlockRef}>
        <div class=" mob-nav fluid-container page-title justify-content-between">
          <div class="d-flex align-items-center left-sec-mob-nav">
            <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
            <span className="bc-link">{t("label_videos")}</span>
            {did !== 0 && (
              <div className="detail-container">
                <span className="god-button ml-2">{god[did]}</span>
                <CloseCircleOutlined
                  className="close-icon"
                  onClick={() => {
                    setDid(0);
                    setFilterId(0);
                  }}
                />
              </div>
            )}
            {did === 0 && (
              <div className="detail-container">
                <span className="detail-but ml-2">{"All"}</span>
              </div>
            )}
          </div>

          <div class="right-sec-mob-nav">
            {/* <i className="fa-solid fa-location-dot" onClick={openModal}></i>&nbsp; */}
            <i class="fa-solid fa-filter filter-link mr-3" onClick={handleFilterGodClick}> </i>&nbsp;&nbsp;
          </div>
        </div>

        <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>
          <div>
            <Link style={{ color: 'black' }} to='/'>
              <span className="bc-active-link">{t('Home')}</span>
            </Link> -{' '}
            <Link to='/library'>
              <span className="bc-active-link" onClick={handleGoBack}> {t('label_library')}</span></Link> -

            <span className="bc-link">{t("label_videos")}</span>{' '}
            <i class="fa-solid fa-filter filter-link" onClick={handleFilterGodClick}> </i>&nbsp;
            {did !== 0 && (
              <div className="detail-container">
                <span className="god-button">{god[did]}</span>
                <CloseCircleOutlined
                  className="close-icon"
                  onClick={() => {
                    setDid(0);
                    setFilterId(0);
                  }}
                />
              </div>
            )}
            {did === 0 && (
              <div className="detail-container">
                <span className="detail-but">{"All"}</span>
              </div>
            )}
          </div>
        </div>
        <div class="popup-bg" style={{ display: isGodModalVisible ? 'flex' : 'none' }}>
        <div class="popup-main-filter animate__animated animate__fadeInUp ">
            <div class="popup-main-header-filter">
              <span class="popup-main-header-text"> {t("label_god_categ")}</span>
              <div class="popup-close" onClick={handleFilterGodCancel}><i class="fa-solid fa-xmark"></i></div>
              <div class="popup-back" onClick={handleFilterGodCancel}><i class="fa-solid fa-chevron-left"></i></div>
            </div>
            <div class="popup-main-container">
              <div class="d-block htinner-popup-main-container">
                <div className=" god-category-list1">
                  <div class="all-gods-sec">
                    {t('label_all')}
                  </div>
                  <div class="god_select">
                    <div class="god_pic">
                      <img style={getStyle(0)} className='dietyImg' src={aum} alt="All"
                        onClick={() => {
                          setFilterId(0);
                          setDid(0);

                        }}
                      />

                      {/* {categoryButtons.map((category, index) => (

                        <div class="god_pic" key={index}>
                          <button style={getStyle(0)} className='dietyBtn' alt={category}
                            onClick={() => {
                              handleButtonClick(category);
                              setIsGodModalVisible(false);
                            }}
                          >
                            {t('label_' + category)}
                          </button>

                        </div>
                      ))} */}
                    </div>
                  </div>


                  <div class="all-gods-sec">
                    Filter By God
                  </div>

                  <Row gutter={[16, 16]}>
                    {godCtgryList &&
                      godCtgryList.map((godCtgry, index) => (
                        <Col key={godCtgry.avatar}>
                          <div class="god_select">
                            <div class="god_pic">
                              <img style={getStyle(godCtgry.avatar)} src={deityImages[godCtgry.avatar]}
                                onClick={() => {
                                  filterTemplesList(godCtgry.avatar);
                                  setIsGodModalVisible(false);
                                }}
                              />
                              <p>{godCtgry.deity}</p>
                            </div>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </div>
            <div class="popup-main-footer">
              <a className="button_move" onClick={handleFilterGodCancel}>{t('label_cancel')}</a>
            </div>
          </div>
        </div>

        {/* <Modal visible={isGodModalVisible} footer={null}>
          <div className="diag-header">
            {t("label_god_categ")}
            <span class="diag-close">
              <i class="fa-solid fa-xmark" onClick={handleFilterGodCancel}></i>
            </span>
          </div>
          <div className="dialog-content god-category-list1">


            <div class="all-gods-sec">
              All
            </div>
            <div class="god_select">
              <div class="god_pic">
                <img style={getStyle(0)} className='dietyImg' src={aum} alt="All"
                  onClick={() => {
                    setFilterId(0);
                    setDid(0);

                  }}
                />

                {categoryButtons.map((category, index) => (

                  <div class="god_pic" key={index}>
                    <button style={getStyle(0)} className='dietyBtn' alt={category}
                      onClick={() => {
                        handleButtonClick(category);
                        setIsGodModalVisible(false);
                      }}
                    >
                      {t('label_'+category)}
                    </button>

                  </div>
                ))}
              </div>
            </div>


            <div class="all-gods-sec">
              Filter By God
            </div>

            <Row gutter={[16, 16]}>
              {godCtgryList &&
                godCtgryList.map((godCtgry, index) => (
                  <Col key={godCtgry.avatar}>
                    <div class="god_select">
                      <div class="god_pic">
                        <img style={getStyle(godCtgry.avatar)} src={deityImages[godCtgry.avatar]}
                          onClick={() => {
                            filterTemplesList(godCtgry.avatar);
                            setIsGodModalVisible(false);
                          }}
                        />
                        <p>{godCtgry.deity}</p>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
          <div className="dialog-buttons">
            <Button className="login-form-button" onClick={handleFilterGodCancel}>{t('label_cancel')}</Button>
          </div>
        </Modal> */}
        <div class="mainsec-block">
          {/* <div class="gods-section">
            <span class="filter-god-txt">{t("label_filter_by_god")}</span>
            {godCtgryListLoading && godCtgryListLoading ? (
              <p>Loading...</p>
            ) : (<div style={{ display: 'flex' }}>
              <div class="god_select">
                <div class="god_pic">

                  <Image style={getGodStyle(0)} className='dietyImg' src={aum} alt="All"
                    onClick={() => {
                      setFilterId(0);
                      setDid(0);
                      setCurrentPageTemples([]);
                    }}
                  />
                </div>
              </div>

              {godCtgryList && godCtgryList.map(godCtgry => (
                <div class="god_select">

                  <div class="god_pic" key={godCtgry.avatar}>

                    <Image style={getGodStyle(godCtgry.avatar)} src={deityImages[godCtgry.avatar]}
                      onClick={() => {
                        filterTemplesList(godCtgry.avatar);

                      }}
                    />

                    <p>{godCtgry.diety}</p>
                  </div>
                </div>
              ))}

            </div>
            )}
          </div> */}

          <div class="f2 font-weight-bold  container title-head-txt " style={{ overflowX: 'auto', paddingBottom: '10px', whiteSpace: "nowrap" }}>
            <button
              className={`declineButton ${selectedItem === null ? 'selected' : ''}`}
              onClick={() => handleButtonClick("All")}>{t('label_all')}</button>
            {categoryButtons.map((category, index) => (
              <button
                key={index}
                type="button"
                className={`declineButton ${selectedItem === category ? 'selected' : ''}`}
                onClick={() => handleButtonClick(category)}
              >
                {t('label_' + category)}
                <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
              </button>
            ))}
          </div>

          {/* <div class="container cat-txt ">
            <div class="cat-detail">
              <span class="f3 d-flex justify-content-between">
                <div><b>{t("About")} {t("lable_live_darshan")}</b> &nbsp; &nbsp;<i class="fa-solid fa-volume-high"></i></div>
                {description.length >= 50 && (
                  <div className="detail-but" >
                    {t('label_show_more')}
                  </div>
                )}
              </span>
              <p class="f1">{description}
              </p>
            </div></div> */}
          {/* <div className='container '>

            <Input
              className='resource-search mb-3 mt-3'
              name="query"
              placeholder="Enter search keyword"
              value={searchText}
              onChange={handleInputChange}
              allowClear
              prefix={<SearchOutlined />}

            />

          </div> */}



          <div className="container search">
            <Row>
              {renderContent()}
            </Row>



            <FloatButton.BackTop icon={<img style={{ height: 30, width: 30, marginLeft: -5 }} src={temple} alt="temple" />} />
          </div>
          <div className="next-row">
            {vDetails && vDetails.length > 0 ? (
              <Col>
                <a
                  className="button_move"
                  onClick={currentPage === 0 ? null : prevTemplesList}
                  disabled={currentPage === 0}
                >
                  Prev
                </a>
              </Col>
            ) : (
              ''
            )}
            {vDetails && vDetails.length > 0 ? (
              <Col>
                <a
                  className="button_move"
                  onClick={vDetails.length < 15 ? null : nextTemplesList}
                  disabled={vDetails.length < 15}
                >
                  Next
                </a>
              </Col>
            ) : (
              ''
            )}
          </div>
          <Footer />
        </div>

      </div>
    </>

  )
}

export default LiveDarshan;
