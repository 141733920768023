import { Button, Checkbox, Col, Form, Input, Modal, Popover, Row, Select, Spin, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fetchRegionalNotifi, fetchState, fetchValidUserLogin, postPasswordRequest, postRegisterUserRequest } from '../../redux/actions/acions';
import i18next from 'i18next';
import { BankOutlined, EnvironmentOutlined, LockOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading, getStateError, getStateList, getStateLoading } from '../../redux/selectors/selectors';
import showToast from '../../utils/showToast';
import AP_LANGUAGES from "../../components/common/appLangs";

const Login = () => {
    const { t } = useTranslation()
    const [firstLetter, setFirstLetter] = useState('');
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [visible, setVisible] = useState(true);
    const [name, setName] = useState('')
    const firstName = name.split(' ')[0];
    const lastName = name.split(' ')[1];
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [loginError, setLoginError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const token = Boolean(localStorage.getItem('token'));
    const [activeIcon, setActiveIcon] = useState(null);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]+$/;
    const storedLanguage = localStorage.getItem('lng') || 5;
    const getToken = Boolean(localStorage.getItem('token'));
    const userid = localStorage.getItem('urole')
    // const success = () => {
    //     messageApi.open({
    //         type: 'success',
    //         content: 'successfully Logged In',
    //     });
    //     setIsLoading(true);
    //     setTimeout(() => {
    //         navigate('/');
    //         message.loading('Reloading...', 0.2, () => {
    //             window.location.reload();
    //         });
    //     }, 2000); 
    // };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getRegionalNotifiList(state),
        rLoading: getRegionalNotifiLoading(state),
        rError: getRegionalNotifiError(state),
    }));
    

    // useEffect(() => {
    //     dispatch(fetchRegionalNotifi(storedLanguage, 0));
    // }, [storedLanguage, dispatch]);
    const getName = localStorage.getItem('name');
    const success = async () => {
        // Display success message
        message.success('Successfully Logged in TemplesWIKI App');
        setIsLoginModalOpen(false);
        // Simulate a 2-second delay for the example
        await new Promise(resolve => setTimeout(resolve, 2000));

        // Fetch recently published events (optional, remove if not needed)
        // dispatch(fetchRegionalNotifi(storedLanguage, 0));

        // Wait for the events to be fetched (optional, remove if not needed)
        //await new Promise(resolve => setTimeout(resolve, 1000));
        window.location.reload();

        setTimeout(() => {
            setShowWelcome(true);
        }, 30); // 30-second delay for welcome message
        // Display welcome dialogue
        // Modal.info({
        //     title: 'Welcome!',
        //     content: (





        // <div>
        //     <p>Hello, {getName}! Welcome to TemplesWIKI.</p>
        //     {/* Additional welcome content can be added here */}
        // </div>
        // ),
        // onOk() {
        // Redirect to the home page (optional, remove if not needed)
        //     navigate('/');
        // },
        // });
    };
    const [showWelcome, setShowWelcome] = useState(false);

    useEffect(() => {
        // Close the welcome message after 30 seconds
        const closeWelcomeTimeout = setTimeout(() => {
            setShowWelcome(false);
        }, 3000);

        // Cleanup the timeout to avoid memory leaks
        return () => clearTimeout(closeWelcomeTimeout);
    }, [showWelcome]);

    const failure = () => {
        showToast('fail', t('label_wrongpword'))
        // setLoginError(msg);
        // messageApi.open({
        //     type: 'error',
        //     content: 'Please enter valid email and password!', //Changes
        // });
    };


    const handleChange = (value) => {
        i18next.changeLanguage(value)
        localStorage.setItem('lng', value)
    };

    const handleO = () => {
        setOpen(false);
        loginShowModal();
        setVisible(false);
    };

    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const dispatch = useDispatch();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const showLoginModal = queryParams.get('showLoginModal') === 'true';

    const loginShowModal = () => {
        setIsLoginModalOpen(true);
    };

    const loginhandleOk = () => {
        setIsLoginModalOpen(false);
    };

    const loginHandleCancel = () => {
        setIsLoginModalOpen(false);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleForgetCancel = () => {
        // Add your logic for handling cancel here
        setIsForgetModalOpen(false); // Assuming you want to close the Forget Password modal
    };

    const loginOnFinish = async (values) => {
        dispatch(fetchValidUserLogin(values.username, values.password, success, failure));
    }
    const handleLogout = () => {
        setVisible(true);
        localStorage.clear()
        localStorage.removeItem('token');
        navigate('/')
        window.location.reload();
    };

    const key = 'updatable';
    const openMessage = () => {
        messageApi.open({
            key,
            type: 'loading',
            content: 'Loading...',
        });
        setTimeout(() => {
            messageApi.open({
                key,
                type: 'success',
                content: 'Please check your mail to activate your account!',
                duration: 2,
            });
        }, 1000);
    };

    const forgetSuccess = () => {
        messageApi.open({
            type: 'success',
            content: 'Check your mail to change password',
        });
    };
    const forgetFailure = (msg) => {
        messageApi.open({
            type: 'failure',
            content: msg,
        });
    };

    const { sDetails, sLoading, sError } = useSelector(state => ({
        sDetails: getStateList(state),
        sLoading: getStateLoading(state),
        sError: getStateError(state)
    }));
    useEffect(() => {
        if (getToken) {
            dispatch(fetchState());
        }
    }, [])

    const [isForgetModalOpen, setIsForgetModalOpen] = useState(false);

    const forgetShowModal = () => {
        setIsForgetModalOpen(true);
    };

    const forgethandleOk = () => {
        setIsForgetModalOpen(false);
    };

    const forgethandleCancel = () => {
        setIsForgetModalOpen(false);
    };

    const forgetOnFinish = (values) => {
        const data = {
            "email": values.email
        }
        dispatch(postPasswordRequest(data, forgetSuccess, forgetFailure));

    };

    const onRegister = (values) => {
        dispatch(postRegisterUserRequest(values, openMessage, failure))

    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, 10000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const handleVisibleChange = (visible) => {
        if (activeIcon !== null) {
            setPopoverVisible(visible);
        }
    };

    const handlePopoverVisible = (visible) => {
        setPopoverVisible(visible);
    };

    React.useEffect(() => {
        const getName = localStorage.getItem('name');
        if (getName) {
            const firstChar = getName.charAt(0);
            setFirstLetter(firstChar);
        }
    }, []);

    // const [name, setName] = useState('');
    const [user, setUser] = useState(null);

    useEffect(() => {
        const getName = localStorage.getItem('name');
        if (getName) {
            setName(getName);
        }
        const uid = localStorage.getItem('uid');
        if (uid) {
            const profileImageURL = localStorage.getItem(`profileImage_${uid}`);
            if (profileImageURL) {
                setUser(profileImageURL);
            } else {
                setUser(null); // Reset to null in case there's a default image previously set
            }
        }
    }, []);

    const content = (
        <div>
            <p>{t("error_msg_69")}</p>

            {/* <button   type="button" class="btn btn-outline-secondary" data-mdb-ripple-color="dark"></button> */}
            <Button onClick={handleO} type="primary" htmlType="submit" className="login-form-button">
            {t("label_signin")}
            </Button>
        </div>
    );

    return (
        <>
            {contextHolder}
            {token ? (
                <div className="pop">
                    <Popover
                        content={
                            <div className="popover-content">
                                {token ? (

                                    <div className="para">
                                        <div className="user-pic1">
                                            <div class="d-flex ">
                                                {user ? (
                                                    <img src={user} alt="Profile" />
                                                ) : (
                                                    <div className="initials-placeholder">
                                                        <h5>
                                                            {(firstName != null && firstName.length > 0) ? firstName.charAt(0) : ''}
                                                            {(lastName != null && lastName.length > 0) ? lastName.charAt(0) : ''}
                                                        </h5>
                                                    </div>
                                                )}
                                                {firstName || lastName ? <h5>{sDetails && sDetails.fullname ? sDetails.fullname : 'Guest'}</h5> : null}
                                            </div>
                                            <div className="mt-1 mb-2">
                                                <hr />
                                            </div>
                                            {/* <Button >Change Profile</Button> */}
                                            <Link to="/profile" className="btn btn-outline-secondary" onClick={() => { popoverVisible(false); }} data-mdb-ripple-color="dark">
                                                {t("lable_edit_prof")}
                                            </Link>

                                        </div>
                                        <p ><span >{t("Language:")}</span>
                                            <Select className='w-100'
                                                defaultValue='5'
                                                onChange={handleChange}
                                                options={AP_LANGUAGES}
                                            /></p>
                                        <div className='language '>


                                        </div>
                                        {/* <p><span >City: </span>{getCity}</p> */}
                                        {/* <Button >Sign Out</Button> */}
                                        <div class="mt-2 mb-2">
                                            <hr />
                                        </div>

                                        <button onClick={handleLogout} type="button" class="btn btn-outline-dark"><i class="fa-solid fa-arrow-right-from-bracket"></i>&nbsp; {t("SignOut")}</button>

                                        <br />

                                    </div>
                                ) : (
                                    <p>Loading user information...</p>
                                )}
                            </div>
                        }
                        title={<div className="profile-header">{t("label_profile")}</div>}
                        open={popoverVisible}
                        onOpenChange={handlePopoverVisible}
                        trigger="click"
                        placement="bottomRight"
                        overlayClassName="custom-popover1"
                    >
                        <div className="user-txt add-temple-icon" onClick={() => setPopoverVisible(!popoverVisible)}>
                            {firstLetter && firstLetter ? firstLetter : 'U'}
                        </div>
                    </Popover>
                </div>
            ) : (
                <Popover
                    content={content}
                    trigger="click"
                    open={visible}
                    onOpenChange={handleVisibleChange}
                    placement="bottomRight"
                >
                    <a onClick={() => { setVisible(false); setOpen(false); loginShowModal(); }}>
                        <Button type='primary' style={{ background: 'transparent', borderRadius: '5px' }}>
                            <i class="fa-solid fa-user"></i></Button>
                    </a>
                </Popover>
            )}

            <div className='signin'>
                <Row >

                    <Col>

                        <Modal
                            open={isModalOpen}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            // footer={null}
                            footer={
                            <Button type="primary" className="login-form-button" style={{ width: '25%', marginBottom: '5px', marginRight: '5px' }} onClick={handleCancel}>
                                {t("label_cancel")}
                            </Button>
                            }
                        >
                            <div class="diag-header">{t('label_sign_up')}
                                <span class="diag-close"><i class="fa-solid fa-xmark" onClick={handleCancel}></i></span></div>
                            <Form
                                name="basic"
                                labelCol={{
                                    span: 24,
                                }}
                                wrapperCol={{
                                    span: 24,
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onRegister}
                                autoComplete="off"
                                className="form-box form-login"
                            >
                                <div class="logo_login"></div>
                                <h1 class="login-txt">{t("error_msg_71")}</h1>
                                <h3>{t("label_please_register")}</h3>
                                <Form.Item
                                    // label="Full Name"
                                    placeholder="Enter full name"
                                    name="fullname"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("label_enter_name"),
                                        },
                                    ]}
                                >
                                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Full Name" />
                                </Form.Item>
                                <Form.Item
                                    // label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("label_enter_email"),
                                        },
                                        {
                                            pattern: emailRegex,
                                            message: t("label_enter_email"),
                                        },
                                    ]}
                                >
                                    <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
                                </Form.Item>

                                <Form.Item
                                    // label="Phone No"
                                    name="phone"
                                    placeholder="phone"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("label_enter_phone"),
                                        },
                                        {
                                            pattern: phoneRegex,
                                            message: 'Please enter a valid phone number! Only numbers are allowed.',
                                        },
                                    ]}
                                >
                                    <Input prefix={<PhoneOutlined className="site-form-item-icon" />} placeholder="Phone No" />
                                </Form.Item>
                                {/* <Form.Item
                                    // label="City"
                                    placeholder="Enter city"
                                    name="city"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your City!',
                                        },
                                    ]}
                                >
                                    <Input prefix={<EnvironmentOutlined />} placeholder="City" />
                                </Form.Item>

                                <Form.Item
                                    // label="State"
                                    placeholder="State"
                                    name="state"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your State!',
                                        },
                                    ]}
                                >
                                    <Input prefix={<BankOutlined />} placeholder="State" />
                                </Form.Item> */}
                                <Form.Item
                                    // label="Password"
                                    placeholder="Password"
                                    name="scode"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("label_enter_pwd"),
                                        },
                                    ]}
                                >
                                    <Input.Password prefix={<LockOutlined />}
                                        type="password"
                                        placeholder="Password"
                                        style={{ paddingRight: '10px' }}
                                    />
                                </Form.Item>
                                {/* <Row>
                    <Col>
                      <a className="login-form-forgot" onClick={() => { setIsModalOpen(false); forgetShowModal(); }}>
                        Forgot password
                      </a>

                    </Col>
                  </Row> */}
                                <Form.Item
                                    wrapperCol={{

                                    }}
                                >
                                    <Button type="primary" htmlType="submit" className="login-form-button">
                                        Register
                                    </Button>

                                    {loginError && (
                                        <p style={{ color: 'red', marginTop: '2px' }}>
                                            {t("error_msg_72")}
                                        </p>
                                    )}
                                    <p class="reg-btn-login">Or <a className='forget' onClick={() => { setIsModalOpen(false); loginShowModal(); }}>Already have an account!</a></p>
                                    {isLoading && <Spin />}

                                </Form.Item>
                            </Form>
                        </Modal>
                    </Col>


                    <Col>


                        <Modal
                            open={isLoginModalOpen}
                            onOk={loginhandleOk}
                            onCancel={loginHandleCancel}
                            footer={<Button id="cancelButton" type="primary" style={{ width: '25%', marginBottom: '5px', marginRight: '5px' }} className="login-form-button" onClick={loginHandleCancel}>{t("label_cancel")}</Button>}
                        >
                            <div class="diag-header">{t('Login')}
                                <span class="diag-close"><i class="fa-solid fa-xmark" onClick={loginHandleCancel}></i></span></div>
                            <Form
                                name="basic"
                                labelCol={{
                                    span: 24,
                                }}
                                wrapperCol={{
                                    span: 24,
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={loginOnFinish}
                                autoComplete="off"
                                className='form-box'
                            >

                                <div class="logo_login"></div>
                                <h1 class="login-txt">Login</h1>
                                <h3>{t("error_msg_75")}</h3>


                                <Form.Item
                                    // label={t('Email')}
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("label_enter_email"),
                                        },
                                        {
                                            pattern: emailRegex,
                                            message: 'Please enter a valid email!',
                                        },
                                    ]}
                                >
                                    <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
                                </Form.Item>

                                <Form.Item
                                    // label={t('Password')}
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("label_enter_pwd"),
                                        },
                                    ]}
                                //initialValue="test@123"
                                >
                                    <Input.Password
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        type="password"
                                        placeholder="Password"
                                        style={{ paddingRight: '10px' }}
                                    />
                                </Form.Item>

                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>Remember me</Checkbox>
                                </Form.Item>


                                <Form.Item wrapperCol={{}}>
                                    <Button type="primary" htmlType="submit" className="login-form-button" id="loginButton">
                                        Log in
                                    </Button>
                                    {isLoading && <Spin />}
                                    {loginError && (
                                        <p style={{ color: 'red', marginTop: '2px' }}>
                                            {t("error_msg_26")}
                                        </p>
                                    )}
                                </Form.Item>



                                <Row>
                                    <a style={{ color: '#007bff' }} className="login-form-forgot" onClick={() => { setIsLoginModalOpen(false); forgetShowModal(); }} id="forgetPassword">
                                        {t("label_forgot_pwd")}
                                    </a>

                                </Row>

                                <Form.Item
                                    wrapperCol={{

                                    }}
                                >

                                    <p class="reg-btn-login">{t("error_msg_73")}  <a className='forget' id="register" onClick={() => { setIsLoginModalOpen(false); showModal(); }}>Register Now!</a></p>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </Col>

                    <Modal
                        open={isForgetModalOpen}
                        onOk={forgethandleOk}
                        onCancel={handleForgetCancel}
                        footer={<Button type="primary" className="login-form-button" style={{ width: '25%', marginBottom: '5px', marginRight: '5px' }} onClick={handleForgetCancel}>{t("label_cancel")}</Button>}
                    >
                        <div class="diag-header">{t('Forget Password')}
                            <span class="diag-close"><i class="fa-solid fa-xmark" onClick={handleForgetCancel}></i></span></div>
                        <Form
                            name="basic"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={forgetOnFinish}
                            autoComplete="off"
                            className='form-box'
                        >
                            <div class="logo_login"></div>
                            <h1 class="login-txt">{t('Forget Password')}</h1>
                            {/* <h3>{t("error_msg_75")}</h3> */}
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    // {
                                    //     required: true,
                                    //     message: 'Please enter your Email!',
                                    // },
                                    {
                                        pattern: emailRegex,
                                        message: 'Please enter a valid email!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<MailOutlined className="site-form-item-icon" />}
                                    placeholder="Email"
                                />
                            </Form.Item>
                            <Form.Item
                                label="Phone No"
                                name="phone"
                                placeholder="phone"
                                rules={[
                                    // {
                                    //     required: true,
                                    //     message: 'Please enter your Phone No!',
                                    // },
                                    {
                                        pattern: phoneRegex,
                                        message: 'Please enter a valid phone number! Only numbers are allowed.',
                                    },
                                ]}
                            >
                                <Input prefix={<PhoneOutlined className="site-form-item-icon" />} placeholder="Phone No" />
                            </Form.Item>

                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Row >
                                    <Col>
                                        <Typography.Text className="login-form-forgot"> {t("label_remem_pwd")} |</Typography.Text>
                                    </Col>
                                    <Col style={{ marginLeft: '5px', marginTop: '-2px', marginBottom: '5px' }}>
                                        <a className='forget' onClick={() => { setIsForgetModalOpen(false); loginShowModal(); }}>
                                            {t('Login')}
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                {t("label_reset_pwd")}
                            </Button>
                            {isLoading && <Spin />}
                        </Form>
                    </Modal>
                </Row>
            </div>
            {userid === 'TA' && showWelcome && (
                <div class="welcomebg">
                    <div class="welcome-image"></div>
                </div>
            )}
        </>

    )
}

export default Login;