import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, Card, Col, Row, message } from 'antd';
import { getContributionsError, getContributionsList, getContributionsLoading, getFavouritesError, getFavouritesList, getFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading } from '../../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTemple, fetchContributions, fetchFavourites, fetchGodNames, fetchNotifications, postFavourites, removeFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { getImageAPI } from "../../../../http-common";
import deftemples from '../../../../assets/images/templeicon.jpg';
import TemplesPage from '../../../common/TemplesPage';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';

let last_rec = 0;
const Contribution = () => {
    const { t } = useTranslation();
    const localEnv = false;
    const records_per_page = 15;
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false);
    const storedLanguage = localStorage.getItem('lng') || 5;
    const [messageApi, contextHolder] = message.useMessage();
    // const failure = (msg) => {
    //     messageApi.open({
    //         type: 'error',
    //         content: msg,
    //     });
    // };

    const success = (tname) => {
        showToast('success', t(`Added ${tname} to favorites`))
        // messageApi.open({
        //     type: 'success',
        //     content: `Added ${tname} to favorites`,
        // });
    };
    const warning = (tname) => {
        showToast('info', t(`Removed ${tname} from favourites`))
        // messageApi.open({
        //     type: 'warning',
        //     content: `Removed ${tname} from favourites`,
        // });
    };

    const failure = (msg) => {
        showToast('error', msg)
        // messageApi.open({
        //     type: 'error',
        //     content: 'Please Login to see additional features',
        // });
    };

    const deleteSuccess = () => {
        showToast('deleteSuccess', t('label_contributiondel_success'))
        // messageApi.open({
        //     type: 'success',
        //     content: `Deleted Sucessfully`,
        // });
    };


    const { cList, cLoading, cError, fList, fLoading, fError,  godCtgryList, godCtgryListLoading, getContributionsError } = useSelector(state => ({
        cList: getContributionsList(state),
        cLoading: getContributionsLoading(state),
        // cError: getContributionsError(state),
        fList: getFavouritesList(state),
        fLoading: getFavouritesLoading(state),
        fError: getFavouritesError(state),
        godCtgryList: getGodNames(state) ,
        godCtgryListLoading: getGodNamesLoading(state),
        godCtgryListError: getGodNamesError(state),
    })
    )
    useEffect(() => {
        dispatch(fetchContributions(storedLanguage, 0))
    }, [storedLanguage])
    

    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage])

    useEffect(() => {
        dispatch(fetchFavourites(storedLanguage, 0))
    }, [getFavouriteTemples])

    const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);

    const [filled, setFilled] = useState({}); // initialize as an empty object
    const [refresh, setRefresh] = useState(false);

    // const [messageApi, contextHolder] = message.useMessage();


    function toggleFilled(id) {
        setFilled(prevFilled => ({
            ...prevFilled,
            [id]: !prevFilled[id]
        }));
    }

    function addFavourites(id) {
        dispatch(postFavourites(id, success, failure))
    }

    function deleteFavourites(deleteId) {
        dispatch(removeFavourites(deleteId, failure))
    }

    const getToken = Boolean(localStorage.getItem('token'));

    function toggleFavorite(temple) {
        const index = favList.findIndex(t => t === temple.tid);
        if (index === -1) {
            addFavourites(temple.tid, temple.tname);
            setGetFavoriteTemples(!getFavouriteTemples);
            if (getToken) {
                success(temple.tname);
            }
        } else {
            deleteFavourites(temple.tid, temple.tname);
            setGetFavoriteTemples(!getFavouriteTemples);
            warning(temple.tname);
        }
    }

    // useEffect(() => {
    //     dispatch(fetchFavourites(0));
    // }, [refresh]);

    let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.tid);

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])

    const [filterId, setFilterId] = useState(0);
    function getStyle(godCategoryId) {
        if (godCategoryId === filterId) {
          return {
            color: '#4096ff',
            borderColor: '#4096ff',
          };
        } else {
          return {
            borderColor: '#d9d9d9',
            color: '#000',
          };
        }
      }

      const tid = cList && cList.length > 0 && cList[0].tid;
      const ludt = cList && cList.length > 0 && cList[0].ludt;
      

      function handleDelete() {
        dispatch(deleteTemple(tid, ludt, deleteSuccess, failure))
        message.success('Deleted successfully');
    }

//     const nextSetRef = useRef(null);
   

//   useEffect(() => {
//       // Add a scroll event listener to the window
//       window.addEventListener("scroll", handleScroll);
//       return () => {
//           // Remove the scroll event listener when the component unmounts
//           window.removeEventListener("scroll", handleScroll);
//       };
//   }, []);

//   const handleScroll = () => {
//       const windowHeight =
//           "innerHeight" in window
//               ? window.innerHeight
//               : document.documentElement.offsetHeight;
//       const body = document.body;
//       const html = document.documentElement;
//       const docHeight = Math.max(
//           body.scrollHeight,
//           body.offsetHeight,
//           html.clientHeight,
//           html.scrollHeight,
//           html.offsetHeight
//       );
//       const windowBottom = windowHeight + window.pageYOffset;

//       // Detect if the user has scrolled to the bottom of the page
//       if (windowBottom >= docHeight && !isLoading) {
//           // Load more data when scrolling to the end
//           nextTemplesList();
//       }
//   };

    // const [currentPage, setCurrentPage] = useState(1);
    // const [currentPageTemples, setCurrentPageTemples] = useState([]);

    // useEffect(() => {
    //     dispatch(fetchContributions(storedLanguage, last_rec));
    // }, [storedLanguage, currentPage]);

    // useEffect(() => {
    //     if (!cLoading && cList) {
    //         setCurrentPageTemples([...currentPageTemples, ...cList]);
    //     }
    // }, [cLoading]);

    const nextTemplesList = async () => {
        setIsLoading(true);
        last_rec = last_rec + records_per_page;
        // did == filterId ? god_last_rec = god_last_rec + records_per_page : god_last_rec = 0 + records_per_page;
        dispatch(fetchContributions(storedLanguage, last_rec));
        // setIsLoading(false);
    }

    function prevTemplesList() {
        last_rec = last_rec - records_per_page;
        last_rec = (last_rec < 0) ? 0 : last_rec;
        // god_last_rec = god_last_rec - records_per_page;
        // god_last_rec = (god_last_rec < 0) ? 0 : god_last_rec;
        dispatch(fetchContributions(storedLanguage, last_rec))
      };

    return (
        <>
            {contextHolder}
            <CustomToastContainer/>
                <TemplesPage
                    title={'My Contributions'}
                    description={'This is Contributions page'}
                    tLoading={cLoading}
                    tList={cList}
                    favList={favList}
                    toggleFavorite={toggleFavorite}
                    toggleFilled={toggleFilled}
                    pageTitle={'Favourites'}
                    handleDelete={handleDelete}
                    // godCtgryList={godCtgryList}
                    // filterTemplesList={filterTemplesList}
                    // godCtgryListLoading={godCtgryListLoading}
                    nextTemplesList={nextTemplesList}
                    prevTemplesList={prevTemplesList}
                    templesList={cList}
                />             
           
        </>


    )
}



export default Contribution;