import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { message, Select } from 'antd'; // Import Select from Ant Design
import { useTranslation } from 'react-i18next';
import cholaTemples from '../../../../assets/images/darshan.jpg';
import { fetchServicesByAdmin } from '../../../../redux/actions/acions';
import {
    getServicesByAdminList,
    getServicesByAdminListError,
    getServicesByAdminListLoading
} from '../../../../redux/selectors/selectors';
import Footer from '../footer/footer';

const AdminService = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const storedLanguage = localStorage.getItem('lng') || 'en';

    const [selectedStatus, setSelectedStatus] = useState(''); // State to manage status dropdown value
    const [selectedCategory, setSelectedCategory] = useState(''); // State to manage category dropdown value
    const [filteredServices, setFilteredServices] = useState([]); // State to store filtered services
    const [categoryOptions, setCategoryOptions] = useState([]); // State to store categories dynamically

    const { nList, nLoading, nError } = useSelector((state) => ({
        nList: getServicesByAdminList(state),
        nLoading: getServicesByAdminListLoading(state),
        nError: getServicesByAdminListError(state),
    }));

    useEffect(() => {
        dispatch(fetchServicesByAdmin(0));
    }, [storedLanguage]);

    useEffect(() => {
        if (nList && Array.isArray(nList)) {
            setFilteredServices(nList);
            const uniqueCategories = [...new Set(nList.map(service => service.ctgry))];
            setCategoryOptions(uniqueCategories);
        }
    }, [nList]);


    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
        filterServices(value, selectedStatus);
    };

    const handleStatusChange = (value) => {
        setSelectedStatus(value);
        filterServices(selectedCategory, value);
    };


    const filterServices = (category, status) => {
        let filtered = nList;

        if (category) {
            filtered = filtered.filter(service => service.ctgry === category);
        }

        if (status === 'true') {
            filtered = filtered.filter(service => service.active === true);
        } else if (status === 'false') {
            filtered = filtered.filter(service => service.active === false);
        }

        setFilteredServices(filtered);
    };

    const handleGoBack = () => {
        Navigate(-1);
    };

    return (
        <div className="mainsec">
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('Admin Services')}</span>
                </div>
                <div></div>
            </div>
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> -
                <span className="bc-link">{t('Admin Services')}</span>
            </div>
            <div class="bg-details-highlight">
            </div>
            <div className="mainsec-block">
                <div className="container">
                    <div className="bg-details-highlight" style={{ width: '100vw', marginLeft: 'calc(-50vw + 50%)' }}>
                        <div className="details-headertxt container">
                            <div className="header_txt">
                                {t("label_services")}
                                <span style={{ marginLeft: '10px' }}> | {t("label_services_like_darshan_puja")}</span>
                                <Link to='/createService' type='button' className="button_move" style={{ marginLeft: '20px' }}>
                                    {t('label_create_service')}
                                </Link>
                            </div>
                            <div className="d-flex flex-row header_star mr-5">
                                <span className="f18 mb-2 colorHeadingviolet fw-7 events-icon"></span>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                        <Select
                            showSearch
                            onChange={handleCategoryChange}
                            value={selectedCategory}
                            className="select-element"
                            style={{ marginRight: '10px', width: '20%' }}
                            placeholder={t('Please select category')}
                        >
                            <Select.Option value="">{t('label_select_category')}</Select.Option>
                            {categoryOptions.map((category, index) => (
                                <Select.Option key={index} value={category}>
                                    {t(category)}
                                </Select.Option>
                            ))}
                        </Select>

                        <Select
                            showSearch
                            onChange={handleStatusChange}
                            value={selectedStatus}
                            className="select-element"
                            style={{ marginRight: '10px', width: '20%' }}
                            placeholder={t('Please select status')}
                        >
                            <Select.Option value="">{t('label_select_status')}</Select.Option>
                            <Select.Option value="true">{t('label_active')}</Select.Option>
                            <Select.Option value="false">{t('label_inactive')}</Select.Option>
                        </Select>
                    </div>

                    <div className="tiles-main">
                        {filteredServices && filteredServices.map((temple, index) => (
                            <Link to={`/ServiceDetails/${temple.servid}/${temple.tname}`} key={index}>
                                <div className="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div className="title-img-bg">
                                        <img className="card-img-temple-cat" alt="temples" src={cholaTemples} />
                                        <div className="hover-links">
                                            <a href={`/ServiceDetails/${temple.servid}`}>{temple.servtype}</a>
                                        </div>
                                    </div>
                                    <div className="tile-text-temple-cat">
                                        <span>{temple.ctgry}</span> <span>{t("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default AdminService;
