import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { getBooksByTagError, getBooksByTagList, getBooksByTagLoading, getBooksError, getBooksFavouritesError, getBooksFavouritesList, getBooksFavouritesLoading, getBooksList, getBooksLoading, getFavouritesError, getFavouritesList, getFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getResourceByTxtError, getResourceByTxtList, getResourceByTxtLoading, getTempleMantrasError, getTempleMantrasList, getTempleMantrasLoading } from '../../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBooks, fetchBooksByTag, fetchBooksFavourites, fetchFavourites, fetchGodNames, fetchResourceByTxt, postBooksFavourites, postFavourites, removeBooksFavourites, removeFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import { Button, Card, Col, FloatButton, Image, Input, Modal, Result, Row, Skeleton, Space, message } from 'antd';
import deftemples from '../../../../assets/images/templeicon.jpg';
import { CloseCircleOutlined, EditOutlined, RocketTwoTone, SearchOutlined } from '@ant-design/icons';
import temple from '../../../../assets/images/img.png'
import { getImageAPI } from "../../../../http-common";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import aum from '../../../../assets/icons/om.jpg'
import naga from '../../../../assets/icons/naga.jpg'
import Error from '../error';
import TempleCard from '../../../common/card';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import deityImages from '../../../common/godImages';
import CommonResource from './commonResource';


let last_rec = 0;
let selected_last_rec = 0;
function Mantras() {
  const { t } = useTranslation();
  const records_per_page = 15;
  // const [isLoading, setIsLoading] = useState(false);
  const storedLanguage = localStorage.getItem('lng') || 5;
  const [did, setDid] = useState(0);
  const [tag, setTag] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const userid = localStorage.getItem('urole')
  const localEnv = false;
  const [filterId, setFilterId] = useState(0);
  const dispatch = useDispatch()
  const [messageApi, contextHolder] = message.useMessage();
  const [lastRec, setLastRec] = useState(0);
  const selected_per_page = 15;
  const { Search } = Input;

  const { uid } = useParams()


  const [mantras, setMantras] = useState('mantras');
  const { bDetails, bLoading, bError, godCtgryList, godCtgryListError, godCtgryListLoading } = useSelector(state => ({
    bDetails: getBooksList(state),
    bLoading: getBooksLoading(state),
    bError: getBooksError(state),
    godCtgryList: getGodNames(state) || [],
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
  }))

  useEffect(() => {
    if (selectedItem === null) {
      dispatch(fetchBooks(mantras, storedLanguage, did, 0))
    }
    else if (selectedItem != null) {
      dispatch(fetchBooksByTag(mantras, storedLanguage, filterId, selectedItem, 0));
    }
  }, [storedLanguage, did])
  

  const { tDetails, tLoading, tError } = useSelector(state => ({
    tDetails: getBooksByTagList(state),
    tLoading: getBooksByTagLoading(state),
    tError: getBooksByTagError(state),
  }))

  
  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    //dispatch(fetchContributions(0, failure));
  }, [])

  // const nextSetRef = useRef(null);


  // useEffect(() => {
  //   // Add a scroll event listener to the window
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     // Remove the scroll event listener when the component unmounts
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const handleScroll = () => {
  //   const windowHeight =
  //     "innerHeight" in window
  //       ? window.innerHeight
  //       : document.documentElement.offsetHeight;
  //   const body = document.body;
  //   const html = document.documentElement;
  //   const docHeight = Math.max(
  //     body.scrollHeight,
  //     body.offsetHeight,
  //     html.clientHeight,
  //     html.scrollHeight,
  //     html.offsetHeight
  //   );
  //   const windowBottom = windowHeight + window.pageYOffset;

  //   // Detect if the user has scrolled to the bottom of the page
  //   if (windowBottom >= docHeight && !isLoading) {
  //     // Load more data when scrolling to the end
  //     nextTemplesList();
  //   }
  // };

  function filterTemplesList(gDid) {
    if (gDid === filterId) {
      setDid(0);
      setFilterId(0);
      // setCurrentPageTemples([]); // Clear the current data when the filter is cleared
    } else {
      setDid(gDid);
      setFilterId(gDid);
      // setCurrentPageTemples([]); // Clear the current data when a new filter is applied
    }
  }

  // function getStyle(godCategoryId) {
  //   if (godCategoryId === filterId) {
  //     return {
  //       color: '#4096ff',
  //       borderColor: '#4096ff',
  //     };
  //   } else {
  //     return {
  //       borderColor: '#d9d9d9',
  //       color: '#000',
  //     };
  //   }
  // }

  function getStyle(godCategoryId) {
    if (godCategoryId === filterId) {
      return {

        boxShadow: '#d3d3d3 3px -2px 4px',
        borderRadius: '8px',
        filter: 'saturate(1)',
        border: '3px solid var(--colororange2)',
      };
    } else {
      return {
        border: '1px solid #ddd',
      };
    }
  }

  // const [currentPage, setCurrentPage] = useState(1);
  // const [currentPageTemples, setCurrentPageTemples] = useState([]);

  // useEffect(() => {
  //   dispatch(fetchBooks(storedLanguage, did, last_rec));
  // }, [storedLanguage, did, currentPage]);

  // useEffect(() => {
  //   if (!bLoading && bDetails) {
  //     setCurrentPageTemples([...currentPageTemples, ...bDetails]);
  //   }
  // }, [bLoading]);

  // const nextTemplesList = async () => {
  //   // setIsLoading(true);
  //   last_rec = last_rec + records_per_page;
  //   // did == filterId ? god_last_rec = god_last_rec + records_per_page : god_last_rec = 0 + records_per_page;
  //   dispatch(fetchBooks(storedLanguage, did, last_rec));
  //   // setIsLoading(false);
  // }

  // function prevTemplesList() {
  //   last_rec = last_rec - records_per_page;
  //   last_rec = (last_rec < 0) ? 0 : last_rec;
  //   // god_last_rec = god_last_rec - records_per_page;
  //   // god_last_rec = (god_last_rec < 0) ? 0 : god_last_rec;
  //   dispatch(fetchBooks(storedLanguage, did, last_rec))
  // };


  const [currentPage, setCurrentPage] = useState(0);
  const mainSecBlockRef = useRef(null);
  const nextTemplesList = async () => {
    setIsLoading(true);
    setCurrentPage(prevPage => {
      const newPage = prevPage + 1;

      if (filterId !== null && selectedItem === null) {
        const newLastRec = newPage * records_per_page;
        dispatch(fetchBooks(mantras, storedLanguage, did, newLastRec));
      }
      else if (selectedItem !== null) {
        const newSelectedLastRec = newPage * selected_per_page;
        dispatch(fetchBooksByTag(mantras, storedLanguage, filterId, selectedItem, newSelectedLastRec));
      }
      else if (searchText !== null) {
        const newSearchLastRec = newPage * selected_per_page;
        dispatch(fetchResourceByTxt(storedLanguage, searchText, newSearchLastRec));
      }
      if (mainSecBlockRef.current) {
        mainSecBlockRef.current.scrollTop = 0; // Scroll to the top of the container
      }
      return newPage;
    });
    setIsLoading(false);
  };

  // Previous button handler
  const prevTemplesList = () => {
    setIsLoading(true);
    setCurrentPage(prevPage => {
      if (prevPage > 0) { // Ensure we don't go below the first page
        const newPage = prevPage - 1; // Decrement page

        if (filterId !== null && selectedItem === null) {
          const newLastRec = newPage * records_per_page; // Calculate new offset for previous page
          dispatch(fetchBooks(mantras, storedLanguage, did, newLastRec));
        }
        else if (selectedItem !== null) {
          const newSelectedLastRec = newPage * selected_per_page; // Previous offset for selected items
          dispatch(fetchBooksByTag(mantras, storedLanguage, filterId, selectedItem, newSelectedLastRec));
        }
        else if (searchText !== null) {
          const newSearchLastRec = newPage * selected_per_page; // Previous offset for search mantras
          dispatch(fetchResourceByTxt(storedLanguage, searchText, newSearchLastRec));
        }
        if (mainSecBlockRef.current) {
          mainSecBlockRef.current.scrollTop = 0; // Scroll to the top of the container
        }
        return newPage; // Return updated page number
      }

      return prevPage; // If we're on the first page, don't decrement
    });
    setIsLoading(false);
  };


  const god = {
    1: 'Ganesha',
    2: 'Vishnu',
    3: 'Lakshmi',
    4: 'Durga',
    5: 'Sri Rama',
    6: 'Shiva',
    7: 'Krishna',
    8: 'Kartikeya',
    9: 'Hanuma',
    10: 'Brahma',
    11: 'Saraswati',
    12: 'Ayyappa',
    13: 'Saibaba',
    14: 'Narasimha',
    15: 'Surya',
    16: 'Budha',
  };

  const [refresh, setRefresh] = useState(false);

  const success = (title) => {
    showToast('success', t(`Added ${title} to favorites`))
  };

  const warning = (title) => {
    showToast('info', t(`Removed ${title} from favourites`))
  };

  const failure = (msg) => {
    showToast('error', t('label_loginerror'))
  };

  const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
  const [filledTemples, setFilledTemples] = useState({});

  const { fList, fLoading, fError } = useSelector(state => ({
    fList: getBooksFavouritesList(state),
    fLoading: getBooksFavouritesLoading(state),
    fError: getBooksFavouritesError(state),
  }));

  useEffect(() => {
    dispatch(fetchBooksFavourites());
  }, [getFavouriteTemples, refresh]);

  const [favList, setFavList] = useState([]);

  useEffect(() => {
    if (fList && fList.fav_list) {
      setFavList(fList.fav_list);
    }
  }, [fList]);



  function addFavourites(rid) {
    dispatch(postBooksFavourites(rid, failure))
  }

  function deleteFavourites(deleteId) {
    dispatch(removeBooksFavourites(deleteId, failure));
  }

  // let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.rid);

  const getToken = Boolean(localStorage.getItem('token'));

  function toggleFavorite(temple) {
    if (getToken) {
      const templeId = temple.rid;
      if (favList.includes(templeId)) {
        // Temple is in favList, remove it
        deleteFavourites(templeId, temple.title);
        warning(temple.title);
        // Update favList by removing the temple ID
        setFavList(prevFavList => prevFavList.filter(id => id !== templeId));
      } else {
        // Temple is not in favList, add it
        addFavourites(templeId, temple.title);
        success(temple.title);
        // Update favList by adding the temple ID
        setFavList(prevFavList => [...prevFavList, templeId]);
      }
    } else {
      // Handle the case when there's no token (e.g., show a message or redirect to login)
      failure("Please log in to favorite temples");
    }
  }


  const [filled, setFilled] = useState({});

  function toggleFilled(id) {
    setFilledTemples((prevFilled) => ({
      ...prevFilled,
      [id]: !prevFilled[id],
    }));
  }



  // const handleButtonClick = (itemName) => {
  //   if (selectedItem === itemName) {
  //     setSelectedItem(null);
  //     dispatch(fetchBooks(storedLanguage, did, 0));
  //   } else {
  //     setSelectedItem(itemName);
  //     const tagForKids = 'kids'; // Assuming 'tag' value for 'kids' is 'kids' itself
  //     dispatch(fetchBooksByTag(storedLanguage, filterId, tagForKids, 0));
  //   }
  // };

  const handleButtonClick = (itemName) => {
    if (itemName === "All") {
      setSelectedItem(null);
      dispatch(fetchBooks(mantras, storedLanguage, did, 0));
    } else {
      setSelectedItem(itemName);
      dispatch(fetchBooksByTag(mantras, storedLanguage, filterId, itemName, 0)); // Pass itemName as the tag
    }
  };

  const categoryButtons = ['kids', 'temple', 'puran', 'dharma', 'god', 'puja', 'festival'];

  const { sList, sLoading, sError } = useSelector(state => ({
    sList: getResourceByTxtList(state),
    sLoading: getResourceByTxtLoading(state),
    sError: getResourceByTxtError(state),
  }));


  const [searchText, setSearchText] = useState('');
  // const description = t("lable_mantras_descr");
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setSearchText(newValue);

    // Call the API only if the length of the input value is 5 or more
    if (newValue.length >= 5) {
      dispatch(fetchResourceByTxt(mantras, storedLanguage, newValue, 0));
    }
  };

  const [isGodModalVisible, setIsGodModalVisible] = useState(false);
  const [selectedFilterGod, setSelectedFilterGod] = useState(null);

  const handleFilterGodClick = () => {
    setIsGodModalVisible(true);
  };

  const handleFilterGodCancel = () => {
    setIsGodModalVisible(false);
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading data
    const loadData = async () => {
      setIsLoading(true);
      // Assume a function fetchData to get your data
      // await fetchData();
      setIsLoading(false);
    };
    loadData();
  }, [searchText, selectedItem, bDetails, sList, tDetails]);


  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {contextHolder}
      
      <CommonResource 
      title={t('label_mantras')}
      description={t('label_about_mantras')}
      bDetails={bDetails}
      sList={sList}
      tDetails={tDetails}
      nextTemplesList={nextTemplesList}
      prevTemplesList={prevTemplesList}
      currentPage={currentPage}
      fetchResource={fetchBooks}
      text={mantras}
      filterTemplesList={filterTemplesList}
      mainSecBlockRef={mainSecBlockRef}
      />
      {/* <div class="mainsec">

        <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>

          <div>
            <Link style={{ color: 'black' }} to='/'>
              <span className="bc-active-link">{t('Home')}</span>
            </Link> -{' '}
            <Link to='/library'>
            <span className="bc-active-link" onClick={handleGoBack}> {t('label_library')}</span></Link> -
            <span className="bc-link">{t('label_mantras')}</span>{' '}
            <i class="fa-solid fa-filter filter-link" onClick={handleFilterGodClick}> </i>&nbsp;
            {did !== 0 && (
              <div className="detail-container">
                <span className="god-button" >{god[did]}</span>
                <CloseCircleOutlined
                  className="close-icon"
                  onClick={() => {
                    setDid(0);
                    setFilterId(0);
                  }}
                />
              </div>
            )}
            {did === 0 && (
              <div className="detail-container">
                <span className="detail-but">{"All"}</span>
              </div>
            )}
          </div>
        </div>

        <Modal visible={isGodModalVisible} footer={handleFilterGodCancel}>
          <div className="diag-header">
            {t("label_god_categ")}
            <span class="diag-close">
              <i class="fa-solid fa-xmark" onClick={handleFilterGodCancel}></i>
            </span>
          </div>
          <div className="dialog-content god-category-list1">


            <div class="all-gods-sec">
              All
            </div>
            <div class="god_select">
              <div class="god_pic">
                <img style={getStyle(0)} className='dietyImg' src={aum} alt="All"
                  onClick={() => {
                    setFilterId(0);
                    setDid(0);
                    
                  }}
                />
                
                {categoryButtons.map((category, index) => (

                  <div class="god_pic" key={index}>
                    <button style={getStyle(0)} className='dietyBtn' alt={category}
                      onClick={() => {
                        handleButtonClick(category);
                        setIsGodModalVisible(false);
                      }}
                    >
                      {t('label_'+category)}
                    </button>
                   
                  </div>
                ))}
              </div>
            </div>


            <div class="all-gods-sec">
              Filter By God
            </div>
            
            <Row gutter={[16, 16]}>
              {godCtgryList &&
                godCtgryList.map((godCtgry, index) => (
                  <Col key={godCtgry.avatar}>
                    <div class="god_select">
                      <div class="god_pic">
                        <img style={getStyle(godCtgry.avatar)} src={deityImages[godCtgry.avatar]}
                          onClick={() => {
                            filterTemplesList(godCtgry.avatar);
                            setIsGodModalVisible(false);
                          }}
                        />
                        <p>{godCtgry.deity}</p>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
          
        </Modal>

        <div class="mainsec-block">
          
          <div class="container cat-txt ">
            <div class="cat-detail">
              <span class="f3 d-flex justify-content-between">
                <div><b>{t("About")} {t("label_mantras")}</b> &nbsp; &nbsp;<i class="fa-solid fa-volume-high"></i></div>
                {description.length >= 50 && (
                  <div className="detail-but" >
                    {t('label_show_more')}
                  </div>
                )}
              </span>
              <p class="f1">{description}
              </p>
            </div></div>
        
          <div className='container'>
            <Space>
              <Input
                className='resource-search'
                name="query"
                placeholder="Enter search keyword"
                value={searchText}
                onChange={handleInputChange}
                allowClear
                prefix={<SearchOutlined />}
                style={{ marginLeft: '10px' }}
              />
            </Space>
          </div>
         
          <div className="container d-flex mt-2 mb-2 custom-scrollbar">
            <button
              className={`btn1 btn btn-secondary ${selectedItem === null ? 'selected' : ''}`}
              onClick={() => handleButtonClick("All")}>{t('label_all')}</button>
            {categoryButtons.map((category, index) => (
              <button
                key={index}
                type="button"
                className={`btn1 btn btn-secondary ${selectedItem === category ? 'selected' : ''}`}
                onClick={() => handleButtonClick(category)}
              >
                {t('label_'+category)}
                <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
              </button>
            ))}
          </div>

          <div className="container">
            <Row>
              {renderContent()}
            </Row>

            <div className="next-row">
              {bDetails && bDetails.length > 0 ? (
                <Col>
                  <Button
                    className="btn btn-warning shadow-0 yellowbtn"
                    onClick={() => prevTemplesList()}
                    disabled={last_rec === 0}
                  >
                    Prev
                  </Button>
                </Col>
              ) : (
                ''
              )}
              {bDetails && bDetails.length > 0 ? (
                <Col>
                  <Button
                    className="btn btn-warning shadow-0 yellowbtn"
                    onClick={() => nextTemplesList()}
                    disabled={bDetails.length < 15}
                  >
                    Next
                  </Button>
                </Col>
              ) : (
                ''
              )}
            </div>
            <FloatButton.BackTop icon={<img style={{ height: 30, width: 30, marginLeft: -5 }} src={temple} alt="temple" />} />

          </div>
          <Footer />
        </div>
      </div> */}
    </>
  )
}


export default Mantras;