import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { getArticlesError, getArticlesList, getArticlesLoading, getBooksByTagError, getBooksByTagList, getBooksByTagLoading, getBooksError, getBooksFavouritesError, getBooksFavouritesList, getBooksFavouritesLoading, getBooksList, getBooksLoading, getFavouritesError, getFavouritesList, getFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getResourceByTxtError, getResourceByTxtList, getResourceByTxtLoading } from '../../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchArticles, fetchBooks, fetchBooksByTag, fetchBooksFavourites, fetchFavourites, fetchGodNames, fetchResourceByTxt, postBooksFavourites, postFavourites, removeBooksFavourites, removeFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import { Button, Card, Col, FloatButton, Image, Modal, Row, Select, Skeleton, message, Input, Space, Result, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import CommonResource from './commonResource';
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import aum from '../../../../assets/icons/om.jpg'
import deityImages from '../../../common/godImages';
import Footer from '../footer/footer';



let last_rec = 0;
let selected_last_rec = 0;
function Articles() {
  const storedLanguage = localStorage.getItem('lng') || 5; // Get the stored language or set default to 'te'
  // const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
  const records_per_page = 15;
  // const [isLoading, setIsLoading] = useState(false);
  const localEnv = false;
  const { t } = useTranslation();
  const [lastRec, setLastRec] = useState(0);
  const userid = localStorage.getItem('urole')
  const dispatch = useDispatch()
  const [messageApi, conarticlesHolder] = message.useMessage();
  const selected_per_page = 15;
  const navigate = useNavigate();
  const { uid } = useParams()


  const { aDetails, aLoading, aError } = useSelector(state => ({
    aDetails: getBooksList(state),
    aLoading: getBooksLoading(state),
    aError: getBooksError(state),
  }))

  useEffect(() => {
    dispatch(fetchBooks(articles, storedLanguage, did, 0))
  }, [storedLanguage, did])

  const { tDetails, tLoading, tError, godCtgryList } = useSelector(state => ({
    tDetails: getBooksByTagList(state),
    tLoading: getBooksByTagLoading(state),
    tError: getBooksByTagError(state),
    godCtgryList: getGodNames(state) || [],
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
  }))

  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure()) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    //dispatch(fetchContributions(0, failure));
  }, [])

  const [isGodModalVisible, setIsGodModalVisible] = useState(false);

  const [did, setDid] = useState(0); // Initialize 'did' to 0
  const [filterId, setFilterId] = useState(0);

  function filterTemplesList(gDid) {
    if (gDid === filterId) {
      setDid(0);
      setFilterId(0);
      // dispatch(fetchBooks(articles, storedLanguage, 0, 0));
      // setCurrentPageTemples([]); // Clear the current data when the filter is cleared
    } else {
      setDid(gDid);
      setFilterId(gDid);
      // dispatch(fetchBooks(articles, storedLanguage, gDid, 0));
      // setCurrentPageTemples([]); // Clear the current data when a new filter is applied
    }
  }


  const handleButtonClick = (itemName) => {
    if (itemName === "All") {
      setSelectedItem(null); // Reset selectedItem to null to enable all buttons     
      dispatch(fetchBooks(articles, storedLanguage, did, 0));
    } else {
      setSelectedItem(itemName);
      dispatch(fetchBooksByTag(articles, storedLanguage, filterId, itemName, 0)); // Pass itemName as the tag
    }
  };

  const handleFilterGodClick = () => {
    setIsGodModalVisible(true);
  };

  const handleFilterGodCancel = () => {
    setIsGodModalVisible(false);
  };

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (uid) {
      setSearchText(uid);
      if (uid.length >= 5) {
        dispatch(fetchResourceByTxt(uid)); // Fetch results on load or when uid changes
      }
    }
  }, [uid, dispatch]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setSearchText(newValue);

    // Call the API only if the length of the input value is 5 or more
    if (newValue.length >= 5) {
      dispatch(fetchResourceByTxt(articles, storedLanguage, newValue, 0));
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const god = {
    1: 'Ganesha',
    2: 'Vishnu',
    3: 'Lakshmi',
    4: 'Durga',
    5: 'Sri Rama',
    6: 'Shiva',
    7: 'Krishna',
    8: 'Kartikeya',
    9: 'Hanuma',
    10: 'Brahma',
    11: 'Saraswati',
    12: 'Ayyappa',
    13: 'Saibaba',
    14: 'Narasimha',
    15: 'Surya',
    16: 'Budha',
  };


  const success = (title) => {
    showToast('success', `Added ${title} to favorites`)

  };
  const warning = (title) => {
    showToast('info', `Removed ${title} from favourites`)

  };

  const failure = (msg) => {
    showToast('error', t('label_loginerror'))
  };



  const [filledTemples, setFilledTemples] = useState({});
  const description = t("label_about_articles")

  const [showTags, setShowTags] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [articles, setArticles] = useState('articles');

  const { sList, sLoading, sError } = useSelector(state => ({
    sList: getResourceByTxtList(state),
    sLoading: getResourceByTxtLoading(state),
    sError: getResourceByTxtError(state),
  }));


  // useEffect(() => {
  //   dispatch(fetchResourceByTxt(articles, storedLanguage, searchText, 0));
  // }, [storedLanguage, searchText]);

  const [isLoading, setIsLoading] = useState(true);

  function getStyle(godCategoryId) {
    if (godCategoryId === filterId) {
      return {

        boxShadow: '#d3d3d3 3px -2px 4px',
        borderRadius: '8px',
        filter: 'saturate(1)',
        border: '3px solid var(--colororange2)',
      };
    } else {
      return {
        border: '1px solid #ddd',
      };
    }
  }

  const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const { fList, fLoading, fError } = useSelector(state => ({
    fList: getBooksFavouritesList(state),
    fLoading: getBooksFavouritesLoading(state),
    fError: getBooksFavouritesError(state),
  }));

  useEffect(() => {
    {
      getToken && (
        dispatch(fetchBooksFavourites())
      )
    }
  }, [getFavouriteTemples, refresh]);

  const [favList, setFavList] = useState([]);

  useEffect(() => {
    if (fList && fList.fav_list) {
      setFavList(fList.fav_list);
    }
  }, [fList]);



  function addFavourites(rid) {
    dispatch(postBooksFavourites(rid, failure))
  }

  function deleteFavourites(deleteId) {
    dispatch(removeBooksFavourites(deleteId, failure));
  }

  // let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.rid);

  const getToken = Boolean(localStorage.getItem('token'));

  function toggleFavorite(temple) {
    if (getToken) {
      const templeId = temple.rid;
      if (favList.includes(templeId)) {
        // Temple is in favList, remove it
        deleteFavourites(templeId, temple.title);
        warning(temple.title);
        // Update favList by removing the temple ID
        setFavList(prevFavList => prevFavList.filter(id => id !== templeId));
      } else {
        // Temple is not in favList, add it
        addFavourites(templeId, temple.title);
        success(temple.title);
        // Update favList by adding the temple ID
        setFavList(prevFavList => [...prevFavList, templeId]);
      }
    } else {
      // Handle the case when there's no token (e.g., show a message or redirect to login)
      failure("Please log in to favorite temples");
    }
  }


  const [filled, setFilled] = useState({});

  function toggleFilled(id) {
    setFilledTemples((prevFilled) => ({
      ...prevFilled,
      [id]: !prevFilled[id],
    }));
  }


  const [currentPage, setCurrentPage] = useState(0);
  const mainSecBlockRef = useRef(null);
  const nextTemplesList = async () => {
    setIsLoading(true);
    setCurrentPage(prevPage => {
      const newPage = prevPage + 1;

      if (filterId !== null && selectedItem === null) {
        const newLastRec = newPage * records_per_page;
        dispatch(fetchBooks(articles, storedLanguage, did, newLastRec));
      }
      else if (selectedItem !== null) {
        const newSelectedLastRec = newPage * selected_per_page;
        dispatch(fetchBooksByTag(articles, storedLanguage, filterId, selectedItem, newSelectedLastRec));
      }
      else if (searchText !== null) {
        const newSearchLastRec = newPage * selected_per_page;
        dispatch(fetchResourceByTxt(storedLanguage, searchText, newSearchLastRec));
      }
      if (mainSecBlockRef.current) {
        mainSecBlockRef.current.scrollTop = 0; 
      }
      return newPage;
    });
    setIsLoading(false);
  };

  // Previous button handler
  const prevTemplesList = () => {
    setIsLoading(true);
    setCurrentPage(prevPage => {
      if (prevPage > 0) { // Ensure we don't go below the first page
        const newPage = prevPage - 1; // Decrement page

        if (filterId !== null && selectedItem === null) {
          const newLastRec = newPage * records_per_page; // Calculate new offset for previous page
          dispatch(fetchBooks(articles, storedLanguage, did, newLastRec));
        }
        else if (selectedItem !== null) {
          const newSelectedLastRec = newPage * selected_per_page; // Previous offset for selected items
          dispatch(fetchBooksByTag(articles, storedLanguage, filterId, selectedItem, newSelectedLastRec));
        }
        else if (searchText !== null) {
          const newSearchLastRec = newPage * selected_per_page; // Previous offset for search articles
          dispatch(fetchResourceByTxt(storedLanguage, searchText, newSearchLastRec));
        }
        if (mainSecBlockRef.current) {
          mainSecBlockRef.current.scrollTop = 0; 
        }
        return newPage; // Return updated page number
      }

      return prevPage; // If we're on the first page, don't decrement
    });
    setIsLoading(false);
  };

  return (
    <>
      {conarticlesHolder}
      
      <CommonResource
        title={t('label_articles')}
        description={t('label_about_articles')}
        bDetails={aDetails}
        sList={sList}
        tDetails={tDetails}
        fetchResource={fetchBooks}
        articles={articles}
        text={articles}
        filterTemplesList={filterTemplesList}
        nextTemplesList={nextTemplesList}
        prevTemplesList={prevTemplesList}
        currentPage={currentPage}
        mainSecBlockRef={mainSecBlockRef}
      />


     

    </>
  )
}


export default Articles;