import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Result, Select, message } from 'antd';
import { fetchMyRequests } from '../../redux/actions/acions';
import { getMyRequestsError, getMyRequestsList, getMyRequestsLoading } from '../../redux/selectors/selectors';
import CustomToastContainer from '../../components/common/CustomToastContainer';
import Footer from '../pages/Homepage/footer/footer';
import { useTranslation } from 'react-i18next';
import Error from '../pages/Homepage/error';

const MyReq = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    let lr = 0;
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getMyRequestsList(state),
        rLoading: getMyRequestsLoading(state),
        rError: getMyRequestsError(state),
    }));

    const status = "all";
    useEffect(() => {
        dispatch(fetchMyRequests(status, lr));
    }, [dispatch]);

    const [selectedValue, setSelectedValue] = useState('all');

    const handleSelectChange = (selectedValue) => {
        dispatch(fetchMyRequests(selectedValue, lr));
    };


    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className='main'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_my_requests')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}
            
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">Home</span>
                </Link>
                &nbsp;-&nbsp;
                <span className="bc-link">{t("label_my_requests")}</span>
                <Select
                    showSearch  // Enable search
                    onChange={handleSelectChange}
                    name="spclty"
                    value={selectedValue}
                    className="select-element"
                    style={{ marginLeft: '5px', width: '15%' }}
                    placeholder='Please select status'
                >
                    <Select.Option value="">{t('label_select_status')}</Select.Option>
                    <Select.Option value="all">{t('label_all')}</Select.Option>
                    <Select.Option value="Open">{t('label_see_them')}</Select.Option>
                    <Select.Option value="Close">{t('label_close')}</Select.Option>
                    {/* <Select.Option value="N">{t('NetBank')}</Select.Option>
                                    <Select.Option value="C">{t('Cheque')}</Select.Option> */}
                </Select>
            </div>
            <div className='container'>
                {rLoading ? (
                    <Error />
                ) : rError ? (
                    <Result
                        status="500"
                        title="Error"
                        subTitle="Sorry, there was an error fetching the data."
                    />
                ) : rList && rList.length > 0 ? (
                    rList.map((notificationList) => (
                        <Link to={`/reqDetails/${notificationList.reqid}`} className="notification-link" key={notificationList.nid}>
                            <div className="tiles-designs-events">
                                <div className="d-flex flex-space-between">
                                    <div className="f1 events-block-link-title">
                                        <i className="fa-solid fa-calendar-week mr-3"></i>
                                        <span className="f1">
                                            {notificationList.ctgry === 'H' ? t('label_horo') : notificationList.ctgry === '2' ? t('label_muhurtham') : ''}
                                        </span>
                                        <span className="f1 events-block-link-value">
                                            {`${new Date(notificationList.ludt).toISOString().split('T')[0]} - ${notificationList.status}`}
                                        </span>
                                    </div>
                                    <div className="d-flex">
                                        <Link to={`/editEvent/${notificationList.nid}`}>
                                            <div className="detail-but ml-3">
                                                Edit
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <span className="f1 events-block-link-value">
                                    {`${notificationList.reqtype === 'V'
                                        ? t('label_vratham')
                                        : notificationList.reqtype === 'J'
                                            ? t('label_jathakam')
                                            : t('label_notchosen')} - ${notificationList.ctgry === '1' || notificationList.ctgry === 'H'
                                                ? t('label_horo')
                                                : notificationList.ctgry === '2'
                                                    ? t('label_muhurtham')
                                                    : notificationList.ctgry === '3'
                                                        ? t('label_puja')
                                                        : notificationList.ctgry === '4'
                                                            ? t('label_homam')
                                                            : t('label_notchosen')
                                        }`}
                                </span>
                            </div>
                        </Link>
                    ))
                ) : (
                    <Result
                        status="404"
                        title="No Data Found"
                        subTitle="Sorry, no requests found."
                    />
                )}
            </div>
            <Footer />
        </div>
    );
};

export default MyReq;
