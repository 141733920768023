import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row, Skeleton, message, Form, Input, Button, Space, DatePicker, Result } from 'antd';
import { getDailyHoroscopeDetailsList, getDailyHoroscopeDetailsLoading, getHoroDetailsByDtError, getHoroDetailsByDtList, getHoroDetailsByDtLoading, getWeeklyHoroscopeDetailsList, getWeeklyHoroscopeDetailsLoading, getWeeklyHoroscopeList, getWeeklyHoroscopeLoading } from '../../../../redux/selectors/selectors';
import { editHoroDetails, editWeeklyHoroDetails, fetchDailyHoroscopeDetails, fetchHoroDetailsByDate, fetchWeeklyHoroscope, fetchWeeklyHoroscopeDetails, updateHoroTitle, updateHoroTxt } from '../../../../redux/actions/acions';
import deftemples from '../../../../assets/images/templeicon.png';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import Footer from '../footer/footer';
import moment from 'moment';
import nodata from '../../../../assets/images/nodata5.jpg';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import ShowMoreText from '../../../common/showMoreText';

const WeeklyHoroscopeDetails = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { hid } = useParams();
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [title, setTitle] = useState(null);
    const [txt, setTxt] = useState(null);
    const [form] = Form.useForm();
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const userid = localStorage.getItem('urole');
    const [isDateChanged, setIsDateChanged] = useState(false);

    const { horoDet, horLoading, horoError } = useSelector((state) => ({
        horoDet: getWeeklyHoroscopeList(state),
        horLoading: getWeeklyHoroscopeLoading(state),
        horoError: getHoroDetailsByDtError(state),
    }));

    const fetchHoroscopeForDate = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        dispatch(fetchWeeklyHoroscope('whoro', storedLanguage, formattedDate, failure));
    };

    useEffect(() => {
        fetchHoroscopeForDate(selectedDate);
    }, [storedLanguage, dispatch, selectedDate]);

    const onChange = (event) => {
        const date = event.target.value;
        setSelectedDate(date);
        setIsDateChanged(true);
        fetchHoroscopeForDate(date);
    };

    const { hDetails, hLoading } = useSelector((state) => ({
        hDetails: getWeeklyHoroscopeDetailsList(state),
        hLoading: getWeeklyHoroscopeDetailsLoading(state),
    }));

    useEffect(() => {
        dispatch(fetchWeeklyHoroscopeDetails(hid, storedLanguage));
    }, [storedLanguage, dispatch]);

    useEffect(() => {
        if (hDetails && hDetails.publish_date) {
            setSelectedDate(hDetails.publish_date);
        }
    }, [hDetails]);

    const success = () => {
        showToast('success', t('label_horoedit_success'));
        form.resetFields();
    };

    const failure = (msg) => {
        showToast('fail', msg);
    };

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const token = localStorage.getItem('token');

    const handleCancelClick = () => {
        setButtonText('Edit');
        setEditable(false);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("label_cancel"));
            setTitle(hDetails && hDetails.title);
            setTxt(hDetails && hDetails.txt);
        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleSaveClick = () => {
        form
            .validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                const dataToSend = {
                    nakshatra: values.nakshatra || hDetails.nakshatra,
                    tithi: values.tithi || hDetails.tithi,
                    yg_time: values.yg_time || hDetails.yg_time,
                    rh_time: values.rh_time || hDetails.rh_time,
                    "mesha": values.mesha || hDetails.mesha,
                    "vrushab": values.vrushab || hDetails.vrushab,
                    "midhuna": values.midhuna || hDetails.midhuna,
                    "karkataka": values.karkataka || hDetails.karkataka,
                    "simha": values.simha || hDetails.simha,
                    "kanya": values.kanya || hDetails.kanya,
                    "thula": values.thula || hDetails.thula,
                    "vruchika": values.vruchika || hDetails.vruchika,
                    "dhanassu": values.dhanassu || hDetails.dhanassu,
                    "makara": values.makara || hDetails.makara,
                    "kumbha": values.kumbha || hDetails.kumbha,
                    "meena": values.meena || hDetails.meena,
                    "title": values.title || hDetails.title,
                    "txt": values.txt || hDetails.txt,
                };
                dispatch(editWeeklyHoroDetails(hid, storedLanguage, dataToSend, success, failure));
            })
    };


    const updateTitle = (e) => {
        dispatch(updateHoroTitle(hid, storedLanguage, { title: `${e.target.value}` }, () => showToast('success', ' Updated Title Successfully', 'failure', 'Error occured')))
    };

    const updateTxt = (e) => {
        dispatch(updateHoroTxt(hid, storedLanguage, { txt: `${e.target.value}` }, () => showToast('success', ' Updated Text Successfully', 'failure', 'Error occured')))
    };

    const dataToDisplay = isDateChanged ? horoDet : hDetails;

    const handleLinkClick = () => {
        // setOpen(false);
        message.info('User does not have permission !');
    };

    const handlePrevious = () => {
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() - 1);
        const formattedDate = newDate.toISOString().split('T')[0];
        setSelectedDate(formattedDate);
        fetchHoroscopeForDate(formattedDate);
        setIsDateChanged(true);
    };

    const handleNext = () => {
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() + 1);
        const formattedDate = newDate.toISOString().split('T')[0];
        setSelectedDate(formattedDate);
        fetchHoroscopeForDate(formattedDate);
        setIsDateChanged(true);
    };

    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_weekly_horoscope_det')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}
            
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>

                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> -
                <span className="bc-active-link" onClick={handleGoBack}> {t('label_horo')}</span> -

                &nbsp;<span className="bc-link">{t("label_weekly_horoscope_det")}</span>
            </div>
            <div className="bg-details-highlight" style={{ overflowX: 'auto', paddingBottom: '10px', whiteSpace: "nowrap" }}>
                <div className="details-headertxt container">
                    <div className="header_txt">
                        {dataToDisplay && dataToDisplay.title}
                    </div>
                    <div className="button-row">


                        <button style={{ fontWeight: "bold" }} className="button_move" onClick={handlePrevious}>{t('label_previous')}</button>
                        <input
                            type="date"
                            value={selectedDate}
                            onChange={onChange}
                            className="date-input"
                        />

                        <button style={{ fontWeight: "bold" }} className="button_move" onClick={handleNext}>{t('label_next')}</button>
                        {(userid === "AD" || userid === "AS") && editable && (
                            <a href="#" className="button_move"
                                onClick={handleSaveClick}
                                style={{ marginLeft: "5px", marginRight: '5px', fontWeight: '200px' }}
                            >
                                {t("label_save")}
                            </a>
                        )}
                        {(userid === "AD" || userid === "AS") ? (
                            <a href="#" className="button_move"
                                onClick={editable ? handleCancelClick : handleEditClick} style={{ marginLeft: "5px" }}> {buttonText} </a>
                        ) : ('')}
                        <a
                            style={{ marginTop: "3px" }}
                            type="link"
                            onClick={() => {
                                if (navigator.share) {
                                    navigator
                                        .share({
                                            title: dataToDisplay && dataToDisplay.title,
                                            text: "Check out this horoscope!",
                                            url: `https://www.templeswiki.com/horoscopeDetails/${hid}`
                                        })
                                        .then(() => console.warn("Share successful."))
                                        .catch((error) => console.error("Error sharing:", error));
                                } else {
                                    console.error("Web Share API not supported.");
                                }
                            }}
                        >
                            <i title="Share" className="fa-solid fa-share-nodes"></i>
                        </a>
                    </div>
                    {/* {(userid === "AD" || userid === "AS") && (
                        <div className="container" style={{ display: 'flex', justifyContent: 'end' }}>
                             <Link to='/editHoroscope/0' className="btn btn-warning shadow-0 yellowbtn">{t("label_create_weekly_horo")}</Link>
                        </div>
                    )} */}
                </div>
            </div>
            <div class="mainsec-block-addtemple-detailspage">
                <div className='container'>
                    {hLoading || horLoading ? (
                        <p>Loading...</p>
                    ) : (
                        dataToDisplay ? (
                            <div className='horoscope-item'>
                                <div className="float-left w-100 mt-4"><h5 className="card-text-title">{t("label_details")} <span>| {t('label_horo_det_tit')}</span></h5></div>
                                <Form form={form}>
                                    <div className="active-details">
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_title")}</Typography.Title>} name="title">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.title} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.title}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_dhanassu")}</Typography.Title>} name="dhanassu">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={dataToDisplay.dhanassu} style={{ marginTop: '6px' }} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.dhanassu}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        {editable && (
                                            <div className="tiles-designs">
                                            </div>
                                        )}
                                        {editable && (
                                            <div className="tiles-designs">
                                            </div>
                                        )}
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_mesha")}</Typography.Title>} name="mesha">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={dataToDisplay.mesha} style={{ marginTop: '6px' }} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.mesha}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_vrushab")}</Typography.Title>} name="vrushab">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={dataToDisplay.vrushab} style={{ marginTop: '6px' }} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.vrushab}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_midhuna")}</Typography.Title>} name="midhuna">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={dataToDisplay.midhuna} style={{ marginTop: '6px' }} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.midhuna}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_karktaka")}</Typography.Title>} name="karkataka">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={dataToDisplay.karkataka} style={{ marginTop: '6px' }} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.karkataka}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_simha")}</Typography.Title>} name="simha">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={dataToDisplay.simha} style={{ marginTop: '6px' }} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.simha}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_kanya")}</Typography.Title>} name="kanya">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={dataToDisplay.kanya} style={{ marginTop: '6px' }} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.kanya}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_thula")}</Typography.Title>} name="thula">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={dataToDisplay.thula} style={{ marginTop: '6px' }} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.thula}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_vruchika")}</Typography.Title>} name="vruchika">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={dataToDisplay.vruchika} style={{ marginTop: '6px' }} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.vruchika}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_dhanassu")}</Typography.Title>} name="dhanassu">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={dataToDisplay.dhanassu} style={{ marginTop: '6px' }} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.dhanassu}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_makara")}</Typography.Title>} name="makara">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={dataToDisplay.makara} style={{ marginTop: '6px' }} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.makara}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_kumbha")}</Typography.Title>} name="kumbha">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={dataToDisplay.kumbha} style={{ marginTop: '6px' }} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.kumbha}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_meena")}</Typography.Title>} name="meena">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={dataToDisplay.meena} style={{ marginTop: '6px' }} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.meena}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_author")}</Typography.Title>} name="title">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.author} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.author}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_details")}</Typography.Title>} name="txt">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={dataToDisplay.txt} style={{ marginTop: '6px' }} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.txt}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                    </div>
                                </Form>
                            </div>
                        ) : (
                            <Result status="404" title="No Data Found" subTitle="Sorry, Horoscope details not found." />
                        )
                    )}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default WeeklyHoroscopeDetails;
