import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Select, Typography, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getResourceError, getResourceList, getResourceLoading, getTempleGroupError, getTempleGroupList, getTempleGroupLoading } from '../../../../redux/selectors/selectors';
import { addTempleToGroup, fetchResource, fetchTempleGroups, publishResource, removeResource } from '../../../../redux/actions/acions';
import { getImageAPI } from '../../../../http-common';
import { Link, useParams } from 'react-router-dom';
import deftemples from '../../../../assets/images/templeicon.png';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';


function TempleData() {
    const { t } = useTranslation();
    const { gid } = useParams();
    const phoneRegex = /^[0-9]+$/;
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [templeid, setTempleid] = useState('');
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const storedLanguage = localStorage.getItem('lng') || 5; // Get the stored language or set default to 'te'
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
    const rid = useParams();
    //   const handleChange = (value) => {
    //     localStorage.setItem('lng', value);
    //     setSelectedLanguage(value);
    //     // Make API request here with the selected language
    //     dispatch(fetchResource(value, 0));
    //   };

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        showToast('success', t('label_tempgrp_success'))
        // messageApi.open({
        //     type: 'success',
        //     content: 'SUCCESSFULLY SUBMITTED',
        // });
        //form.resetFields()
    };

    const failure = (msg) => {
        showToast('fail', t('label_tempgrp_failure'))
        // messageApi.open({
        //     type: 'failed',
        //     content: msg,

        // });
    };

    const dispatch = useDispatch();
    const { nList, nLoading, nError } = useSelector((state) => ({
        nList: getTempleGroupList(state),
        nLoading: getTempleGroupLoading(state),
        nError: getTempleGroupError(state),
    }));

    useEffect(() => {
        // Initial API request on component mount
        dispatch(fetchTempleGroups(selectedLanguage, 0));
    }, [storedLanguage]);

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])

    const openDialog = (group) => {
        setSelectedGroup(group);
        setDialogVisible(true);
    };

    const handleDialogSubmit = () => {
        if (selectedGroup) {
            dispatch(addTempleToGroup(selectedGroup.gid, templeid, success, failure));
            setDialogVisible(false);
        }
    };
    const handleDialogueCancel = () => {
        setDialogVisible(false)
    }


    return (
        <>
            {contextHolder}
            <CustomToastContainer/>
            <div class='mainsec'>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">Home</span></Link> -
                    &nbsp;<span className="bc-link">{t("label_tmpl_groups_list")}</span>
                </div>
                <div className="container" style={{ display: 'flex', justifyContent: 'end' }}>
                    <Link to='/addTempleGroup/0'><button type="button" class="btn1 btn btn-secondary " style={{ marginLeft: '5px', marginBottom: '5px' }}>{t('Create Temple Group')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                </div>
                <div class="mainsec-block-detailspage">
                    <div className='container'>
                        <section className="container h-100 d-flex flex-column">
                            <div className="search-rt-sec1 ">
                                <Row>
                                    {
                                        nList && nList.map(temple => {
                                            return (
                                                <Col className='youtube-video' key={temple.rid}>
                                                    <div class="tiles-design animate__animated animate__flipInX">
                                                        <div >

                                                            <div class="card-body p-0">

                                                                {/* <Link to={`/editResource/${temple.rid}`} >
                                                            <EditOutlined style={{ color: 'orange', marginRight:'70%' }} />
                                                        </Link> */}



                                                                <Modal
                                                                    visible={dialogVisible}
                                                                    onOk={handleDialogSubmit}
                                                                    onCancel={handleDialogueCancel}
                                                                    destroyOnClose
                                                                    maskClosable={true}
                                                                    mask={false}
                                                                    style={{ boxShadow: 'none' }}
                                                                >
                                                                    <div class="diag-header">Enter Temple ID
                                                                        <span class="diag-close"><i class="fa-solid fa-xmark" onClick={handleDialogueCancel}></i></span></div>

                                                                    <Form

                                                                        name="nest-messages"
                                                                        labelCol={{
                                                                            span: 24,
                                                                        }}
                                                                        wrapperCol={{
                                                                            span: 24,
                                                                        }}
                                                                    >
                                                                        <div class="active-details">
                                                                            <div class="tiles-designs" >
                                                                                <Form.Item

                                                                                    label="Temple ID"
                                                                                    name="templeid"
                                                                                    rules={[
                                                                                        { required: true, message: 'Please enter TempleID' },
                                                                                        {
                                                                                            pattern: phoneRegex,
                                                                                            message: 'Please enter a valid Phone Number! Only numbers are allowed.',
                                                                                        },
                                                                                    ]}
                                                                                > <Input
                                                                                        value={templeid}
                                                                                        onChange={(e) => setTempleid(e.target.value)}
                                                                                    />

                                                                                </Form.Item>
                                                                            </div>
                                                                        </div>
                                                                    </Form>

                                                                </Modal>
                                                                <Link to={`/groupDetails/${temple.gid}`} >
                                                                    <div class="p-2">
                                                                        <h6 className="home-temple-text" title={temple.group_name}>{temple.group_name}</h6>
                                                                        <p class="small mb-1">{temple.descr}</p>
                                                                        <p className="small mb-1">
                                                                            <a href={temple.src_url} target="_blank" rel="noopener noreferrer">
                                                                                {temple.src_url}
                                                                            </a>
                                                                        </p>
                                                                    </div>
                                                                    </Link>
                                                                    <div class="tile-quicklink">
                                                                        {/* <Link to={`/addTempleGroup/${temple.gid}`} >
                                                                    <i class="fa-solid fa-edit" ></i>
                                                                </Link> */}
                                                                        <i class="fa-solid fa-circle-plus" onClick={() => openDialog(temple)}  ></i>
                                                                    </div>
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default TempleData;
