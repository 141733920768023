import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row, Skeleton, message, Form, Input, Button, Space, DatePicker, Result, Calendar } from 'antd';
import { getDailyHoroscopeDetailsList, getDailyHoroscopeDetailsLoading, getHoroDetailsByDtError, getHoroDetailsByDtList, getHoroDetailsByDtLoading } from '../../../../redux/selectors/selectors';
import { editHoroDetails, fetchDailyHoroscopeDetails, fetchHoroDetailsByDate, updateHoroTitle, updateHoroTxt } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import Footer from '../footer/footer';
import moment from 'moment';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import ShowMoreText from '../../../common/showMoreText';
import dayjs from 'dayjs';

const HoroscopeDetails = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { hid } = useParams();
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [title, setTitle] = useState(null);
    const [txt, setTxt] = useState(null);
    const [form] = Form.useForm();
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const [selectedDateFull, setSelectedDateFull] = useState(dayjs());
    const userid = localStorage.getItem('urole');
    const [isDateChanged, setIsDateChanged] = useState(false);

    const { horoDet, horLoading, horoError } = useSelector((state) => ({
        horoDet: getHoroDetailsByDtList(state),
        horLoading: getHoroDetailsByDtLoading(state),
        horoError: getHoroDetailsByDtError(state),
    }));

    const fetchHoroscopeForDate = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        dispatch(fetchHoroDetailsByDate(1, storedLanguage, formattedDate, failure));
    };

    useEffect(() => {
        const selectedFullDate = selectedDateFull ? selectedDateFull.format('YYYY-MM-DD') : selectedDate;
        fetchHoroscopeForDate(selectedFullDate);
    }, [storedLanguage, dispatch, selectedDate, selectedDateFull]);
    

    const onPanelChange = (value, mode) => {
        console.log(value.format('YYYY-MM-DD'), mode);
    };

    const onChange = (event) => {
        const date = event.target.value;
        setSelectedDate(date);
        setSelectedDateFull(dayjs(date));
        setIsDateChanged(true);
        fetchHoroscopeForDate(date);
    };


    const onCalenderChange = (date) => {
        if (date) {
            const formattedDate = date.format('YYYY-MM-DD');
            setSelectedDateFull(date);
            setSelectedDate(formattedDate);
            fetchHoroscopeForDate(formattedDate);
        }
    };

    const { hDetails, hLoading } = useSelector((state) => ({
        hDetails: getDailyHoroscopeDetailsList(state),
        hLoading: getDailyHoroscopeDetailsLoading(state),
    }));

    useEffect(() => {
        dispatch(fetchDailyHoroscopeDetails(hid, storedLanguage));
    }, [storedLanguage, dispatch]);

    useEffect(() => {
        if (hDetails && hDetails.publish_date) {
            // Assuming `hDetails.publish_date` is in YYYY-MM-DD format, convert it to Day.js
            const formattedDate = dayjs(hDetails.publish_date);
            setSelectedDate(hDetails.publish_date); // This is a string, used for input field
            setSelectedDateFull(formattedDate); // This is a Day.js object, used for Calendar
        }
    }, [hDetails]);

    const success = () => {
        showToast('success', t('label_horoedit_success'));
        form.resetFields();
    };

    const failure = (msg) => {
        showToast('fail', msg);
    };

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const token = localStorage.getItem('token');

    const handleCancelClick = () => {
        setButtonText('Edit');
        setEditable(false);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("label_cancel"));
            setTitle(hDetails && hDetails.title);
            setTxt(hDetails && hDetails.txt);
        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleSaveClick = () => {
        const values = form.getFieldsValue();
        const dataToSend = {
            year_info: values.year_info || hDetails.year_info,
            maasam: values.maasam || hDetails.maasam,
            vaaram: values.vaaram || hDetails.vaaram,
            ruthuvu: values.ruthuvu || hDetails.ruthuvu,
            nakshatra: values.nakshatra || hDetails.nakshatra,
            chandrarasi: values.chandrarasi || hDetails.chandrarasi,
            karanam: values.karanam || hDetails.karanam,
            publish_date: values.publish_date || hDetails.publish_date,
            shubhakal: values.shubhakal || hDetails.shubhakal,
            sunrise: values.sunrise || hDetails.sunrise,
            sunset: values.sunset || hDetails.sunset,
            suryarasi: values.suryarasi || hDetails.suryarasi,
            tithi: values.tithi || hDetails.tithi,
            yg_time: values.yg_time || hDetails.yg_time,
            rh_time: values.rh_time || hDetails.rh_time,
            varjyam: values.vaaram || hDetails.vaaram,
            amruthkal: values.amruthkal || hDetails.amruthkal,
            durmuhurth: values.durmuhurth || hDetails.durmuhurth,
            yoga: values.yoga || hDetails.yoga,
        };
        dispatch(editHoroDetails(hid, storedLanguage, dataToSend, success, failure));
    };

    const updateTitle = () => {
        dispatch(updateHoroTitle(hid, storedLanguage, { title: title }, success()));
    };

    const updateTxt = () => {
        dispatch(updateHoroTxt(hid, storedLanguage, { txt: txt }, success()));
    };

    const dataToDisplay = isDateChanged ? horoDet : hDetails;

    const handleLinkClick = () => {
        // setOpen(false);
        message.info('User does not have permission !');
    };

    const handlePrevious = () => {
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() - 1);
        const formattedDate = newDate.toISOString().split('T')[0];
        setSelectedDate(formattedDate);
        fetchHoroscopeForDate(formattedDate);
        setIsDateChanged(true);
    };

    const handleNext = () => {
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() + 1);
        const formattedDate = newDate.toISOString().split('T')[0];
        setSelectedDate(formattedDate);
        fetchHoroscopeForDate(formattedDate);
        setIsDateChanged(true);
    };

    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_daily_panchamgam_det')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}
            
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>
                {/* <span className="breadcrumb-link"> */}
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> -
                <span className="bc-active-link" onClick={handleGoBack}> {t('label_horo')}</span> -
                {/* </span> */}
                &nbsp;<span className="bc-link">{t("label_daily_panchamgam_det")}</span>
            </div>
            <div className="bg-details-highlight">
                <div className="details-headertxt container">
                    <div className="header_txt">
                        {/* <LeftOutlined onClick={handlePrevious} /> */}

                        {dataToDisplay && dataToDisplay.title}
                        {/* <RightOutlined onClick={handleNext} /> */}
                    </div>
                    <div className="button-row">

                        <button style={{ fontWeight: "bold" }} className="button_move" onClick={handlePrevious}>{t('label_previous')}</button>

                        <input
                            type="date"
                            value={selectedDate}
                            onChange={onChange}
                            className="date-input"
                        />

                        <button style={{ fontWeight: "bold" }} className="button_move" onClick={handleNext}>{t('label_next')}</button>
                        {(userid === "AD" || userid === "AS") && editable && (
                            <a href="#" className="button_move"
                                onClick={handleSaveClick}
                                style={{ marginLeft: "5px", marginRight: '5px', fontWeight: '200px' }}
                            >
                                {t("label_save")}
                            </a>
                        )}
                        {(userid === "AD" || userid === "AS") ? (
                            <a href="#" className="button_move"
                                onClick={editable ? handleCancelClick : handleEditClick} style={{ marginLeft: "5px" }}> {buttonText} </a>
                        ) : ('')}
                        <a
                            style={{ marginTop: "3px" }}
                            type="link"
                            onClick={() => {
                                if (navigator.share) {
                                    navigator
                                        .share({
                                            title: dataToDisplay && dataToDisplay.title,
                                            text: "Check out this horoscope!",
                                            url: `https://www.templeswiki.com/horoscopeDetails/${hid}`
                                        })
                                        .then(() => console.warn("Share successful."))
                                        .catch((error) => console.error("Error sharing:", error));
                                } else {
                                    console.error("Web Share API not supported.");
                                }
                            }}
                        >
                            <i title="Share" className="fa-solid fa-share-nodes"></i>
                        </a>
                    </div>
                    {/* {(userid === "AD" || userid === "AS") && (
                        <div className="container" style={{ display: 'flex', justifyContent: 'end' }}>
                             <Link to='/editHoroscope/0' className="btn btn-warning shadow-0 yellowbtn">{t("label_create_daily_horo")}</Link>
                        </div>
                    )} */}
                </div>
            </div>
            <div className="mainsec-block-addtemple-detailspage">
                <div className='container'>
                    {hLoading || horLoading ? (
                        <p>Loading...</p>
                    ) : (
                        dataToDisplay ? (
                            <div className='horoscope-item'>
                                <div className="float-left w-100 mt-4"><h5 className="card-text-title">{t("label_details")}
                                    {/* <span>| {t('label_horo_det_tit')}</span> */}
                                </h5></div>
                                <Form form={form}>
                                    <div className="active-details position-relative">
                                        <div className="calendar-wrapper">
                                            <Calendar fullscreen={false} onPanelChange={onPanelChange} value={selectedDateFull}
                                                onChange={onCalenderChange} />
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_year_info")}</Typography.Title>} name="year_info">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.year_info} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.year_info}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_maasam")}</Typography.Title>} name="maasam">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.maasam} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.maasam}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_vaaram")}</Typography.Title>} name="vaaram">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.vaaram} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.vaaram}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_ruthuvu")}</Typography.Title>} name="ruthuvu">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.ruthuvu} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.ruthuvu}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_nak")}</Typography.Title>} name="nakshatra">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.nakshatra} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.nakshatra}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_tith")}</Typography.Title>} name="tithi">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.tithi} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.tithi}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_rahu_time")}</Typography.Title>} name="rh_time">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.rh_time} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.rh_time}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_yam")}</Typography.Title>} name="yg_time">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.yg_time} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.yg_time}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_varjyam")}</Typography.Title>} name="varjyam">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.varjyam} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.varjyam}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_amruthkal")}</Typography.Title>} name="amruthkal">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.amruthkal} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.amruthkal}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_durmuhurtham")}</Typography.Title>} name="durmuhurth">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.durmuhurth} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.durmuhurth}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_shubhkal")}</Typography.Title>} name="shubhakal">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.shubhakal} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.shubhakal}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_suryarasi")}</Typography.Title>} name="suryarasi">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.suryarasi} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.suryarasi}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_chandrarasi")}</Typography.Title>} name="chandrarasi">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.chandrarasi} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.chandrarasi}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_sunrise")}</Typography.Title>} name="sunrise">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.sunrise} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.sunrise}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_sunset")}</Typography.Title>} name="sunset">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.sunset} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.sunset}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_yoga")}</Typography.Title>} name="yoga">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.yoga} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.yoga}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_karanam")}</Typography.Title>} name="karanam">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.karanam} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.karanam}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_author")}</Typography.Title>} name="author">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.author} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.author}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_date")}</Typography.Title>} name="date">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.publish_date} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.publish_date}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_details")}</Typography.Title>} name="txt">
                                                {editable ? (
                                                    <TextArea defaultValue={dataToDisplay.txt} onChange={(e) => setTxt(e.target.value)} />
                                                ) : (
                                                    <Typography.Text
                                                        style={{ whiteSpace: "pre-wrap" }}
                                                        level={5}
                                                        className='typ'
                                                    >
                                                        <ShowMoreText charLimit={20} text={dataToDisplay && dataToDisplay.txt ? dataToDisplay.txt.split("<CM>").join("\n") : ""} />
                                                    </Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        {editable && (
                                            <div className="tiles-designs">
                                            </div>
                                        )}
                                    </div>
                                </Form>
                            </div>
                        ) : (
                            <Result status="404" title="No Data Found" subTitle="Sorry, Horoscope details not found." />
                        )
                    )}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default HoroscopeDetails;
