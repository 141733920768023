import axios from 'axios'
import {
  CANCEL_SERVICE_FAILURE,
  CANCEL_SERVICE_REQUEST,
  CANCEL_SERVICE_SUCESS,
  DELETE_DONATION_LIST_FAILURE,
  DELETE_DONATION_LIST_REQUEST,
  DELETE_DONATION_LIST_SUCESS,
  DELETE_EXPENSES_LIST_FAILURE,
  DELETE_EXPENSES_LIST_REQUEST,
  DELETE_EXPENSES_LIST_SUCESS,
  DELETE_FAVOURITES_LIST_FAILURE,
  DELETE_FAVOURITES_LIST_REQUEST,
  DELETE_FAVOURITES_LIST_SUCESS,
  DELETE_FAVOURITES_NOTES_LIST_FAILURE,
  DELETE_FAVOURITES_NOTES_LIST_REQUEST,
  DELETE_FAVOURITES_NOTES_LIST_SUCESS,
  DELETE_IMAGE_LIST_FAILURE,
  DELETE_IMAGE_LIST_REQUEST,
  DELETE_IMAGE_LIST_SUCESS,
  DELETE_NOTIFICATION_LIST_FAILURE,
  DELETE_NOTIFICATION_LIST_REQUEST,
  DELETE_NOTIFICATION_LIST_SUCESS,
  DELETE_RESOURCE_LIST_FAILURE,
  DELETE_RESOURCE_LIST_REQUEST,
  DELETE_RESOURCE_LIST_SUCESS,
  DELETE_TEMPLE_FAILURE,
  DELETE_TEMPLE_REQUEST,
  DELETE_TEMPLE_SUCESS,
  DELETE_TEMPLE_USER_FAILURE,
  DELETE_TEMPLE_USER_REQUEST,
  DELETE_TEMPLE_USER_SUCESS,
  EDIT_AVATAR_LIST_FAILURE,
  EDIT_AVATAR_LIST_REQUEST,
  EDIT_AVATAR_LIST_SUCESS,
  EDIT_CITY_LIST_FAILURE,
  EDIT_CITY_LIST_REQUEST,
  EDIT_CITY_LIST_SUCESS,
  EDIT_GENDER_LIST_FAILURE,
  EDIT_GENDER_LIST_REQUEST,
  EDIT_GENDER_LIST_SUCESS,
  EDIT_LANG_LIST_FAILURE,
  EDIT_LANG_LIST_REQUEST,
  EDIT_LANG_LIST_SUCESS,
  EDIT_NAME_LIST_FAILURE,
  EDIT_NAME_LIST_REQUEST,
  EDIT_NAME_LIST_SUCESS,
  EDIT_RECENTLY_VIEWED_FAILURE,
  EDIT_RECENTLY_VIEWED_REQUEST,
  EDIT_RECENTLY_VIEWED_SUCESS,
  EDIT_RESOURCE_LIST_FAILURE,
  EDIT_RESOURCE_LIST_REQUEST,
  EDIT_RESOURCE_LIST_SUCESS,
  EDIT_TEMPLES_LIST_FAILURE,
  EDIT_TEMPLES_LIST_REQUEST,
  EDIT_TEMPLES_LIST_SUCESS,
  EDIT_TEMPLE_ADDINFO_FAILURE,
  EDIT_TEMPLE_ADDINFO_REQUEST,
  EDIT_TEMPLE_ADDINFO_SUCESS,
  EDIT_TEMPLE_ADDRESS_FAILURE,
  EDIT_TEMPLE_ADDRESS_REQUEST,
  EDIT_TEMPLE_ADDRESS_SUCESS,
  EDIT_TEMPLE_ARCHITECTURE_FAILURE,
  EDIT_TEMPLE_ARCHITECTURE_REQUEST,
  EDIT_TEMPLE_ARCHITECTURE_SUCESS,
  EDIT_TEMPLE_DESCR_FAILURE,
  EDIT_TEMPLE_DESCR_REQUEST,
  EDIT_TEMPLE_DESCR_SUCESS,
  EDIT_TEMPLE_DETAILS_FAILURE,
  EDIT_TEMPLE_DETAILS_REQUEST,
  EDIT_TEMPLE_DETAILS_SUCESS,
  EDIT_TEMPLE_FIELDS_FAILURE,
  EDIT_TEMPLE_FIELDS_REQUEST,
  EDIT_TEMPLE_FIELDS_SUCESS,
  EDIT_TEMPLE_HIS_FAILURE,
  EDIT_TEMPLE_HIS_REQUEST,
  EDIT_TEMPLE_HIS_SUCESS,
  EDIT_TEMPLE_IMAGES_STATUS_LIST_FAILURE,
  EDIT_TEMPLE_IMAGES_STATUS_LIST_REQUEST,
  EDIT_TEMPLE_IMAGES_STATUS_LIST_SUCESS,
  EDIT_TEMPLE_LOCATION_FAILURE,
  EDIT_TEMPLE_LOCATION_REQUEST,
  EDIT_TEMPLE_LOCATION_SUCESS,
  EDIT_TEMPLE_STATUS_LIST_FAILURE,
  EDIT_TEMPLE_STATUS_LIST_REQUEST,
  EDIT_TEMPLE_STATUS_LIST_SUCESS,
  FETCH_ANONOMOUS_USER_FAILURE,
  FETCH_ANONOMOUS_USER_REQUEST,
  FETCH_ANONOMOUS_USER_SUCESS,
  FETCH_APP_USERS_FAILURE,
  FETCH_APP_USERS_REQUEST,
  FETCH_APP_USERS_SUCCESS,
  FETCH_BOOKS_BY_TAG_FAILURE,
  FETCH_BOOKS_BY_TAG_REQUEST,
  FETCH_BOOKS_BY_TAG_SUCESS,
  FETCH_BOOKS_FAILURE,
  FETCH_BOOKS_FAVOURITES_LIST_FAILURE,
  FETCH_BOOKS_FAVOURITES_LIST_REQUEST,
  FETCH_BOOKS_FAVOURITES_LIST_SUCESS,
  FETCH_BOOKS_REQUEST,
  FETCH_BOOKS_SUCESS,
  FETCH_BY_ST_DT_FAILURE,
  FETCH_BY_ST_DT_REQUEST,
  FETCH_BY_ST_DT_SUCESS,
  FETCH_BY_ST_DT_TXT_FAILURE,
  FETCH_BY_ST_DT_TXT_REQUEST,
  FETCH_BY_ST_DT_TXT_SUCESS,
  FETCH_CITY_ST_DESCR_LIST_FAILURE,
  FETCH_CITY_ST_DESCR_LIST_REQUEST,
  FETCH_CITY_ST_DESCR_LIST_SUCESS,
  FETCH_CITY_TEMPLES_LIST_FAILURE,
  FETCH_CITY_TEMPLES_LIST_REQUEST,
  FETCH_CITY_TEMPLES_LIST_SUCESS,
  FETCH_CONTRIBUTIONS_LIST_FAILURE,
  FETCH_CONTRIBUTIONS_LIST_REQUEST,
  FETCH_CONTRIBUTIONS_LIST_SUCESS,
  FETCH_DAILY_HOROSCOPE_DETAILS_FAILURE,
  FETCH_DAILY_HOROSCOPE_DETAILS_REQUEST,
  FETCH_DAILY_HOROSCOPE_DETAILS_SUCESS,
  FETCH_DAILY_HOROSCOPE_FAILURE,
  FETCH_DAILY_HOROSCOPE_LIST_FAILURE,
  FETCH_DAILY_HOROSCOPE_LIST_REQUEST,
  FETCH_DAILY_HOROSCOPE_LIST_SUCESS,
  FETCH_DAILY_HOROSCOPE_REQUEST,
  FETCH_DAILY_HOROSCOPE_SUCESS,
  FETCH_DAILY_HORO_DETAILS_BY_DATE_FAILURE,
  FETCH_DAILY_HORO_DETAILS_BY_DATE_REQUEST,
  FETCH_DAILY_HORO_DETAILS_BY_DATE_SUCESS,
  FETCH_DONATIONS_BY_MONTH_FAILURE,
  FETCH_DONATIONS_BY_MONTH_REQUEST,
  FETCH_DONATIONS_BY_MONTH_SUCESS,
  FETCH_DONATIONS_DETAILS_FAILURE,
  FETCH_DONATIONS_DETAILS_REQUEST,
  FETCH_DONATIONS_DETAILS_SUCESS,
  FETCH_DONATIONS_LIST_FAILURE,
  FETCH_DONATIONS_LIST_REQUEST,
  FETCH_DONATIONS_LIST_SUCESS,
  FETCH_EXPENSES_DETAILS_FAILURE,
  FETCH_EXPENSES_DETAILS_REQUEST,
  FETCH_EXPENSES_DETAILS_SUCESS,
  FETCH_EXPENSES_LIST_FAILURE,
  FETCH_EXPENSES_LIST_REQUEST,
  FETCH_EXPENSES_LIST_SUCESS,
  FETCH_FAVOURITES_LIST_FAILURE,
  FETCH_FAVOURITES_LIST_REQUEST,
  FETCH_FAVOURITES_LIST_SUCESS,
  FETCH_TEMPLESERVICEREG_LIST_FAILURE,
  FETCH_TEMPLESERVICEREG_LIST_REQUEST,
  FETCH_TEMPLESERVICEREG_LIST_SUCESS,
  FETCH_FILTER_BY_GOD_FAILURE,
  FETCH_FILTER_BY_GOD_REQUEST,
  FETCH_FILTER_BY_GOD_SUCESS,
  FETCH_FILTER_BY_ST_DT_FAILURE,
  FETCH_FILTER_BY_ST_DT_REQUEST,
  FETCH_FILTER_BY_ST_DT_SUCESS,
  FETCH_FILTER_NEAREST_FAILURE,
  FETCH_FILTER_NEAREST_REQUEST,
  FETCH_FILTER_NEAREST_SUCESS,
  FETCH_GOD_NAMES_LIST_FAILURE,
  FETCH_GOD_NAMES_LIST_REQUEST,
  FETCH_GOD_NAMES_LIST_SUCESS,
  FETCH_GROUP_DESCRIPTION_FAILURE,
  FETCH_GROUP_DESCRIPTION_REQUEST,
  FETCH_GROUP_DESCRIPTION_SUCESS,
  FETCH_LIVETV_FAILURE,
  FETCH_LIVETV_REQUEST,
  FETCH_LIVETV_SUCESS,
  FETCH_MY_FAILURE,
  FETCH_MY_REQUEST,
  FETCH_MY_SERVICES_LIST_FAILURE,
  FETCH_MY_SERVICES_LIST_REQUEST,
  FETCH_MY_SERVICES_LIST_SUCESS,
  FETCH_MY_SUCESS,
  FETCH_NEAREST_FAILURE,
  FETCH_NEAREST_REQUEST,
  FETCH_NEAREST_SUCESS,
  FETCH_NOTIFICATIONS_DETAILS_FAILURE,
  FETCH_NOTIFICATIONS_DETAILS_REQUEST,
  FETCH_NOTIFICATIONS_DETAILS_SUCESS,
  FETCH_NOTIFICATIONS_LIST_FAILURE,
  FETCH_NOTIFICATIONS_LIST_REQUEST,
  FETCH_NOTIFICATIONS_LIST_SUCESS,
  FETCH_PENDING_TEMPLES_LIST_FAILURE,
  FETCH_PENDING_TEMPLES_LIST_REQUEST,
  FETCH_PENDING_TEMPLES_LIST_SUCESS,
  FETCH_PRIEST_BY_SPCLTY_FAILURE,
  FETCH_PRIEST_BY_SPCLTY_REQUEST,
  FETCH_PRIEST_BY_SPCLTY_SUCESS,
  FETCH_PRIEST_DETAILS_FAILURE,
  FETCH_PRIEST_DETAILS_REQUEST,
  FETCH_PRIEST_DETAILS_SUCESS,
  FETCH_PRIEST_REQUEST,
  FETCH_PRIEST_REQUEST_FAILURE,
  FETCH_PRIEST_REQUEST_SUCESS,
  FETCH_PRIEST_REQ_DETAILS_FAILURE,
  FETCH_PRIEST_REQ_DETAILS_REQUEST,
  FETCH_PRIEST_REQ_DETAILS_SUCESS,
  FETCH_RECENTLY_VIEWED_FAILURE,
  FETCH_RECENTLY_VIEWED_REQUEST,
  FETCH_RECENTLY_VIEWED_SUCESS,
  FETCH_REFRESH_TOKEN_FAILURE,
  FETCH_REFRESH_TOKEN_REQUEST,
  FETCH_REFRESH_TOKEN_SUCESS,
  FETCH_REGIONAL_NOTIFI_FAILURE,
  FETCH_REGIONAL_NOTIFI_REQUEST,
  FETCH_REGIONAL_NOTIFI_SUCESS,
  FETCH_RESOURCE_BY_TXT_FAILURE,
  FETCH_RESOURCE_BY_TXT_REQUEST,
  FETCH_RESOURCE_BY_TXT_SUCESS,
  FETCH_RESOURCE_FAILURE,
  FETCH_RESOURCE_REQUEST,
  FETCH_RESOURCE_SUCESS,
  FETCH_SERVICES_BY_ADMIN_FAILURE,
  FETCH_SERVICES_BY_ADMIN_REQUEST,
  FETCH_SERVICES_BY_ADMIN_SUCESS,
  FETCH_SERVICES_BY_USER_FAILURE,
  FETCH_SERVICES_BY_USER_REQUEST,
  FETCH_SERVICES_BY_USER_SUCESS,
  FETCH_SERVICES_DETAILS_FAILURE,
  FETCH_SERVICES_DETAILS_REQUEST,
  FETCH_SERVICES_DETAILS_SUCESS,
  FETCH_SERVICE_REGISTRATION_DETAILS_FAILURE,
  FETCH_SERVICE_REGISTRATION_DETAILS_REQUEST,
  FETCH_SERVICE_REGISTRATION_DETAILS_SUCESS,
  FETCH_STATE_FAILURE,
  FETCH_STATE_REQUEST,
  FETCH_STATE_SUCESS,
  FETCH_TEMPLES_BY_GP_FAILURE,
  FETCH_TEMPLES_BY_GP_REQUEST,
  FETCH_TEMPLES_BY_GP_SUCESS,
  FETCH_TEMPLES_BY_TXT_FAILURE,
  FETCH_TEMPLES_BY_TXT_REQUEST,
  FETCH_TEMPLES_BY_TXT_SUCESS,
  FETCH_TEMPLES_LIST_FAILURE,
  FETCH_TEMPLES_LIST_REQUEST,
  FETCH_TEMPLES_LIST_SUCCESS,
  FETCH_TEMPLES_OUTSIDE_IND_LIST_FAILURE,
  FETCH_TEMPLES_OUTSIDE_IND_LIST_REQUEST,
  FETCH_TEMPLES_OUTSIDE_IND_LIST_SUCESS,
  FETCH_TEMPLE_AUDIO_FAILURE,
  FETCH_TEMPLE_AUDIO_REQUEST,
  FETCH_TEMPLE_AUDIO_SUCESS,
  FETCH_TEMPLE_BOOKS_FAILURE,
  FETCH_TEMPLE_BOOKS_REQUEST,
  FETCH_TEMPLE_BOOKS_SUCESS,
  FETCH_TEMPLE_DASHBOARD_LIST_FAILURE,
  FETCH_TEMPLE_DASHBOARD_LIST_REQUEST,
  FETCH_TEMPLE_DASHBOARD_LIST_SUCESS,
  FETCH_TEMPLE_FAVOURITES_LIST_FAILURE,
  FETCH_TEMPLE_FAVOURITES_LIST_REQUEST,
  FETCH_TEMPLE_FAVOURITES_LIST_SUCESS,
  FETCH_TEMPLE_GP_FAILURE,
  FETCH_TEMPLE_GP_REQUEST,
  FETCH_TEMPLE_GP_SUCESS,
  FETCH_TEMPLE_MEMBERS_LIST_FAILURE,
  FETCH_TEMPLE_MEMBERS_LIST_REQUEST,
  FETCH_TEMPLE_MEMBERS_LIST_SUCESS,
  FETCH_TEMPLE_NOTIFI_FAILURE,
  FETCH_TEMPLE_NOTIFI_REQUEST,
  FETCH_TEMPLE_NOTIFI_SUCESS,
  FETCH_TEMPLE_SERVICE_REGISTRATION_FAILURE,
  FETCH_TEMPLE_SERVICE_REGISTRATION_REQUEST,
  FETCH_TEMPLE_SERVICE_REGISTRATION_SUCESS,
  FETCH_TEMPLE_USERS_BY_ST_FAILURE,
  FETCH_TEMPLE_USERS_BY_ST_REQUEST,
  FETCH_TEMPLE_USERS_BY_ST_SUCESS,
  FETCH_TEMPLE_VIDEO_FAILURE,
  FETCH_TEMPLE_VIDEO_REQUEST,
  FETCH_TEMPLE_VIDEO_SUCESS,
  FETCH_USER_LOGIN_FAILURE,
  FETCH_USER_LOGIN_REQUEST,
  FETCH_USER_LOGIN_SUCESS,
  FETCH_USER_SERVICE_REGISTRATION_FAILURE,
  FETCH_USER_SERVICE_REGISTRATION_REQUEST,
  FETCH_USER_SERVICE_REGISTRATION_SUCESS,
  FETCH_VIDEO_LINKS_FAILURE,
  FETCH_VIDEO_LINKS_REQUEST,
  FETCH_VIDEO_LINKS_SUCESS,
  FETCH_WEEKLY_HOROSCOPE_DETAILS_FAILURE,
  FETCH_WEEKLY_HOROSCOPE_DETAILS_REQUEST,
  FETCH_WEEKLY_HOROSCOPE_DETAILS_SUCESS,
  FETCH_WEEKLY_HOROSCOPE_FAILURE,
  FETCH_WEEKLY_HOROSCOPE_LIST_FAILURE,
  FETCH_WEEKLY_HOROSCOPE_LIST_REQUEST,
  FETCH_WEEKLY_HOROSCOPE_LIST_SUCESS,
  FETCH_WEEKLY_HOROSCOPE_REQUEST,
  FETCH_WEEKLY_HOROSCOPE_SUCESS,
  GET_LATEST_TEMPLE_DET_FAILURE,
  GET_LATEST_TEMPLE_DET_REQUEST,
  GET_LATEST_TEMPLE_DET_SUCCESS,
  GET_RESOURCE_DET_FAILURE,
  GET_RESOURCE_DET_REQUEST,
  GET_RESOURCE_DET_SUCCESS,
  GET_TEMPLE_DET_FAILURE,
  GET_TEMPLE_DET_IN_OTHER_LANG_FAILURE,
  GET_TEMPLE_DET_IN_OTHER_LANG_REQUEST,
  GET_TEMPLE_DET_IN_OTHER_LANG_SUCCESS,
  GET_TEMPLE_DET_REQUEST,
  GET_TEMPLE_DET_SUCCESS,
  GET_TEMPLE_GRP_DETAILS_FAILURE,
  GET_TEMPLE_GRP_DETAILS_REQUEST,
  GET_TEMPLE_GRP_DETAILS_SUCCESS,
  POST_ADD_TEMPLE_ADMIN_LIST_FAILURE,
  POST_ADD_TEMPLE_ADMIN_LIST_REQUEST,
  POST_ADD_TEMPLE_ADMIN_LIST_SUCESS,
  POST_ADD_USER_TO_TEMPLE_LIST_FAILURE,
  POST_ADD_USER_TO_TEMPLE_LIST_REQUEST,
  POST_ADD_USER_TO_TEMPLE_LIST_SUCESS,
  POST_BOOKS_FAVOURITES_LIST_FAILURE,
  POST_BOOKS_FAVOURITES_LIST_REQUEST,
  POST_BOOKS_FAVOURITES_LIST_SUCESS,
  POST_CHANGE_PASSWORD_FAILURE,
  POST_CHANGE_PASSWORD_REQUEST,
  POST_CHANGE_PASSWORD_SUCESS,
  POST_CLEARALL_NOTIFI_LIST_FAILURE,
  POST_CLEARALL_NOTIFI_LIST_REQUEST,
  POST_CLEARALL_NOTIFI_LIST_SUCESS,
  POST_DONATIONS_LIST_FAILURE,
  POST_DONATIONS_LIST_REQUEST,
  POST_DONATIONS_LIST_SUCESS,
  POST_EXPENSES_LIST_FAILURE,
  POST_EXPENSES_LIST_REQUEST,
  POST_EXPENSES_LIST_SUCESS,
  POST_FAVOURITES_LIST_FAILURE,
  POST_FAVOURITES_LIST_REQUEST,
  POST_FAVOURITES_LIST_SUCESS,
  POST_HOROSCOPE_LIST_FAILURE,
  POST_HOROSCOPE_LIST_REQUEST,
  POST_HOROSCOPE_LIST_SUCESS,
  POST_IMAGE_FILE_FAILURE,
  POST_IMAGE_FILE_REQUEST,
  POST_IMAGE_FILE_SUCESS,
  POST_LIBRARY_UPLOAD_IMAGE_FAILURE,
  POST_LIBRARY_UPLOAD_IMAGE_REQUEST,
  POST_LIBRARY_UPLOAD_IMAGE_SUCESS,
  POST_NOTIFICATIONS_LIST_FAILURE,
  POST_NOTIFICATIONS_LIST_REQUEST,
  POST_NOTIFICATIONS_LIST_SUCESS,
  POST_PASSWORD_RESET_FAILURE,
  POST_PASSWORD_RESET_REQUEST,
  POST_PASSWORD_RESET_SUCESS,
  POST_PRIEST_LIST_FAILURE,
  POST_PRIEST_LIST_REQUEST,
  POST_PRIEST_LIST_SUCESS,
  POST_PRIEST_REQ_FAILURE,
  POST_PRIEST_REQ_REQUEST,
  POST_PRIEST_REQ_SUCESS,
  POST_REGISTER_USER_FAILURE,
  POST_REGISTER_USER_REQUEST,
  POST_REGISTER_USER_SUCESS,
  POST_REQUEST_PASSWORD_FAILURE,
  POST_REQUEST_PASSWORD_REQUEST,
  POST_REQUEST_PASSWORD_SUCESS,
  POST_RESOURCE_LIST_FAILURE,
  POST_RESOURCE_LIST_REQUEST,
  POST_RESOURCE_LIST_SUCESS,
  POST_SERVICES_REGISTER_BY_AD_FAILURE,
  POST_SERVICES_REGISTER_BY_AD_REQUEST,
  POST_SERVICES_REGISTER_BY_AD_SUCESS,
  POST_SET_NEW_PASSWORD_FAILURE,
  POST_SET_NEW_PASSWORD_REQUEST,
  POST_SET_NEW_PASSWORD_SUCESS,
  POST_TEMPLES_LIST_FAILURE,
  POST_TEMPLES_LIST_REQUEST,
  POST_TEMPLES_LIST_SUCESS,
  POST_TEMPLE_GP_LIST_FAILURE,
  POST_TEMPLE_GP_LIST_REQUEST,
  POST_TEMPLE_GP_LIST_SUCESS,
  POST_TEMPLE_TO_GP_LIST_FAILURE,
  POST_TEMPLE_TO_GP_LIST_REQUEST,
  POST_TEMPLE_TO_GP_LIST_SUCESS,
  POST_TRANSLATION_LIST_FAILURE,
  POST_TRANSLATION_LIST_REQUEST,
  POST_TRANSLATION_LIST_SUCESS,
  POST_UPLOAD_IMAGE_FAILURE,
  POST_UPLOAD_IMAGE_REQUEST,
  POST_UPLOAD_IMAGE_SUCESS,
  POST_WEEKLY_HOROSCOPE_FAILURE,
  POST_WEEKLY_HOROSCOPE_REQUEST,
  POST_WEEKLY_HOROSCOPE_SUCESS,
  PUBLISH_RESOURCE_LIST_FAILURE,
  PUBLISH_RESOURCE_LIST_REQUEST,
  PUBLISH_RESOURCE_LIST_SUCESS,
  UPDATE_DONATIONS_AMOUNT_FAILURE,
  UPDATE_DONATIONS_AMOUNT_REQUEST,
  UPDATE_DONATIONS_AMOUNT_SUCESS,
  UPDATE_DONATIONS_BILL_FAILURE,
  UPDATE_DONATIONS_BILL_REQUEST,
  UPDATE_DONATIONS_BILL_SUCESS,
  UPDATE_DONATIONS_NOTES_FAILURE,
  UPDATE_DONATIONS_NOTES_REQUEST,
  UPDATE_DONATIONS_NOTES_SUCESS,
  UPDATE_DONATIONS_PAYMENT_FAILURE,
  UPDATE_DONATIONS_PAYMENT_REQUEST,
  UPDATE_DONATIONS_PAYMENT_SUCESS,
  UPDATE_DONATIONS_TYPE_FAILURE,
  UPDATE_DONATIONS_TYPE_REQUEST,
  UPDATE_DONATIONS_TYPE_SUCESS,
  UPDATE_DONATION_PAYMENT_DETAILS_FAILURE,
  UPDATE_DONATION_PAYMENT_DETAILS_REQUEST,
  UPDATE_DONATION_PAYMENT_DETAILS_SUCESS,
  UPDATE_DONOR_DETAILS_FAILURE,
  UPDATE_DONOR_DETAILS_REQUEST,
  UPDATE_DONOR_DETAILS_SUCESS,
  UPDATE_EXPENSES_AMOUNT_FAILURE,
  UPDATE_EXPENSES_AMOUNT_REQUEST,
  UPDATE_EXPENSES_AMOUNT_SUCESS,
  UPDATE_EXPENSES_BILL_FAILURE,
  UPDATE_EXPENSES_BILL_REQUEST,
  UPDATE_EXPENSES_BILL_SUCESS,
  UPDATE_EXPENSES_NOTES_FAILURE,
  UPDATE_EXPENSES_NOTES_REQUEST,
  UPDATE_EXPENSES_NOTES_SUCESS,
  UPDATE_EXPENSES_TYPE_FAILURE,
  UPDATE_EXPENSES_TYPE_REQUEST,
  UPDATE_EXPENSES_TYPE_SUCESS,
  UPDATE_GROUP_DESCR_FAILURE,
  UPDATE_GROUP_DESCR_REQUEST,
  UPDATE_GROUP_DESCR_SUCESS,
  UPDATE_GROUP_NAME_FAILURE,
  UPDATE_GROUP_NAME_REQUEST,
  UPDATE_GROUP_NAME_SUCESS,
  UPDATE_GROUP_RANK_FAILURE,
  UPDATE_GROUP_RANK_REQUEST,
  UPDATE_GROUP_RANK_SUCESS,
  UPDATE_HOROSCOPE_TEXT_FAILURE,
  UPDATE_HOROSCOPE_TEXT_REQUEST,
  UPDATE_HOROSCOPE_TEXT_SUCESS,
  UPDATE_HOROSCOPE_TITLE_FAILURE,
  UPDATE_HOROSCOPE_TITLE_REQUEST,
  UPDATE_HOROSCOPE_TITLE_SUCESS,
  UPDATE_HORO_DETAILS_FAILURE,
  UPDATE_HORO_DETAILS_REQUEST,
  UPDATE_HORO_DETAILS_SUCESS,
  UPDATE_HORO_LOC_FAILURE,
  UPDATE_HORO_LOC_REQUEST,
  UPDATE_HORO_LOC_SUCESS,
  UPDATE_IMAGE_LIST_FAILURE,
  UPDATE_IMAGE_LIST_REQUEST,
  UPDATE_IMAGE_LIST_SUCESS,
  UPDATE_NOTIFI_DETAILS_FAILURE,
  UPDATE_NOTIFI_DETAILS_REQUEST,
  UPDATE_NOTIFI_DETAILS_SUCESS,
  UPDATE_NOTIFI_DATE_FAILURE,
  UPDATE_NOTIFI_DATE_REQUEST,
  UPDATE_NOTIFI_DATE_SUCESS,
  UPDATE_PRIEST_DEGREE_FAILURE,
  UPDATE_PRIEST_DEGREE_REQUEST,
  UPDATE_PRIEST_DEGREE_SUCESS,
  UPDATE_PRIEST_DETAILS_FAILURE,
  UPDATE_PRIEST_DETAILS_REQUEST,
  UPDATE_PRIEST_DETAILS_SUCESS,
  UPDATE_PRIEST_QUERY_FAILURE,
  UPDATE_PRIEST_QUERY_REQUEST,
  UPDATE_PRIEST_QUERY_SUCESS,
  UPDATE_PRIEST_RECENT_FAILURE,
  UPDATE_PRIEST_RECENT_REQUEST,
  UPDATE_PRIEST_RECENT_SUCESS,
  UPDATE_PRIEST_REQ_STATUS,
  UPDATE_PRIEST_REQ_STATUS_FAILURE,
  UPDATE_PRIEST_REQ_STATUS_SUCESS,
  UPDATE_PRIEST_RESOLUTION_FAILURE,
  UPDATE_PRIEST_RESOLUTION_REQUEST,
  UPDATE_PRIEST_RESOLUTION_SUCESS,
  UPDATE_PRIEST_SPCLTY_FAILURE,
  UPDATE_PRIEST_SPCLTY_REQUEST,
  UPDATE_PRIEST_SPCLTY_SUCESS,
  UPDATE_SERVICE_AMOUNT_FAILURE,
  UPDATE_SERVICE_AMOUNT_REQUEST,
  UPDATE_SERVICE_AMOUNT_SUCESS,
  UPDATE_SERVICE_BOOKBEFORE,
  UPDATE_SERVICE_BOOKBEFORE_FAILURE,
  UPDATE_SERVICE_BOOKBEFORE_SUCESS,
  UPDATE_SERVICE_DATE_FAILURE,
  UPDATE_SERVICE_DATE_REQUEST,
  UPDATE_SERVICE_DATE_SUCESS,
  UPDATE_SERVICE_NOTES_FAILURE,
  UPDATE_SERVICE_NOTES_REQUEST,
  UPDATE_SERVICE_NOTES_SUCESS,
  UPDATE_SERVICE_TITLE_FAILURE,
  UPDATE_SERVICE_TITLE_REQUEST,
  UPDATE_SERVICE_TITLE_SUCESS,
  UPDATE_SERVICE_REGISTRATION_ADDR_FAILURE,
  UPDATE_SERVICE_REGISTRATION_ADDR_REQUEST,
  UPDATE_SERVICE_REGISTRATION_ADDR_SUCESS,
  UPDATE_SERVICE_REGISTRATION_AMOUNT_FAILURE,
  UPDATE_SERVICE_REGISTRATION_AMOUNT_REQUEST,
  UPDATE_SERVICE_REGISTRATION_AMOUNT_SUCESS,
  UPDATE_SERVICE_REGISTRATION_BILL_FAILURE,
  UPDATE_SERVICE_REGISTRATION_BILL_REQUEST,
  UPDATE_SERVICE_REGISTRATION_BILL_SUCESS,
  UPDATE_SERVICE_REGISTRATION_NAME_FAILURE,
  UPDATE_SERVICE_REGISTRATION_NAME_REQUEST,
  UPDATE_SERVICE_REGISTRATION_NAME_SUCESS,
  UPDATE_SERVICE_REGISTRATION_NOTES_FAILURE,
  UPDATE_SERVICE_REGISTRATION_NOTES_REQUEST,
  UPDATE_SERVICE_REGISTRATION_NOTES_SUCESS,
  UPDATE_SERVICE_REGISTRATION_PHONE_FAILURE,
  UPDATE_SERVICE_REGISTRATION_PHONE_REQUEST,
  UPDATE_SERVICE_REGISTRATION_PHONE_SUCESS,
  UPDATE_SERVICE_REGISTRATION_QTY_FAILURE,
  UPDATE_SERVICE_REGISTRATION_QTY_REQUEST,
  UPDATE_SERVICE_REGISTRATION_QTY_SUCESS,
  UPDATE_SERVICE_REGISTRATION_STATUS_FAILURE,
  UPDATE_SERVICE_REGISTRATION_STATUS_REQUEST,
  UPDATE_SERVICE_REGISTRATION_STATUS_SUCESS,
  UPDATE_SERVICE_STATUS_FAILURE,
  UPDATE_SERVICE_STATUS_REQUEST,
  UPDATE_SERVICE_STATUS_SUCESS,
  UPDATE_SERVICE_TYPE_FAILURE,
  UPDATE_SERVICE_TYPE_REQUEST,
  UPDATE_SERVICE_TYPE_SUCESS,
  UPDATE_USER_ROLE_FAILURE,
  UPDATE_USER_ROLE_REQUEST,
  UPDATE_USER_ROLE_SUCESS,
  UPDATE_WHORO_DETAILS_FAILURE,
  UPDATE_WHORO_DETAILS_REQUEST,
  UPDATE_WHORO_DETAILS_SUCESS,
  POST_RESOURCE_TXT_REQUEST,
  POST_RESOURCE_TXT_SUCESS,
  POST_RESOURCE_TXT_FAILURE,
  POST_HORO_TXT_REQUEST,
  POST_HORO_TXT_SUCESS,
  POST_HORO_TXT_FAILURE,
  FETCH_RESOURCE_FAVOURITES_LIST_REQUEST,
  FETCH_RESOURCE_FAVOURITES_LIST_SUCESS,
  FETCH_RESOURCE_FAVOURITES_LIST_FAILURE,
  UPDATE_SERVICE_CAPACITY_FAILURE,
  UPDATE_SERVICE_CAPACITY_REQUEST,
  UPDATE_SERVICE_CAPACITY_SUCESS,
  FETCH_USER_ACTIVITY_REQUEST,
  FETCH_USER_ACTIVITY_SUCESS,
  FETCH_USER_ACTIVITY_FAILURE,
  FETCH_RES_BOOKS_REQUEST,
  FETCH_RES_BOOKS_SUCCESS,
  FETCH_RES_BOOKS_FAILURE,
  FETCH_ARTICLES_REQUEST,
  FETCH_ARTICLES_SUCCESS,
  FETCH_ARTICLES_FAILURE,
  FETCH_MANTRAS_REQUEST,
  FETCH_MANTRAS_SUCCESS,
  FETCH_MANTRAS_FAILURE,
  FETCH_AUDIOS_REQUEST,
  FETCH_AUDIOS_SUCCESS,
  FETCH_AUDIOS_FAILURE,
  FETCH_RES_LIVETV_REQUEST,
  FETCH_RES_LIVETV_SUCCESS,
  FETCH_RES_LIVETV_FAILURE,
  FETCH_VIDEOS_REQUEST,
  FETCH_VIDEOS_SUCCESS,
  FETCH_VIDEOS_FAILURE,
  FETCH_TEMPLES_MIN_REQUEST,
  FETCH_TEMPLES_MIN_SUCCESS,
  FETCH_TEMPLES_MIN_FAILURE,
  TRANS_RESOURCE_LANG_FAILURE,
  TRANS_RESOURCE_LANG_REQUEST,
  TRANS_RESOURCE_LANG_SUCESS,
  DELETE_NOTIFICATION_SUCESS,
  DELETE_NOTIFICATION_FAILURE,
  DELETE_NOTIFICATION_REQUEST,
}
  from '../conistants/conistants'
import TemplesDataService from "../../services/temples.service";
import UsersDataService from "../../services/users.service";
import adminService from '../../services/admin.service';
import priestService from '../../services/priest.service';
import usersService from '../../services/users.service';
import servicesService from '../../services/services.service';
import API_KEYS from '../../services/apiKeys';

var CryptoJS = require("crypto-js");
var crypto_key = CryptoJS.enc.Utf8.parse(API_KEYS.SECRET_CODE);

const decrypt_data = (encry_data) => {
  var cipherBytes = CryptoJS.enc.Base64.parse(encry_data);
  var bytes = CryptoJS.AES.decrypt({ ciphertext: cipherBytes }, crypto_key, { mode: CryptoJS.mode.ECB });
  var txt = bytes.toString(CryptoJS.enc.Utf8)
  // console.warn('API_Response1='+txt)
  txt = txt.replaceAll("': '", '": "').replaceAll("', '", '", "')
  txt = txt.replaceAll("{'", '{"').replaceAll("'}", '"}')
  txt = txt.replaceAll("': ", '": ').replaceAll(", '", ', "')
  txt = txt.replaceAll(': None', ': null').replaceAll(': True', ': true').replaceAll(': False', ': false')
  // console.warn('API_Response2='+txt)
  var decryptedData = JSON.parse(txt);
  return decryptedData;
}

export const translateResourcelang = (rid,{ slang, tlang }, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: TRANS_RESOURCE_LANG_REQUEST })

    TemplesDataService.translateResourcelang(rid, slang, tlang).then((response) => {
      dispatch({
        type: TRANS_RESOURCE_LANG_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      dispatch({
        type: TRANS_RESOURCE_LANG_FAILURE,
        payload: error.message,
      });
      failure(error);
    })
  }
}

export const fetchTemplesList = (failure) => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPLES_LIST_REQUEST })
    axios.get(`${process.env.REACT_APP_API_URL}temples`).then((response) => {
      dispatch({
        type: FETCH_TEMPLES_LIST_SUCCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_TEMPLES_LIST_FAILURE,
        payload: error.message,
      })
      failure(errorMsg);
    })
  }
}

export const fetchGodNames = (failure) => {
  return (dispatch) => {
    dispatch({ type: FETCH_GOD_NAMES_LIST_REQUEST })
    axios.get(`${process.env.REACT_APP_API_URL}GodNames`).then((response) => {
      dispatch({
        type: FETCH_GOD_NAMES_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_GOD_NAMES_LIST_FAILURE,
        payload: error.message,
      })
      failure(errorMsg);
    })
  }
}

export const fetchContributions = (lang, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_CONTRIBUTIONS_LIST_REQUEST })
    //axios.get(`${process.env.REACT_APP_API_URL}Contributions`).then((response) => {
    TemplesDataService.getMyContributions(lang, lr).then((response) => {
      dispatch({
        type: FETCH_CONTRIBUTIONS_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_CONTRIBUTIONS_LIST_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const fetchNotifications = (lang, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_NOTIFICATIONS_LIST_REQUEST })
    //axios.get(`${process.env.REACT_APP_API_URL}Notifications`).then((response) => {
    UsersDataService.getNotifications(lang, lr).then((response) => {
      dispatch({
        type: FETCH_NOTIFICATIONS_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_NOTIFICATIONS_LIST_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const fetchNotificationDetails = (nid, lang) => {
  return (dispatch) => {
    dispatch({ type: FETCH_NOTIFICATIONS_DETAILS_REQUEST })
    //axios.get(`${process.env.REACT_APP_API_URL}Notifications`).then((response) => {
    UsersDataService.getNotificationDetails(nid, lang).then((response) => {
      dispatch({
        type: FETCH_NOTIFICATIONS_DETAILS_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_NOTIFICATIONS_DETAILS_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const updateNotificationDetails = (lang, nid, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_NOTIFI_DETAILS_REQUEST })

    UsersDataService.updateNotificationDetails(lang, nid, data).then((response) => {
      dispatch({
        type: UPDATE_NOTIFI_DETAILS_SUCESS,
        payload: response.data,
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: UPDATE_NOTIFI_DETAILS_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const deleteNotification = (nid, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: DELETE_NOTIFICATION_REQUEST })

    UsersDataService.deleteAdminNotification(nid).then((response) => {
      dispatch({
        type: DELETE_NOTIFICATION_SUCESS,
        payload: response.data,
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: DELETE_NOTIFICATION_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const updateNotificationDate = ( nid, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_NOTIFI_DATE_REQUEST })

    UsersDataService.updateNotificationDate(nid, data).then((response) => {
      dispatch({
        type: UPDATE_NOTIFI_DATE_SUCESS,
        payload: response.data,
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: UPDATE_NOTIFI_DATE_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const fetchDonationsList = (lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_DONATIONS_LIST_REQUEST })
    //axios.get(`${process.env.REACT_APP_API_URL}Notifications`).then((response) => {
    adminService.getDonationsList(lr).then((response) => {
      dispatch({
        type: FETCH_DONATIONS_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_DONATIONS_LIST_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const fetchMyServicesList = (lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_MY_SERVICES_LIST_REQUEST })
    adminService.getMyServicesList(lr).then((response) => {
      dispatch({
        type: FETCH_MY_SERVICES_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_MY_SERVICES_LIST_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchDonationsByMonth = (year, month) => {
  return (dispatch) => {
    dispatch({ type: FETCH_DONATIONS_BY_MONTH_REQUEST })
    //axios.get(`${process.env.REACT_APP_API_URL}Notifications`).then((response) => {
    adminService.getDonationsByMonth(year, month).then((response) => {
      dispatch({
        type: FETCH_DONATIONS_BY_MONTH_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_DONATIONS_BY_MONTH_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const fetchDonationsDetails = (lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_DONATIONS_DETAILS_REQUEST })
    //axios.get(`${process.env.REACT_APP_API_URL}Notifications`).then((response) => {
    adminService.getDonationsDetails(lr).then((response) => {
      dispatch({
        type: FETCH_DONATIONS_DETAILS_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_DONATIONS_DETAILS_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const fetchExpensesList = (lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_EXPENSES_LIST_REQUEST })
    adminService.getExpensesList(lr).then((response) => {
      dispatch({
        type: FETCH_EXPENSES_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_EXPENSES_LIST_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const fetchServiceRegList = (lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPLESERVICEREG_LIST_REQUEST })
    adminService.getTempleServiceBookingList(lr).then((response) => {
      dispatch({
        type: FETCH_TEMPLESERVICEREG_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_TEMPLESERVICEREG_LIST_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const fetchExpensesDetails = (lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_EXPENSES_DETAILS_REQUEST })
    //axios.get(`${process.env.REACT_APP_API_URL}Notifications`).then((response) => {
    adminService.getExpensesDetails(lr).then((response) => {
      dispatch({
        type: FETCH_EXPENSES_DETAILS_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_EXPENSES_DETAILS_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const postUserActivity = (data) => {
  return (dispatch) => {
    dispatch({ type: FETCH_USER_ACTIVITY_REQUEST })
    //axios.get(`${process.env.REACT_APP_API_URL}Notifications`).then((response) => {
    adminService.postUserActivity(data).then((response) => {
      dispatch({
        type: FETCH_USER_ACTIVITY_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_USER_ACTIVITY_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const fetchTempleDashboard = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPLE_DASHBOARD_LIST_REQUEST })
    //axios.get(`${process.env.REACT_APP_API_URL}Notifications`).then((response) => {
    adminService.getTempleDashboard().then((response) => {
      dispatch({
        type: FETCH_TEMPLE_DASHBOARD_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
      localStorage.setItem('tname', response.data.tname)
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_TEMPLE_DASHBOARD_LIST_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const fetchTempleMembers = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPLE_MEMBERS_LIST_REQUEST })
    //axios.get(`${process.env.REACT_APP_API_URL}Notifications`).then((response) => {
    adminService.getTempleMenbers().then((response) => {
      dispatch({
        type: FETCH_TEMPLE_MEMBERS_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_TEMPLE_MEMBERS_LIST_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const fetchPendingTemples = (lang, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_PENDING_TEMPLES_LIST_REQUEST })
    //axios.get(`${process.env.REACT_APP_API_URL}Notifications`).then((response) => {
    adminService.getPendingTemples(lang, lr).then((response) => {
      dispatch({
        type: FETCH_PENDING_TEMPLES_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_PENDING_TEMPLES_LIST_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const fetchTempleUsersByStCity = (st, city, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPLE_USERS_BY_ST_REQUEST })
    //axios.get(`${process.env.REACT_APP_API_URL}Notifications`).then((response) => {
    adminService.getTempleUsersByStCity(st, city, lr).then((response) => {
      dispatch({
        type: FETCH_TEMPLE_USERS_BY_ST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_TEMPLE_USERS_BY_ST_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const postAddUserToTemple = (uid, data, sucess) => {
  return (dispatch) => {
    dispatch({ type: POST_ADD_USER_TO_TEMPLE_LIST_REQUEST })

    adminService.postAddUserToTemple(uid, data).then((response) => {
      dispatch({
        type: POST_ADD_USER_TO_TEMPLE_LIST_SUCESS,
        payload: response.data
      })
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_ADD_USER_TO_TEMPLE_LIST_FAILURE,
        payload: error.message,
      })
      //failure(error.message);
    })
  }
}

export const postAddTempleAdmin = (tid, uid, data, sucess) => {
  return (dispatch) => {
    dispatch({ type: POST_ADD_TEMPLE_ADMIN_LIST_REQUEST })

    adminService.postAddTempleAdmin(tid, uid, data).then((response) => {
      dispatch({
        type: POST_ADD_TEMPLE_ADMIN_LIST_SUCESS,
        payload: response.data
      })
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_ADD_TEMPLE_ADMIN_LIST_FAILURE,
        payload: error.message,
      })
      // failure(errorMsg);
    })
  }
}

export const deleteTempleUser = (uid) => {
  return (dispatch) => {
    dispatch({ type: DELETE_TEMPLE_USER_REQUEST })

    adminService.deleteTempleUser(uid).then((response) => {
      dispatch({
        type: DELETE_TEMPLE_USER_SUCESS,
        payload: response.data,
      });
      // sucess()
    }).catch((error) => {
      dispatch({
        type: DELETE_TEMPLE_USER_FAILURE,
        payload: error.message,
      });
      // failure(error.message)
    })
  }
}

export const postFavourites = (id, success, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_FAVOURITES_LIST_REQUEST })

    UsersDataService.postFavourites(id).then((response) => {
      dispatch({
        type: POST_FAVOURITES_LIST_SUCESS,
        payload: response.data
      })
      success()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_FAVOURITES_LIST_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const postClearALLNotifi = (success) => {
  return (dispatch) => {
    dispatch({ type: POST_CLEARALL_NOTIFI_LIST_REQUEST })

    UsersDataService.postClearALLNotifi().then((response) => {
      dispatch({
        type: POST_CLEARALL_NOTIFI_LIST_SUCESS,
        payload: response.data
      })
      success()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_CLEARALL_NOTIFI_LIST_FAILURE,
        payload: error.message,
      })
      // failure(errorMsg);
    })
  }
}

export const postBooksFavourites = (text, rid, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_BOOKS_FAVOURITES_LIST_REQUEST })

    UsersDataService.postBooksFavourites(text, rid).then((response) => {
      dispatch({
        type: POST_BOOKS_FAVOURITES_LIST_SUCESS,
        payload: response.data
      })
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_BOOKS_FAVOURITES_LIST_FAILURE,
        payload: error.message,
      })
      failure(errorMsg);
    })
  }
}

export const removeFavourites = (id, failure) => {
  return (dispatch) => {
    dispatch({ type: DELETE_FAVOURITES_LIST_REQUEST })

    UsersDataService.deleteFavourites(id).then((response) => {
      dispatch({
        type: DELETE_FAVOURITES_LIST_SUCESS,
        payload: response.data
      })
    }).catch((error) => {
      dispatch({
        type: DELETE_FAVOURITES_LIST_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const deleteTemple = (id, tsm, success, failure) => {
  return (dispatch) => {
    dispatch({ type: DELETE_TEMPLE_REQUEST })

    TemplesDataService.deleteTemple(id, tsm).then((response) => {
      dispatch({
        type: DELETE_TEMPLE_SUCESS,
        payload: response.data
      })
      success()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: DELETE_TEMPLE_FAILURE,
        payload: error.message,
      })
      failure(errorMsg);
    })
  }
}

export const removeBooksFavourites = (text, rid, failure) => {
  return (dispatch) => {
    dispatch({ type: DELETE_FAVOURITES_NOTES_LIST_REQUEST })

    UsersDataService.deleteBooksFavourites(text, rid).then((response) => {
      dispatch({
        type: DELETE_FAVOURITES_NOTES_LIST_SUCESS,
        payload: response.data
      })
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: DELETE_FAVOURITES_NOTES_LIST_FAILURE,
        payload: error.message,
      })
      failure(errorMsg);
    })
  }
}

export const removeNotification = (data, success, failure) => {
  return (dispatch) => {
    dispatch({ type: DELETE_NOTIFICATION_LIST_REQUEST })

    UsersDataService.deleteNotification(data).then((response) => {
      dispatch({
        type: DELETE_NOTIFICATION_LIST_SUCESS,
        payload: response.data
      })
      success();
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: DELETE_NOTIFICATION_LIST_FAILURE,
        payload: error.message,
      })
      failure(errorMsg);
    })
  }
}


export const removeResource = (rid) => {
  return (dispatch) => {
    dispatch({ type: DELETE_RESOURCE_LIST_REQUEST })

    TemplesDataService.deleteResource(rid).then((response) => {
      dispatch({
        type: DELETE_RESOURCE_LIST_SUCESS,
        payload: response.data
      })
    }).catch((error) => {
      dispatch({
        type: DELETE_RESOURCE_LIST_FAILURE,
        payload: error.message,
      })
      //failure(error.message);
    })
  }
}

export const publishResource = (rid) => {
  return (dispatch) => {
    dispatch({ type: PUBLISH_RESOURCE_LIST_REQUEST })

    TemplesDataService.publishResource(rid).then((response) => {
      dispatch({
        type: PUBLISH_RESOURCE_LIST_SUCESS,
        payload: response.data
      })
    }).catch((error) => {
      dispatch({
        type: PUBLISH_RESOURCE_LIST_FAILURE,
        payload: error.message,
      })
      //failure(error.message);
    })
  }
}

export const fetchFavourites = (lang, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_FAVOURITES_LIST_REQUEST })

    UsersDataService.getFavourites(lang, lr).then((response) => {
      dispatch({
        type: FETCH_FAVOURITES_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_FAVOURITES_LIST_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}
export const fetchResArticles = (lang, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_ARTICLES_REQUEST })

    UsersDataService.getResourceFavourites('articles', lang, lr).then((response) => {
      dispatch({
        type: FETCH_ARTICLES_SUCCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_ARTICLES_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}


export const fetchResMantras = (lang, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_MANTRAS_REQUEST })

    UsersDataService.getResourceFavourites('mantras', lang, lr).then((response) => {
      dispatch({
        type: FETCH_MANTRAS_SUCCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_MANTRAS_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const fetchResAudios = (lang, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_AUDIOS_REQUEST })

    UsersDataService.getResourceFavourites('audios', lang, lr).then((response) => {
      dispatch({
        type: FETCH_AUDIOS_SUCCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_AUDIOS_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}


export const fetchResLivetv = (lang, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_RES_LIVETV_REQUEST })

    UsersDataService.getResourceFavourites('livetv', lang, lr).then((response) => {
      dispatch({
        type: FETCH_RES_LIVETV_SUCCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_RES_LIVETV_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const fetchResVideos = (lang, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_VIDEOS_REQUEST })

    UsersDataService.getResourceFavourites('videos', lang, lr).then((response) => {
      dispatch({
        type: FETCH_VIDEOS_SUCCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_VIDEOS_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}


export const fetchResourceFavourites = (res, lang, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_RESOURCE_FAVOURITES_LIST_REQUEST })

    UsersDataService.getResourceFavourites(res, lang, lr).then((response) => {
      dispatch({
        type: FETCH_RESOURCE_FAVOURITES_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_RESOURCE_FAVOURITES_LIST_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const fetchBooksFavourites = (res) => {
  return (dispatch) => {
    dispatch({ type: FETCH_BOOKS_FAVOURITES_LIST_REQUEST })

    UsersDataService.getBooksFavourites(res).then((response) => {
      dispatch({
        type: FETCH_BOOKS_FAVOURITES_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_BOOKS_FAVOURITES_LIST_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const fetchTempleFavourites = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPLE_FAVOURITES_LIST_REQUEST })

    UsersDataService.getTempleFavourites().then((response) => {
      dispatch({
        type: FETCH_TEMPLE_FAVOURITES_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_TEMPLE_FAVOURITES_LIST_FAILURE,
        payload: error.message,
      })
      // failure(error.message);
    })
  }
}

export const fetchFilterByGod = (lang, did, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_FILTER_BY_GOD_REQUEST })

    UsersDataService.getFilterByGod(lang, did, lr).then((response) => {
      dispatch({
        type: FETCH_FILTER_BY_GOD_SUCESS,
        payload: decrypt_data(response.data)
      })
      localStorage.setItem('templeid', response.data.tid);
    }).catch((error) => {
      dispatch({
        type: FETCH_FILTER_BY_GOD_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchRecentlyViewed = (lang, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_RECENTLY_VIEWED_REQUEST })

    UsersDataService.getRecentlyViewed(lang, lr).then((response) => {
      dispatch({
        type: FETCH_RECENTLY_VIEWED_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_RECENTLY_VIEWED_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchValidUserLogin = (uid, pwd, success, failure) => {
  return (dispatch) => {
    dispatch({ type: FETCH_USER_LOGIN_REQUEST });
    UsersDataService.getValidStandardUserLogin(uid, pwd).then((response) => {
      dispatch({
        type: FETCH_USER_LOGIN_SUCESS,
        payload: response.data
      });

      // Store the access token, refresh token, and the time the token was obtained
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('uid', response.data.userid);
      localStorage.setItem('name', response.data.fullname);
      localStorage.setItem('urole', response.data.urole);
      localStorage.setItem('lang', response.data.lang);
      localStorage.setItem('refreshToken', response.data.refresh_token);
      // Store the timestamp of when the token was obtained
      localStorage.setItem('tokenTimestamp', new Date().getTime());
      success();

    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_USER_LOGIN_FAILURE,
        payload: error.message,
      });
      failure(errorMsg);
    });
  }
}

export const fetchTempleMinDetails = (lang, tid) => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPLES_MIN_REQUEST })
    TemplesDataService.getTempleMinDetails(lang, tid).then((response) => {
      dispatch({
        type: FETCH_TEMPLES_MIN_SUCCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_TEMPLES_MIN_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchRefreshToken = (refreshToken) => {
  return (dispatch) => {
    dispatch({ type: FETCH_REFRESH_TOKEN_REQUEST });
    UsersDataService.getRefreshToken(refreshToken, API_KEYS.USER_AUTH_KEY).then((response) => {
      dispatch({
        type: FETCH_REFRESH_TOKEN_SUCESS,
        payload: response.data
      });

      // Store the new access token
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('refreshToken', response.data.refresh_token);
      localStorage.setItem('tokenTimestamp', new Date().getTime());
    }).catch((error) => {
      dispatch({
        type: FETCH_REFRESH_TOKEN_FAILURE,
        payload: error.message,
      });
      localStorage.clear();
      localStorage.removeItem('token');
      window.location.reload('/');
    });
  }
}


export const postPasswordRequest = (data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_REQUEST_PASSWORD_REQUEST })

    UsersDataService.postPasswordRequest(data).then((response) => {
      dispatch({
        type: POST_REQUEST_PASSWORD_SUCESS,
        payload: response.data
      })
      sucess();
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_REQUEST_PASSWORD_FAILURE,
        payload: error.message,
      })
      failure(errorMsg);
    })
  }
}

export const postSetNewPasswordRequest = () => {
  return (dispatch) => {
    dispatch({ type: POST_SET_NEW_PASSWORD_REQUEST })

    UsersDataService.postSetNewPassword().then((response) => {
      dispatch({
        type: POST_SET_NEW_PASSWORD_SUCESS,
        payload: response.data
      })
    }).catch((error) => {
      dispatch({
        type: POST_SET_NEW_PASSWORD_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const postUploadImageRequest = (tid, formData, success, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_UPLOAD_IMAGE_REQUEST });

    // Assuming you have a function in UsersDataService that handles the image upload
    UsersDataService.postUploadImage(tid, formData)
      .then((response) => {
        dispatch({
          type: POST_UPLOAD_IMAGE_SUCESS,
          payload: response.data
        });
        success();
      })
      .catch((error) => {
        const errorMsg = error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : error.message;
        console.error('API Error:', error); // Log the error
        dispatch({
          type: POST_UPLOAD_IMAGE_FAILURE,
          payload: error.message,
        });
        failure(errorMsg);
      });
  };
};

export const postLibraryUploadImageRequest = (res, rid, formData, success, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_LIBRARY_UPLOAD_IMAGE_REQUEST });

    // Assuming you have a function in UsersDataService that handles the image upload
    UsersDataService.postLibraryUploadImage(res, rid, formData)
      .then((response) => {
        dispatch({
          type: POST_LIBRARY_UPLOAD_IMAGE_SUCESS,
          payload: response.data
        });
        success();
      })
      .catch((error) => {
        const errorMsg = error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : error.message;
        console.error('API Error:', error); // Log the error
        dispatch({
          type: POST_LIBRARY_UPLOAD_IMAGE_FAILURE,
          payload: error.message,
        });
        failure(errorMsg);
      });
  };
};


export const postRegisterUserRequest = (data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_REGISTER_USER_REQUEST })

    UsersDataService.postRegisterNewUser(data).then((response) => {
      dispatch({
        type: POST_REGISTER_USER_SUCESS,
        payload: response.data
      })
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_REGISTER_USER_FAILURE,
        payload: error.message,
      })
      failure(errorMsg)
    })
  }
}

export const postChangePasswordRequest = (data, sucess) => {
  return (dispatch) => {
    dispatch({ type: POST_CHANGE_PASSWORD_REQUEST })

    UsersDataService.postSetNewPassword(data).then((response) => {
      dispatch({
        type: POST_CHANGE_PASSWORD_SUCESS,
        payload: response.data
      })
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_CHANGE_PASSWORD_FAILURE,
        payload: error.message,
      })
      // failure(errorMsg)
    })
  }
}

export const postPasswordReset = (mail, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_PASSWORD_RESET_REQUEST })

    UsersDataService.postPasswordReset(mail, data).then((response) => {
      dispatch({
        type: POST_PASSWORD_RESET_SUCESS,
        payload: response.data
      })
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_PASSWORD_RESET_FAILURE,
        payload: error.message,
      })
      failure(errorMsg)
    })
  }
}

export const postPriest = (data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_PRIEST_LIST_REQUEST })

    priestService.createPriest(data).then((response) => {
      dispatch({
        type: POST_PRIEST_LIST_SUCESS,
        payload: response.data
      })
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_PRIEST_LIST_FAILURE,
        payload: error.message,
      })
      failure(errorMsg)
    })
  }
}

export const postNewPriest = (id, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_PRIEST_LIST_REQUEST })

    priestService.createNewPriest(id, data).then((response) => {
      dispatch({
        type: POST_PRIEST_LIST_SUCESS,
        payload: response.data
      })
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_PRIEST_LIST_FAILURE,
        payload: error.message,
      })
      failure(errorMsg)
    })
  }
}

export const postTranslate = (ini, tid, target, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_TRANSLATION_LIST_REQUEST })

    adminService.createTranslation(ini, tid, target).then((response) => {
      dispatch({
        type: POST_TRANSLATION_LIST_SUCESS,
        payload: response.data
      })
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_TRANSLATION_LIST_FAILURE,
        payload: error.message,
      })
      failure(errorMsg)
    })
  }
}

export const postPriestReq = (data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_PRIEST_REQ_REQUEST })

    priestService.createPriestReq(data).then((response) => {
      dispatch({
        type: POST_PRIEST_REQ_SUCESS,
        payload: response.data
      })
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_PRIEST_REQ_FAILURE,
        payload: error.message,
      })
      failure(errorMsg)
    })
  }
}

export const fetchLiveTv = (lang, did, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_LIVETV_REQUEST })
    UsersDataService.getLiveTv(lang, did, lr).then((response) => {
      dispatch({
        type: FETCH_LIVETV_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_LIVETV_FAILURE,
        payload: error.message,
      })
      // failure(error.message)
    })
  }
}

export const fetchVideoLinks = (res, lang, did, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_VIDEO_LINKS_REQUEST })

    UsersDataService.getVideoLinks(res, lang, did, lr).then((response) => {
      dispatch({
        type: FETCH_VIDEO_LINKS_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_VIDEO_LINKS_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchBooks = (res, lang, did, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_BOOKS_REQUEST })

    UsersDataService.getBooks(res, lang, did, lr).then((response) => {
      dispatch({
        type: FETCH_BOOKS_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_BOOKS_FAILURE,
        payload: error.message,
      })
      // failure(error.message)
    })
  }
}

export const fetchBooksByTag = (res, lang, did, tag, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_BOOKS_BY_TAG_REQUEST })

    UsersDataService.getBooksByTag(res, lang, did, tag, lr).then((response) => {
      dispatch({
        type: FETCH_BOOKS_BY_TAG_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_BOOKS_BY_TAG_FAILURE,
        payload: error.message,
      })
      // failure(error.message)
    })
  }
}

export const fetchResource = (lang, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_RESOURCE_REQUEST })

    UsersDataService.getResource(lang, lr).then((response) => {
      dispatch({
        type: FETCH_RESOURCE_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_RESOURCE_FAILURE,
        payload: error.message,
      })
      //failure(error.message)
    })
  }
}

export const fetchResourceByTxt = (res, lang, txt, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_RESOURCE_BY_TXT_REQUEST })

    UsersDataService.getResourceByTxt(res, lang, txt, lr).then((response) => {
      dispatch({
        type: FETCH_RESOURCE_BY_TXT_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_RESOURCE_BY_TXT_FAILURE,
        payload: error.message,
      })
      //failure(error.message)
    })
  }
}

export const fetchDailyHoroscopeList = (lang, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_DAILY_HOROSCOPE_LIST_REQUEST })

    UsersDataService.getDailyHoroscopeList(lang, lr).then((response) => {
      dispatch({
        type: FETCH_DAILY_HOROSCOPE_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_DAILY_HOROSCOPE_LIST_FAILURE,
        payload: error.message,
      })
      // failure(errorMsg)
    })
  }
}


export const fetchWeeklyHoroscopeList = (lang, type, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_WEEKLY_HOROSCOPE_LIST_REQUEST })

    UsersDataService.getWeeklyHoroscopeList(lang, type, lr).then((response) => {
      dispatch({
        type: FETCH_WEEKLY_HOROSCOPE_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_WEEKLY_HOROSCOPE_LIST_FAILURE,
        payload: error.message,
      })
      //failure(error.message)
    })
  }
}

export const fetchDailyHoroscope = (lang, dt, failure) => {
  return (dispatch) => {
    dispatch({ type: FETCH_DAILY_HOROSCOPE_REQUEST })

    UsersDataService.getDailyHoroscope(lang, dt).then((response) => {
      dispatch({
        type: FETCH_DAILY_HOROSCOPE_SUCESS,
        payload: decrypt_data(response.data)
      })
      // localStorage.setItem('hid', response.data.hid);
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_DAILY_HOROSCOPE_FAILURE,
        payload: error.message,
      })
      failure(errorMsg)
    })
  }
}

export const fetchDailyHoroscopeDetails = (id, lang, failure) => {
  return (dispatch) => {
    dispatch({ type: FETCH_DAILY_HOROSCOPE_DETAILS_REQUEST })

    UsersDataService.getDailyHoroscopeDetails(id, lang).then((response) => {
      dispatch({
        type: FETCH_DAILY_HOROSCOPE_DETAILS_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_DAILY_HOROSCOPE_DETAILS_FAILURE,
        payload: error.message,
      })
      // failure(errorMsg)
    })
  }
}

export const fetchWeeklyHoroscopeDetails = (id, lang) => {
  return (dispatch) => {
    dispatch({ type: FETCH_WEEKLY_HOROSCOPE_DETAILS_REQUEST })

    UsersDataService.getWeeklyHoroscopeDetails(id, lang).then((response) => {
      dispatch({
        type: FETCH_WEEKLY_HOROSCOPE_DETAILS_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_WEEKLY_HOROSCOPE_DETAILS_FAILURE,
        payload: error.message,
      })
      // failure(errorMsg)
    })
  }
}

export const fetchHoroDetailsByDate = (id, lang, date, failure) => {
  return (dispatch) => {
    dispatch({ type: FETCH_DAILY_HORO_DETAILS_BY_DATE_REQUEST })

    UsersDataService.getHoroscopeDetailsByDate(id, lang, date).then((response) => {
      dispatch({
        type: FETCH_DAILY_HORO_DETAILS_BY_DATE_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_DAILY_HORO_DETAILS_BY_DATE_FAILURE,
        payload: error.message,
      })
      failure(errorMsg)
    })
  }
}

export const fetchPriestDetails = (id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_PRIEST_DETAILS_REQUEST });
    priestService.getPriestDetails(id).then((response) => {
      dispatch({
        type: FETCH_PRIEST_DETAILS_SUCESS,
        payload: decrypt_data(response.data)
      });

    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_PRIEST_DETAILS_FAILURE,
        payload: errorMsg,
      });
      // failure(errorMsg);
    });
  };
};

export const fetchAppUsers = (lr, success) => {
  return (dispatch) => {
    dispatch({ type: FETCH_APP_USERS_REQUEST });
    adminService.getAppUsers(lr).then((response) => {
      dispatch({
        type: FETCH_APP_USERS_SUCCESS, // Use correct action type
        payload: decrypt_data(response.data),
      });
      if (success) success(); // Ensure the success callback exists before calling it
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: FETCH_APP_USERS_FAILURE,
        payload: errorMsg,
      });
      // failure(errorMsg); // Uncomment if you have a failure callback defined
    });
  };
};

export const fetchPriestReqDetails = (id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_PRIEST_REQ_DETAILS_REQUEST })

    priestService.getMyRequestDetails(id).then((response) => {
      dispatch({
        type: FETCH_PRIEST_REQ_DETAILS_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_PRIEST_REQ_DETAILS_FAILURE,
        payload: error.message,
      })
      //failure(error.message)
    })
  }
}

export const fetchWeeklyHoroscope = (type, lang, dt) => {
  return (dispatch) => {
    dispatch({ type: FETCH_WEEKLY_HOROSCOPE_REQUEST })

    UsersDataService.getWeeklyHoroscope(type, lang, dt).then((response) => {
      dispatch({
        type: FETCH_WEEKLY_HOROSCOPE_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_WEEKLY_HOROSCOPE_FAILURE,
        payload: error.message,
      })
      //failure(error.message)
    })
  }
}

export const fetchTempleBooks = (lang, tid, did) => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPLE_BOOKS_REQUEST })

    UsersDataService.getTempleBooks(lang, tid, did).then((response) => {
      dispatch({
        type: FETCH_TEMPLE_BOOKS_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_TEMPLE_BOOKS_FAILURE,
        payload: error.message,
      })
      // failure(error.message)
    })
  }
}

export const fetchTempleNotifi = (lang, tid, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPLE_NOTIFI_REQUEST })

    UsersDataService.getTempleNotifi(lang, tid, lr).then((response) => {
      dispatch({
        type: FETCH_TEMPLE_NOTIFI_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_TEMPLE_NOTIFI_FAILURE,
        payload: error.message,
      })
      // failure(error.message)
    })
  }
}

export const postHoroscope = (data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_HOROSCOPE_LIST_REQUEST })

    TemplesDataService.createHoroscope(data).then((response) => {
      dispatch({
        type: POST_HOROSCOPE_LIST_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_HOROSCOPE_LIST_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const postWeeklyHoroscope = (data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_WEEKLY_HOROSCOPE_REQUEST })

    TemplesDataService.createWeeklyHoroscope(data).then((response) => {
      dispatch({
        type: POST_WEEKLY_HOROSCOPE_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_WEEKLY_HOROSCOPE_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const fetchRegionalNotifi = (lang, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_REGIONAL_NOTIFI_REQUEST })

    UsersDataService.getRegionalNotifi(lang, lr).then((response) => {
      dispatch({
        type: FETCH_REGIONAL_NOTIFI_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_REGIONAL_NOTIFI_FAILURE,
        payload: error.message,
      })
      // failure(error.message)
    })
  }
}

export const fetchTempleVideo = (lang, did, tid) => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPLE_VIDEO_REQUEST })

    UsersDataService.getTempleVideo(lang, did, tid).then((response) => {
      dispatch({
        type: FETCH_TEMPLE_VIDEO_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_TEMPLE_VIDEO_FAILURE,
        payload: error.message,
      })
      // failure(error.message)
    })
  }
}

export const fetchTempleAudio = (path, filename) => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPLE_AUDIO_REQUEST });

    UsersDataService.getTempleAudio(path, filename, { responseType: 'arraybuffer' }) // Using 'arraybuffer' instead of 'blob'
      .then((response) => {
        // Convert the array buffer to a Blob
        const blob = new Blob([response.data], { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(blob);
        // Dispatch the success action with the audio URL
        dispatch({
          type: FETCH_TEMPLE_AUDIO_SUCESS,
          payload: audioUrl,
        });
      })
      .catch((error) => {
        console.error('Error fetching audio:', error.message);
        dispatch({
          type: FETCH_TEMPLE_AUDIO_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const postTemples = (data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_TEMPLES_LIST_REQUEST })

    TemplesDataService.create(data).then((response) => {
      dispatch({
        type: POST_TEMPLES_LIST_SUCESS,
        payload: response.data
      });
      localStorage.setItem('templeid', response.data);
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_TEMPLES_LIST_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const postDonations = (data, success, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_DONATIONS_LIST_REQUEST })

    adminService.createDonations(data).then((response) => {
      dispatch({
        type: POST_DONATIONS_LIST_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_DONATIONS_LIST_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const postExpenses = (data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_EXPENSES_LIST_REQUEST })

    adminService.createExpenses(data).then((response) => {
      dispatch({
        type: POST_EXPENSES_LIST_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_EXPENSES_LIST_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const updatedonationType = (data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_DONATIONS_TYPE_REQUEST })

    adminService.updateDonationType(data).then((response) => {
      dispatch({
        type: UPDATE_DONATIONS_TYPE_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: UPDATE_DONATIONS_TYPE_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const updatedonorDetails = (id, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_DONOR_DETAILS_REQUEST })

    adminService.updateDonorDetais(id, data).then((response) => {
      dispatch({
        type: UPDATE_DONOR_DETAILS_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: UPDATE_DONOR_DETAILS_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const updatedonationPaymentDetails = (id, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_DONATION_PAYMENT_DETAILS_REQUEST })

    adminService.updateDonationPaymentDetais(id, data).then((response) => {
      dispatch({
        type: UPDATE_DONATION_PAYMENT_DETAILS_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: UPDATE_DONATION_PAYMENT_DETAILS_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const updateHoroTitle = (hid, lang, data, sucess) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_HOROSCOPE_TITLE_REQUEST })
    TemplesDataService.updateHoroTitle(hid, lang, data).then((response) => {
      dispatch({
        type: UPDATE_HOROSCOPE_TITLE_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: UPDATE_HOROSCOPE_TITLE_FAILURE,
        payload: error.message,
      });
      // failure(errorMsg)
    })
  }
}

export const updateHoroTxt = (hid, lang, data, sucess) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_HOROSCOPE_TEXT_REQUEST })
    TemplesDataService.updateHoroText(hid, lang, data).then((response) => {
      dispatch({
        type: UPDATE_HOROSCOPE_TEXT_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: UPDATE_HOROSCOPE_TEXT_FAILURE,
        payload: error.message,
      });
      // failure(errorMsg)
    })
  }
}

export const updateHoroLocation = (hid, lang, data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_HORO_LOC_REQUEST })
    TemplesDataService.updateHoroLocation(hid, lang, data).then((response) => {
      dispatch({
        type: UPDATE_HORO_LOC_SUCESS,
        payload: response.data
      });
      // sucess()
    }).catch((error) => {
      dispatch({
        type: UPDATE_HORO_LOC_FAILURE,
        payload: error.message,
      });
      // failure(error.message)
    })
  }
}

export const updateExpenseType = (id, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_EXPENSES_TYPE_REQUEST })

    adminService.updateExpenseType(id, data).then((response) => {
      dispatch({
        type: UPDATE_EXPENSES_TYPE_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: UPDATE_EXPENSES_TYPE_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const updateDonationAmount = (id, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_DONATIONS_AMOUNT_REQUEST })

    adminService.updateDonationAmount(id, data).then((response) => {
      dispatch({
        type: UPDATE_DONATIONS_AMOUNT_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: UPDATE_DONATIONS_AMOUNT_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const updateExpenseAmount = (id, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_EXPENSES_AMOUNT_REQUEST })

    adminService.updateExpenseAmount(id, data).then((response) => {
      dispatch({
        type: UPDATE_EXPENSES_AMOUNT_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: UPDATE_EXPENSES_AMOUNT_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const updateDonationBill = (id, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_DONATIONS_BILL_REQUEST })

    adminService.updateDonationBill(id, data).then((response) => {
      dispatch({
        type: UPDATE_DONATIONS_BILL_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: UPDATE_DONATIONS_BILL_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const updateExpenseBill = (id, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_EXPENSES_BILL_REQUEST })

    adminService.updateExpenseBill(id, data).then((response) => {
      dispatch({
        type: UPDATE_EXPENSES_BILL_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: UPDATE_EXPENSES_BILL_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const updateDonationNotes = (id, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_DONATIONS_NOTES_REQUEST })

    adminService.updateDonationNotes(id, data).then((response) => {
      dispatch({
        type: UPDATE_DONATIONS_NOTES_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: UPDATE_DONATIONS_NOTES_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const updateExpenseNotes = (id, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_EXPENSES_NOTES_REQUEST })

    adminService.updateExpenseNotes(id, data).then((response) => {
      dispatch({
        type: UPDATE_EXPENSES_NOTES_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: UPDATE_EXPENSES_NOTES_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const updateDonationPayment = (id, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_DONATIONS_PAYMENT_REQUEST })

    adminService.updateDonationPayment(id, data).then((response) => {
      dispatch({
        type: UPDATE_DONATIONS_PAYMENT_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: UPDATE_DONATIONS_PAYMENT_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const postNotification = (data, success, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_NOTIFICATIONS_LIST_REQUEST })

    UsersDataService.postNotification(data).then((response) => {
      dispatch({
        type: POST_NOTIFICATIONS_LIST_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: POST_NOTIFICATIONS_LIST_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const deleteTempleImage = (tid, imgid, data) => {
  return (dispatch) => {
    dispatch({ type: DELETE_IMAGE_LIST_REQUEST })

    UsersDataService.deleteImage(tid, imgid, data).then((response) => {
      dispatch({
        type: DELETE_IMAGE_LIST_SUCESS,
        payload: response.data
      });
      // sucess()
    }).catch((error) => {
      dispatch({
        type: DELETE_IMAGE_LIST_FAILURE,
        payload: error.message,
      });
      // failure(error.message)
    })
  }
}

export const updateTempleImage = (tid, data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_IMAGE_LIST_REQUEST })

    UsersDataService.updateImage(tid, data).then((response) => {
      dispatch({
        type: UPDATE_IMAGE_LIST_SUCESS,
        payload: response.data
      });
      // sucess()
    }).catch((error) => {
      dispatch({
        type: UPDATE_IMAGE_LIST_FAILURE,
        payload: error.message,
      });
      // failure(error.message)
    })
  }
}

export const deleteDonation = (did, ts, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: DELETE_DONATION_LIST_REQUEST })

    adminService.deleteDonation(did, ts).then((response) => {
      dispatch({
        type: DELETE_DONATION_LIST_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: DELETE_DONATION_LIST_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const deleteExpenses = (did, ts, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: DELETE_EXPENSES_LIST_REQUEST })

    adminService.deleteExpenses(did, ts).then((response) => {
      dispatch({
        type: DELETE_EXPENSES_LIST_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      dispatch({
        type: DELETE_EXPENSES_LIST_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}


export const postTempleGroup = (data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_TEMPLE_GP_LIST_REQUEST })

    UsersDataService.postTempleGroup(data).then((response) => {
      dispatch({
        type: POST_TEMPLE_GP_LIST_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      dispatch({
        type: POST_TEMPLE_GP_LIST_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const addTempleToGroup = (gid, tid, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_TEMPLE_TO_GP_LIST_REQUEST })

    UsersDataService.addTempleToGroup(gid, tid).then((response) => {
      dispatch({
        type: POST_TEMPLE_TO_GP_LIST_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      dispatch({
        type: POST_TEMPLE_TO_GP_LIST_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const postImage = (rid, filePath) => {
  return (dispatch) => {
    dispatch({ type: POST_IMAGE_FILE_REQUEST })

    TemplesDataService.postImage(rid, filePath).then((response) => {
      dispatch({
        type: POST_IMAGE_FILE_SUCESS,
        payload: response.data
      });
      // sucess()
    }).catch((error) => {
      dispatch({
        type: POST_IMAGE_FILE_FAILURE,
        payload: error.message,
      });
      // failure(error.message)
    })
  }
}

export const postResource = (data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_RESOURCE_LIST_REQUEST })

    UsersDataService.postResource(data).then((response) => {
      dispatch({
        type: POST_RESOURCE_LIST_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      dispatch({
        type: POST_RESOURCE_LIST_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const postResourceTxt = (data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_RESOURCE_TXT_REQUEST })

    UsersDataService.postResourceTxt(data).then((response) => {
      dispatch({
        type: POST_RESOURCE_TXT_SUCESS,
        payload: response.data
      });
      sucess()
      localStorage.setItem('resid', response.data)
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
      ? error.response.data.error
      : error.message;
      dispatch({
        type: POST_RESOURCE_TXT_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const postHoroTxt = (data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_HORO_TXT_REQUEST })

    UsersDataService.postHoroTxt(data).then((response) => {
      dispatch({
        type: POST_HORO_TXT_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
      ? error.response.data.error
      : error.message;
      dispatch({
        type: POST_HORO_TXT_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const editTemples = (id, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: EDIT_TEMPLES_LIST_REQUEST })

    UsersDataService.update(id, data).then((response) => {
      dispatch({
        type: EDIT_TEMPLES_LIST_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      dispatch({
        type: EDIT_TEMPLES_LIST_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const editResource = (rid, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: EDIT_RESOURCE_LIST_REQUEST })

    TemplesDataService.updateResource(rid, data).then((response) => {
      dispatch({
        type: EDIT_RESOURCE_LIST_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      dispatch({
        type: EDIT_RESOURCE_LIST_FAILURE,
        payload: error.message,
      });
      failure(error);
    })
  }
}

export const updateRecentlyViewed = (data) => {
  return (dispatch) => {
    dispatch({ type: EDIT_RECENTLY_VIEWED_REQUEST })

    UsersDataService.updateRecentlyViewed(data).then((response) => {
      dispatch({
        type: EDIT_RECENTLY_VIEWED_SUCESS,
        payload: response.data
      });
      // sucess()
    }).catch((error) => {
      dispatch({
        type: EDIT_RECENTLY_VIEWED_FAILURE,
        payload: error.message,
      });
      // failure(error.message)
    })
  }
}

export const editLang = (data, sucess) => {
  return (dispatch) => {
    dispatch({ type: EDIT_LANG_LIST_REQUEST })

    UsersDataService.updateLang(data).then((response) => {
      dispatch({
        type: EDIT_LANG_LIST_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      dispatch({
        type: EDIT_LANG_LIST_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const editGender = (data, sucess) => {
  return (dispatch) => {
    dispatch({ type: EDIT_GENDER_LIST_REQUEST })

    UsersDataService.updateGender(data).then((response) => {
      dispatch({
        type: EDIT_GENDER_LIST_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      dispatch({
        type: EDIT_GENDER_LIST_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const editCity = (data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: EDIT_CITY_LIST_REQUEST })

    UsersDataService.updateCity(data).then((response) => {
      dispatch({
        type: EDIT_CITY_LIST_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      dispatch({
        type: EDIT_CITY_LIST_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const editAvatar = (tid, data) => {
  return (dispatch) => {
    dispatch({ type: EDIT_AVATAR_LIST_REQUEST })

    UsersDataService.updateAvatar(tid, data).then((response) => {
      dispatch({
        type: EDIT_AVATAR_LIST_SUCESS,
        payload: response.data
      });
    }).catch((error) => {
      dispatch({
        type: EDIT_AVATAR_LIST_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const editName = (data, sucess) => {
  return (dispatch) => {
    dispatch({ type: EDIT_NAME_LIST_REQUEST })

    UsersDataService.updateName(data).then((response) => {
      dispatch({
        type: EDIT_NAME_LIST_SUCESS,
        payload: response.data
      });
      if (response.data && response.data.newName) {
        localStorage.setItem('name', response.data.newName);
      }
      sucess()
    }).catch((error) => {
      dispatch({
        type: EDIT_NAME_LIST_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const editTempleStatus = (tid, status, data) => {
  return (dispatch) => {
    dispatch({ type: EDIT_TEMPLE_STATUS_LIST_REQUEST })

    UsersDataService.updateTempleStatus(tid, status, data).then((response) => {
      dispatch({
        type: EDIT_TEMPLE_STATUS_LIST_SUCESS,
        payload: response.data
      });
    }).catch((error) => {
      dispatch({
        type: EDIT_TEMPLE_STATUS_LIST_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const editTempleImageStatus = (tid, data) => {
  return (dispatch) => {
    dispatch({ type: EDIT_TEMPLE_IMAGES_STATUS_LIST_REQUEST })

    UsersDataService.updateTempleImagesStatus(tid, data).then((response) => {
      dispatch({
        type: EDIT_TEMPLE_IMAGES_STATUS_LIST_SUCESS,
        payload: response.data
      });
    }).catch((error) => {
      dispatch({
        type: EDIT_TEMPLE_IMAGES_STATUS_LIST_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const fetchTempleDetails = (lang, tid) => {
  return (dispatch) => {
    dispatch({ type: GET_TEMPLE_DET_REQUEST })
    TemplesDataService.get(lang, tid).then((response) => {
      dispatch({
        type: GET_TEMPLE_DET_SUCCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: GET_TEMPLE_DET_FAILURE,
        payload: error.message,
      })
      // failure(error.message)
    })
  }
}

export const fetchTempleDetailsInOtherLang = (lang, tid,) => {
  return (dispatch) => {
    dispatch({ type: GET_TEMPLE_DET_IN_OTHER_LANG_REQUEST })
    TemplesDataService.getTempleDetailsInOtherLang(lang, tid).then((response) => {
      dispatch({
        type: GET_TEMPLE_DET_IN_OTHER_LANG_SUCCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: GET_TEMPLE_DET_IN_OTHER_LANG_FAILURE,
        payload: error.message,
      })
      // failure(error.message)
    })
  }
}

export const fetchLatestTempleDetails = (lang, tid) => {
  return (dispatch) => {
    dispatch({ type: GET_LATEST_TEMPLE_DET_REQUEST })
    TemplesDataService.getLatestTempleDetails(lang, tid).then((response) => {
      dispatch({
        type: GET_LATEST_TEMPLE_DET_SUCCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: GET_LATEST_TEMPLE_DET_FAILURE,
        payload: error.message,
      })
      // failure(error.message)
    })
  }
}

export const fetchTempleGroupDetails = (lang, gid) => {
  return (dispatch) => {
    dispatch({ type: GET_TEMPLE_GRP_DETAILS_REQUEST })
    TemplesDataService.getTempleGroupDetails(lang, gid).then((response) => {
      dispatch({
        type: GET_TEMPLE_GRP_DETAILS_SUCCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: GET_TEMPLE_GRP_DETAILS_FAILURE,
        payload: error.message,
      })
      // failure(error.message)
    })
  }
}

export const fetchGroupDescription = (lang, gid) => {
  return (dispatch) => {
    dispatch({ type: FETCH_GROUP_DESCRIPTION_REQUEST })
    UsersDataService.getGroupDescription(lang, gid).then((response) => {
      dispatch({
        type: FETCH_GROUP_DESCRIPTION_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_GROUP_DESCRIPTION_FAILURE,
        payload: error.message,
      })
      // failure(error.message)
    })
  }
}

export const fetchResourceDetails = (lang, rid) => {
  return (dispatch) => {
    dispatch({ type: GET_RESOURCE_DET_REQUEST })
    TemplesDataService.getResourceDetails(lang, rid).then((response) => {
      dispatch({
        type: GET_RESOURCE_DET_SUCCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: GET_RESOURCE_DET_FAILURE,
        payload: error.message,
      })
      // failure(error.message)
    })
  }
}

export const retrieveTemples = (lang, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPLES_LIST_REQUEST })
    TemplesDataService.getAll(lang, lr).then((response) => {
      dispatch({
        type: FETCH_TEMPLES_LIST_SUCCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_TEMPLES_LIST_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const retrieveGodNames = (lang) => {
  return (dispatch) => {
    dispatch({ type: FETCH_GOD_NAMES_LIST_REQUEST })
    TemplesDataService.getGodCategories(lang).then((response) => {
      dispatch({
        type: FETCH_GOD_NAMES_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_GOD_NAMES_LIST_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const retrieveTempleDetails = () => {
  return (dispatch) => {
    dispatch({ type: GET_TEMPLE_DET_SUCCESS })
    TemplesDataService.getGodCategories().then((response) => {
      dispatch({
        type: GET_TEMPLE_DET_SUCCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: GET_TEMPLE_DET_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const retriveContributions = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_CONTRIBUTIONS_LIST_REQUEST })
    TemplesDataService.getGodCategories().then((response) => {
      dispatch({
        type: FETCH_CONTRIBUTIONS_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_CONTRIBUTIONS_LIST_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const retriveNotifications = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_NOTIFICATIONS_LIST_REQUEST })
    TemplesDataService.getGodCategories().then((response) => {
      dispatch({
        type: FETCH_NOTIFICATIONS_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_NOTIFICATIONS_LIST_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const retriveFavourites = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_FAVOURITES_LIST_REQUEST })
    TemplesDataService.getGodCategories().then((response) => {
      dispatch({
        type: FETCH_FAVOURITES_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_FAVOURITES_LIST_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_STATE_REQUEST })
    UsersDataService.getState().then((response) => {
      dispatch({
        type: FETCH_STATE_SUCESS,
        payload: decrypt_data(response.data)
      })
      localStorage.setItem('lang', response.data.lang)
      localStorage.setItem('city', response.data.city)
    }).catch((error) => {
      dispatch({
        type: FETCH_STATE_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchByStDt = (lang, st, dt, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_BY_ST_DT_REQUEST })
    UsersDataService.getByStDt(lang, st, dt, lr).then((response) => {
      dispatch({
        type: FETCH_BY_ST_DT_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_BY_ST_DT_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchByStDtText = (lang, st, dt, txt, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_BY_ST_DT_TXT_REQUEST })
    UsersDataService.getByStDtText(lang, st, dt, txt, lr).then((response) => {
      dispatch({
        type: FETCH_BY_ST_DT_TXT_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_BY_ST_DT_TXT_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchAnonomousUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_ANONOMOUS_USER_REQUEST })
    UsersDataService.anonomousUser().then((response) => {
      dispatch({
        type: FETCH_ANONOMOUS_USER_SUCESS,
        payload: response.data
      })
      localStorage.setItem('apptoken', response.data.apptoken);
      localStorage.setItem('appuid', response.data.appuid);
    }).catch((error) => {
      dispatch({
        type: FETCH_ANONOMOUS_USER_FAILURE,
        payload: error.message,
      })
    })
  }
}


export const fetchNearest = (lang, lat, lon, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_NEAREST_REQUEST })
    UsersDataService.getNearest(lang, lat, lon, lr).then((response) => {
      dispatch({
        type: FETCH_NEAREST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_NEAREST_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchFilterNearest = (lang, lat, lon, did, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_FILTER_NEAREST_REQUEST })
    UsersDataService.getFilterNearest(lang, lat, lon, did, lr).then((response) => {
      dispatch({
        type: FETCH_FILTER_NEAREST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_FILTER_NEAREST_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchTemplesByGroup = (lang, gid, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPLES_BY_GP_REQUEST })
    UsersDataService.getTemplesByGroup(lang, gid, lr).then((response) => {
      dispatch({
        type: FETCH_TEMPLES_BY_GP_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_TEMPLES_BY_GP_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchTempleGroups = (lang) => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPLE_GP_REQUEST })
    UsersDataService.getTempleGroups(lang).then((response) => {
      dispatch({
        type: FETCH_TEMPLE_GP_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_TEMPLE_GP_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchTempleTxt = (lang, txt, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPLES_BY_TXT_REQUEST })
    UsersDataService.getTemplesByTxt(lang, txt, lr).then((response) => {
      dispatch({
        type: FETCH_TEMPLES_BY_TXT_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_TEMPLES_BY_TXT_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchPriestSpclty = (state, city, spclty, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_PRIEST_BY_SPCLTY_REQUEST })

    priestService.getPriestBySpclty(state, city, spclty, lr).then((response) => {
      dispatch({
        type: FETCH_PRIEST_BY_SPCLTY_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_PRIEST_BY_SPCLTY_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchMyRequests = (status, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_MY_REQUEST })
    priestService.getMyRequests(status, lr).then((response) => {
      dispatch({
        type: FETCH_MY_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_MY_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchPriestRequests = (status, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_PRIEST_REQUEST })
    priestService.getPriestRequests(status, lr).then((response) => {
      dispatch({
        type: FETCH_PRIEST_REQUEST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_PRIEST_REQUEST_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchServicesByUser = (tid, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_SERVICES_BY_USER_REQUEST })
    servicesService.getServicesByUser(tid, lr).then((response) => {
      dispatch({
        type: FETCH_SERVICES_BY_USER_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_SERVICES_BY_USER_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const postServicesRegisterByAd = () => {
  return (dispatch) => {
    dispatch({ type: POST_SERVICES_REGISTER_BY_AD_REQUEST })

    servicesService.getServicesRegByAd().then((response) => {
      dispatch({
        type: POST_SERVICES_REGISTER_BY_AD_SUCESS,
        payload: response.data
      })
    }).catch((error) => {
      dispatch({
        type: POST_SERVICES_REGISTER_BY_AD_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchTempleServiceRegistration = (lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPLE_SERVICE_REGISTRATION_REQUEST })

    servicesService.getTempleServiceRegistration(lr).then((response) => {
      dispatch({
        type: FETCH_TEMPLE_SERVICE_REGISTRATION_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_TEMPLE_SERVICE_REGISTRATION_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchUserServiceRegistration = (lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_USER_SERVICE_REGISTRATION_REQUEST })

    servicesService.getTempleServiceRegistration(lr).then((response) => {
      dispatch({
        type: FETCH_USER_SERVICE_REGISTRATION_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_USER_SERVICE_REGISTRATION_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchServiceRegDetails = (sregid) => {
  return (dispatch) => {
    dispatch({ type: FETCH_SERVICE_REGISTRATION_DETAILS_REQUEST })

    servicesService.getServiceRegDetails(sregid).then((response) => {
      dispatch({
        type: FETCH_SERVICE_REGISTRATION_DETAILS_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_SERVICE_REGISTRATION_DETAILS_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchServicesDetails = (id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_SERVICES_DETAILS_REQUEST })

    servicesService.getServicesDetails(id).then((response) => {
      dispatch({
        type: FETCH_SERVICES_DETAILS_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_SERVICES_DETAILS_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchServicesByAdmin = (lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_SERVICES_BY_ADMIN_REQUEST })

    servicesService.getServicesByAdmin(lr).then((response) => {
      dispatch({
        type: FETCH_SERVICES_BY_ADMIN_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_SERVICES_BY_ADMIN_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const postService = (data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_PRIEST_LIST_REQUEST })

    servicesService.createService(data).then((response) => {
      dispatch({
        type: POST_PRIEST_LIST_SUCESS,
        payload: response.data
      })
      sucess()
    }).catch((error) => {
      dispatch({
        type: POST_PRIEST_LIST_FAILURE,
        payload: error.message,
      })
      failure(error.message)
    })
  }
}

export const postServiceRegistration = (data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_PRIEST_LIST_REQUEST })

    servicesService.createServiceRegistration(data).then((response) => {
      dispatch({
        type: POST_PRIEST_LIST_SUCESS,
        payload: response.data
      })
      sucess()
    }).catch((error) => {
      dispatch({
        type: POST_PRIEST_LIST_FAILURE,
        payload: error.message,
      })
      failure(error.message)
    })
  }
}

export const postServiceRegistrationPOS = (data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: POST_PRIEST_LIST_REQUEST })

    servicesService.createServiceRegistrationPOS(data).then((response) => {
      dispatch({
        type: POST_PRIEST_LIST_SUCESS,
        payload: response.data
      })
      sucess()
    }).catch((error) => {
      dispatch({
        type: POST_PRIEST_LIST_FAILURE,
        payload: error.message,
      })
      failure(error.message)
    })
  }
}

export const editServiceRegistrationAmount = (servid, data, success) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SERVICE_REGISTRATION_AMOUNT_REQUEST })

    servicesService.updateServiceRegistrationAmount(servid, data).then((response) => {
      dispatch({
        type: UPDATE_SERVICE_REGISTRATION_AMOUNT_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      dispatch({
        type: UPDATE_SERVICE_REGISTRATION_AMOUNT_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const editServiceRegistrationPhone = (servid, data, success) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SERVICE_REGISTRATION_PHONE_REQUEST })

    servicesService.updateServiceRegistrationPhone(servid, data).then((response) => {
      dispatch({
        type: UPDATE_SERVICE_REGISTRATION_PHONE_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      dispatch({
        type: UPDATE_SERVICE_REGISTRATION_PHONE_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const editServiceRegistrationAddr = (servid, data, success) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SERVICE_REGISTRATION_ADDR_REQUEST })

    servicesService.updateServiceRegistrationAddr(servid, data).then((response) => {
      dispatch({
        type: UPDATE_SERVICE_REGISTRATION_ADDR_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      dispatch({
        type: UPDATE_SERVICE_REGISTRATION_ADDR_FAILURE,
        payload: error.message,
      });

    })
  }
}

export const editServiceRegistrationName = (servid, data, success) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SERVICE_REGISTRATION_NAME_REQUEST })

    servicesService.updateServiceRegistrationName(servid, data).then((response) => {
      dispatch({
        type: UPDATE_SERVICE_REGISTRATION_NAME_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      dispatch({
        type: UPDATE_SERVICE_REGISTRATION_NAME_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const editServiceRegistrationBill = (servid, data, success) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SERVICE_REGISTRATION_BILL_REQUEST })

    servicesService.updateServiceRegistrationBill(servid, data).then((response) => {
      dispatch({
        type: UPDATE_SERVICE_REGISTRATION_BILL_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      dispatch({
        type: UPDATE_SERVICE_REGISTRATION_BILL_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const editServiceRegistrationNotes = (servid, data, success) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SERVICE_REGISTRATION_NOTES_REQUEST })

    servicesService.updateServiceRegistrationNotes(servid, data).then((response) => {
      dispatch({
        type: UPDATE_SERVICE_REGISTRATION_NOTES_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      dispatch({
        type: UPDATE_SERVICE_REGISTRATION_NOTES_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const editServiceRegistrationStatus = (servid, data, success) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SERVICE_REGISTRATION_STATUS_REQUEST })

    servicesService.updateServiceRegistrationAdminStatus(servid, data).then((response) => {
      dispatch({
        type: UPDATE_SERVICE_REGISTRATION_STATUS_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      dispatch({
        type: UPDATE_SERVICE_REGISTRATION_STATUS_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const editServiceRegistrationQty = (servid, data, success) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SERVICE_REGISTRATION_QTY_REQUEST })

    servicesService.updateServiceRegistrationQuantity(servid, data).then((response) => {
      dispatch({
        type: UPDATE_SERVICE_REGISTRATION_QTY_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      dispatch({
        type: UPDATE_SERVICE_REGISTRATION_QTY_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const editServiceDate = (servid, data, success, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SERVICE_DATE_REQUEST })

    servicesService.updateServiceDate(servid, data).then((response) => {
      dispatch({
        type: UPDATE_SERVICE_DATE_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      dispatch({
        type: UPDATE_SERVICE_DATE_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const editServiceCapacity = (servid, data, success, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SERVICE_CAPACITY_REQUEST })

    servicesService.updateServiceCapacity(servid, data).then((response) => {
      dispatch({
        type: UPDATE_SERVICE_CAPACITY_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      dispatch({
        type: UPDATE_SERVICE_CAPACITY_FAILURE,
        payload: error.message,
      });
      // failure(error.message)
    })
  }
}

export const editServiceType = (servid, data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SERVICE_TYPE_REQUEST })

    servicesService.updateServiceType(servid, data).then((response) => {
      dispatch({
        type: UPDATE_SERVICE_TYPE_SUCESS,
        payload: response.data
      });
    }).catch((error) => {
      dispatch({
        type: UPDATE_SERVICE_TYPE_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const editServiceBookBefore = (servid, data, success, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SERVICE_BOOKBEFORE })

    servicesService.updateServiceBackBefore(servid, data).then((response) => {
      dispatch({
        type: UPDATE_SERVICE_BOOKBEFORE_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      dispatch({
        type: UPDATE_SERVICE_BOOKBEFORE_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const editServiceStatus = (servid, data, success, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SERVICE_STATUS_REQUEST })

    servicesService.updateServiceStatus(servid, data).then((response) => {
      dispatch({
        type: UPDATE_SERVICE_STATUS_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      dispatch({
        type: UPDATE_SERVICE_STATUS_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const editServiceAmount = (servid, data, success, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SERVICE_AMOUNT_REQUEST })

    servicesService.updateServiceAmount(servid, data).then((response) => {
      dispatch({
        type: UPDATE_SERVICE_AMOUNT_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      dispatch({
        type: UPDATE_SERVICE_AMOUNT_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const editServiceNotes = (servid, data, success, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SERVICE_NOTES_REQUEST })

    servicesService.updateServiceNotes(servid, data).then((response) => {
      dispatch({
        type: UPDATE_SERVICE_NOTES_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      dispatch({
        type: UPDATE_SERVICE_NOTES_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const editServiceTitle = (servid, data, success, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SERVICE_TITLE_REQUEST })

    servicesService.updateServiceTitle(servid, data).then((response) => {
      dispatch({
        type: UPDATE_SERVICE_TITLE_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      dispatch({
        type: UPDATE_SERVICE_TITLE_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const cancelService = (servid, data) => {
  return (dispatch) => {
    dispatch({ type: CANCEL_SERVICE_REQUEST })

    servicesService.cancelService(servid, data).then((response) => {
      dispatch({
        type: CANCEL_SERVICE_SUCESS,
        payload: response.data
      });
    }).catch((error) => {
      dispatch({
        type: CANCEL_SERVICE_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const fetchFilterByStDt = (lang, st, dt, did, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_FILTER_BY_ST_DT_REQUEST })

    UsersDataService.getFilterByStDt(lang, st, dt, did, lr).then((response) => {
      dispatch({
        type: FETCH_FILTER_BY_ST_DT_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_FILTER_BY_ST_DT_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchCityTemplesList = (st, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_CITY_TEMPLES_LIST_REQUEST })

    UsersDataService.getCityTemplesList(st, lr).then((response) => {
      dispatch({
        type: FETCH_CITY_TEMPLES_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_CITY_TEMPLES_LIST_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchCityStDesList = (lang, st, city) => {
  return (dispatch) => {
    dispatch({ type: FETCH_CITY_ST_DESCR_LIST_REQUEST })

    UsersDataService.getCityStDescr(lang, st, city).then((response) => {
      dispatch({
        type: FETCH_CITY_ST_DESCR_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_CITY_ST_DESCR_LIST_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const fetchTemplesOutsideIndList = (lang, country, st, city, avatar, lr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPLES_OUTSIDE_IND_LIST_REQUEST })

    TemplesDataService.getTemplesOutsideInd(lang, country, st, city, avatar, lr).then((response) => {
      dispatch({
        type: FETCH_TEMPLES_OUTSIDE_IND_LIST_SUCESS,
        payload: decrypt_data(response.data)
      })
    }).catch((error) => {
      dispatch({
        type: FETCH_TEMPLES_OUTSIDE_IND_LIST_FAILURE,
        payload: error.message,
      })
    })
  }
}

export const editTempleDetails = (tid, data) => {
  return (dispatch) => {
    dispatch({ type: EDIT_TEMPLE_DETAILS_REQUEST })

    UsersDataService.updateTempleDetails(tid, data).then((response) => {
      dispatch({
        type: EDIT_TEMPLE_DETAILS_SUCESS,
        payload: response.data
      });
    }).catch((error) => {
      dispatch({
        type: EDIT_TEMPLE_DETAILS_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const editTempleLocation = (tid, data, success, failure) => {
  return (dispatch) => {
    dispatch({ type: EDIT_TEMPLE_LOCATION_REQUEST })

    UsersDataService.updateTempleLocation(tid, data).then((response) => {
      dispatch({
        type: EDIT_TEMPLE_LOCATION_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: EDIT_TEMPLE_LOCATION_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

export const editArchitecture = (lang, tid, data) => {
  return (dispatch) => {
    dispatch({ type: EDIT_TEMPLE_ARCHITECTURE_REQUEST })

    TemplesDataService.updateArchitecture(lang, tid, data).then((response) => {
      dispatch({
        type: EDIT_TEMPLE_ARCHITECTURE_SUCESS,
        payload: response.data
      });
    }).catch((error) => {
      dispatch({
        type: EDIT_TEMPLE_ARCHITECTURE_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const editAddInfo = (lang, tid, data) => {
  return (dispatch) => {
    dispatch({ type: EDIT_TEMPLE_ADDINFO_REQUEST })

    TemplesDataService.updateAddInfo(lang, tid, data).then((response) => {
      dispatch({
        type: EDIT_TEMPLE_ADDINFO_SUCESS,
        payload: response.data
      });
    }).catch((error) => {
      dispatch({
        type: EDIT_TEMPLE_ADDINFO_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const editTempleDescr = (lang, tid, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: EDIT_TEMPLE_DESCR_REQUEST })

    UsersDataService.updateTempleDescr(lang, tid, data).then((response) => {
      dispatch({
        type: EDIT_TEMPLE_DESCR_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      dispatch({
        type: EDIT_TEMPLE_DESCR_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const editTempleHist = (tid, ts, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: EDIT_TEMPLE_HIS_REQUEST })

    UsersDataService.updateTempleHist(tid, ts, data).then((response) => {
      dispatch({
        type: EDIT_TEMPLE_HIS_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      dispatch({
        type: EDIT_TEMPLE_HIS_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const editTempleFields = (lang, tid, ts, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: EDIT_TEMPLE_FIELDS_REQUEST })

    UsersDataService.updateTempleFields(lang, tid, ts, data).then((response) => {
      dispatch({
        type: EDIT_TEMPLE_FIELDS_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      dispatch({
        type: EDIT_TEMPLE_FIELDS_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const editTempleAddress = (tid, ts, data) => {
  return (dispatch) => {
    dispatch({ type: EDIT_TEMPLE_ADDRESS_REQUEST })

    UsersDataService.updateTempleAddress(tid, ts, data).then((response) => {
      dispatch({
        type: EDIT_TEMPLE_ADDRESS_SUCESS,
        payload: response.data
      });
    }).catch((error) => {
      dispatch({
        type: EDIT_TEMPLE_ADDRESS_FAILURE,
        payload: error.message,
      });
    })
  }
}

export const editGroupName = (gid, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_GROUP_NAME_REQUEST })

    TemplesDataService.updateGroupName(gid, data).then((response) => {
      dispatch({
        type: UPDATE_GROUP_NAME_SUCESS,
        payload: response.data
      });
      sucess();
    }).catch((error) => {
      dispatch({
        type: UPDATE_GROUP_NAME_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const editGroupRank = (gid, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_GROUP_RANK_REQUEST })

    TemplesDataService.updateGroupRank(gid, data).then((response) => {
      dispatch({
        type: UPDATE_GROUP_RANK_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      dispatch({
        type: UPDATE_GROUP_RANK_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const editGroupDescr = (gid, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_GROUP_DESCR_REQUEST })

    TemplesDataService.updateGroupDescription(gid, data).then((response) => {
      dispatch({
        type: UPDATE_GROUP_DESCR_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      dispatch({
        type: UPDATE_GROUP_DESCR_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const editWeeklyHoroDetails = (hid, lang, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_WHORO_DETAILS_REQUEST })

    TemplesDataService.updateWeeklyHoroDetails(hid, lang, data).then((response) => {
      dispatch({
        type: UPDATE_WHORO_DETAILS_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      dispatch({
        type: UPDATE_WHORO_DETAILS_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const editHoroDetails = (hid, lang, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_HORO_DETAILS_REQUEST })

    TemplesDataService.updateHoroDetails(hid, lang, data).then((response) => {
      dispatch({
        type: UPDATE_HORO_DETAILS_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      dispatch({
        type: UPDATE_HORO_DETAILS_FAILURE,
        payload: error.message,
      });
      failure(error.message)
    })
  }
}

export const updatePriestDetails = (id, data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PRIEST_DETAILS_REQUEST })
    priestService.updatePriestDetails(id, data).then((response) => {
      dispatch({
        type: UPDATE_PRIEST_DETAILS_SUCESS,
        payload: response.data
      });
      // sucess()
    }).catch((error) => {
      dispatch({
        type: UPDATE_PRIEST_DETAILS_FAILURE,
        payload: error.message,
      });
      // failure(error.message)
    })
  }
}

export const updatePriestDegree = (id, data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PRIEST_DEGREE_REQUEST })
    priestService.updatePriestDegreeDetails(id, data).then((response) => {
      dispatch({
        type: UPDATE_PRIEST_DEGREE_SUCESS,
        payload: response.data
      });
      // sucess()
    }).catch((error) => {
      dispatch({
        type: UPDATE_PRIEST_DEGREE_FAILURE,
        payload: error.message,
      });
      // failure(error.message)
    })
  }
}

export const updatePriestSpclty = (data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PRIEST_SPCLTY_REQUEST })
    priestService.updatePriestSpclty(data).then((response) => {
      dispatch({
        type: UPDATE_PRIEST_SPCLTY_SUCESS,
        payload: response.data
      });
      // sucess()
    }).catch((error) => {
      dispatch({
        type: UPDATE_PRIEST_SPCLTY_FAILURE,
        payload: error.message,
      });
      // failure(error.message)
    })
  }
}

export const updatePriestRecent = (id, data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PRIEST_RECENT_REQUEST })
    priestService.updatePriestReqRecent(id, data).then((response) => {
      dispatch({
        type: UPDATE_PRIEST_RECENT_SUCESS,
        payload: response.data
      });
      // sucess()
    }).catch((error) => {
      dispatch({
        type: UPDATE_PRIEST_RECENT_FAILURE,
        payload: error.message,
      });
      // failure(error.message)
    })
  }
}

export const updatePriestReqStatus = (id, data, success) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PRIEST_REQ_STATUS })

    priestService.updatePriestReqStatus(id, data).then((response) => {
      dispatch({
        type: UPDATE_PRIEST_REQ_STATUS_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      dispatch({
        type: UPDATE_PRIEST_REQ_STATUS_FAILURE,
        payload: error.message,
      });
      // failure(error.message)
    })
  }
}

export const updatePriestQuery = (id, data, success) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PRIEST_QUERY_REQUEST })

    priestService.updatePriestReqQury(id, data).then((response) => {
      dispatch({
        type: UPDATE_PRIEST_QUERY_SUCESS,
        payload: response.data
      });
      success()
    }).catch((error) => {
      dispatch({
        type: UPDATE_PRIEST_QUERY_FAILURE,
        payload: error.message,
      });
      // failure(error.message)
    })
  }
}

export const updatePriestResolution = (id, data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PRIEST_RESOLUTION_REQUEST })

    priestService.updatePriestReqResolution(id, data).then((response) => {
      dispatch({
        type: UPDATE_PRIEST_RESOLUTION_SUCESS,
        payload: response.data
      });
      // sucess()
    }).catch((error) => {
      dispatch({
        type: UPDATE_PRIEST_RESOLUTION_FAILURE,
        payload: error.message,
      });
      // failure(error.message)
    })
  }
}

export const updateUserRole = (id, data, sucess, failure) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_USER_ROLE_REQUEST })
    adminService.updateUserRole(id, data).then((response) => {
      dispatch({
        type: UPDATE_USER_ROLE_SUCESS,
        payload: response.data
      });
      sucess()
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : error.message;
      dispatch({
        type: UPDATE_USER_ROLE_FAILURE,
        payload: error.message,
      });
      failure(errorMsg)
    })
  }
}

// export const editAvatar = (tid, ts, data) => {
//   return (dispatch) => {
//     dispatch({ type: EDIT_AVATAR_LIST_REQUEST })
//
//     UsersDataService.updateAvatar(tid, ts, data).then((response) => {
//       dispatch({
//         type: EDIT_AVATAR_LIST_SUCESS,
//         payload: decrypt_data(response.data),
//       });
//     }).catch((error) => {
//       dispatch({
//         type: EDIT_AVATAR_LIST_FAILURE,
//         payload: error.message,
//       });
//     })
//   }
// }