import {
    Form, Input, Typography,
    Col,
    Row,
    Select,
    DatePicker,
} from 'antd';
import React, { useEffect, useState } from 'react';
import "../../addTemple.css"
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getGodNames, getGodNamesError, getGodNamesLoading, } from '../../../../redux/selectors/selectors';
import { fetchGodNames, postHoroTxt, postResourceTxt, retrieveGodNames } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import aum from '../../../../assets/icons/aum.png'
import TextArea from 'antd/es/input/TextArea';
import Footer from '../footer/footer';
import { Option } from 'antd/es/mentions';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import dayjs from 'dayjs';


const AddHoroTxt = () => {

    const { t } = useTranslation();
    const [selectedDeity, setSelectedDeity] = useState(null);
    const localEnv = false;
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { godCtgryList, godCtgryListLoading, godCtgryListError } = useSelector(state => ({
        godCtgryList: getGodNames(state),
        godCtgryListLoading: getGodNamesLoading(state),
        godCtgryListError: getGodNamesError(state),
    }))

    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage])


    const success = () => {
        showToast('success', t('label_add_notifi_sucess'))
        form.resetFields()
    };

    const failure = (msg) => {
        showToast('error', msg)
        form.resetFields()
    };

    const [showAllImages, setShowAllImages] = useState(false);


    const handleImageClick = () => {
        setShowAllImages(true);
    };

    const handleDeityImageClick = (deityNumber) => {
        setSelectedDeity(deityNumber);
        setShowAllImages(false);
        //dispatch(postResource({ did: did, }));
    };


    const handleCloseModal = () => {
        setShowAllImages(false);
    };

    const userid = localStorage.getItem('uid')
    const onFinish = () => {
        form
            .validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                const data = {
                    raw_text: values.raw_text,
                    horo_type: values.horo_type,
                    publish_date: values.publish_date,
                }
                dispatch(postHoroTxt(data, success, failure))
            })
            .catch((errorInfo) => {
                console.error('Validation failed:', errorInfo);
            });

    };
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        // Reset the form to its initial values
        form.resetFields();
        navigate(-1);
        // setButtonText('Edit');
        // setEditable(false);
    };

    return (
        <>
            
            <div class='mainsec'>
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_add_horo_txt')}</span>
                    </div>
                    <div></div>
                </div>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link> -
                    {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                    &nbsp; <span className="bc-active-link" onClick={handleGoBack}>{t('label_create_daily_horo')}</span> -
                    &nbsp; <span className="bc-link">{t("label_add_horo_txt")}</span>
                </div>
                <div class="bg-details-highlight">
                    <div class=" details-headertxt container">
                        <div class="header_txt"> {t('label_add_horo_txt')}</div>
                        <div class="">
                            <a href="#" class="button_move" onClick={handleCancelClick} style={{ marginRight: '5px' }}>
                                {t('label_cancel')}
                            </a>
                            <a href="#" class="button_move" ype="primary" htmlType="submit" onClick={onFinish}>
                                {t('save')}
                            </a>
                        </div>
                        <div class="d-flex flex-row header_star mr-5">
                            <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="mainsec-block-detailspage">
                    <div className='container'>
                        <Form form={form} name="nest-messages" onFinish={onFinish}
                        >
                            <div class="container" ><h5 class="card-text-title">{t("label_add_horo_txt")} <span></span></h5></div>
                            {/* <Typography.Title level={4} style={{ color: "rgb(229 123 13)" }}>{t("label_enter_tmpl_det")}</Typography.Title> */}
                            <div class="active-details">
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='horo_type'
                                        label={<Typography.Title level={4}>{t('label_horoscope_type')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select placeholder='-- Select Category --'>
                                            <Option value="calendar">{t("label_panchangam")}</Option>
                                            <Option value="dhoro">{t("label_daily_horoscope")}</Option>
                                            <Option value="whoro">{t("label_weekly_horoscope")}</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="tiles-designs">
                                    <Form.Item
                                        name="publish_date"
                                        label={<Typography.Title level={4}>{t('label_pub_date')}</Typography.Title>}
                                        // initialValue={dayjs()}
                                        rules={[{ required: true }]}
                                        // initialValue={dayjs()}
                                    >
                                        <DatePicker
                                            style={{ height: '30px' }}
                                            // defaultValue={dayjs()}
                                        />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='raw_text'
                                        label={<Typography.Title level={4}>{t("label_details")}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <TextArea rows={13} placeholder={t('label_enter_details')} />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <Footer />
                </div>

            </div>

        </>
    );
};
export default AddHoroTxt;