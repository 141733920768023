import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, message, Row, Col, Image, Result } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addFavourites, fetchContributions, fetchFavourites, fetchGodNames, fetchTemplesList, postFavourites, removeFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { getImageAPI } from "../../../../http-common";
import deftemples from '../../../../assets/images/templeicon.jpg';
import { getFavouritesError, getFavouritesList, getFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getTemplesList, getTemplesListError, getTemplesListLoading } from '../../../../redux/selectors/selectors';
import Error from '../error';
import Footer from '../footer/footer';
import TempleMap from './templeMap';
import Hammer from 'react-hammerjs';

let last_rec = 0;

const SearchFavourite = () => {
    const records_per_page = 15;
    const [filterId, setFilterId] = useState(null);
    const localEnv = false;
    const [isLoading, setIsLoading] = useState(false);
    const [did, setDid] = useState(0);
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const dispatch = useDispatch();
    const { fList, fLoading, fError, templesList, templesListLoading, templesListError, godCtgryList, godCtgryListLoading, godCtgryListError } = useSelector(state => ({
        fList: getFavouritesList(state),
        fLoading: getFavouritesLoading(state),
        fError: getFavouritesError(state),
        templesList: getTemplesList(state),
        templesListLoading: getTemplesListLoading(state),
        templesListError: getTemplesListError(state),
        godCtgryList: getGodNames(state),
        godCtgryListLoading: getGodNamesLoading(state),
        godCtgryListError: getGodNamesError(state),
    }));


    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage]);

    const [filled, setFilled] = useState({}); // initialize as an empty object
    const [refresh, setRefresh] = useState(false);
    const getToken = Boolean(localStorage.getItem('token'));
    const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Removed from favourites',
        });
    };

    const failure = (msg) => {
        messageApi.open({
            type: 'error',
            content: 'Please Login to see additional features',
        });
    };

    function toggleFilled(id) {
        setFilled(prevFilled => ({
            ...prevFilled,
            [id]: !prevFilled[id]
        }));
    }

    function deleteFavourites(deleteId) {
        dispatch(removeFavourites(deleteId, failure));
        success();
    }

    useEffect(() => {
        dispatch(fetchFavourites(storedLanguage, last_rec));
    }, [storedLanguage, refresh]);

    function addFavourites(id) {
        dispatch(postFavourites(id, failure))
    }

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'));
    }, []);

    function getStyle(godCategoryId) {
        if (godCategoryId === did) {
            return {
                color: '#4096ff',
                borderColor: '#4096ff',
            };
        } else {
            return {
                borderColor: '#d9d9d9',
                color: '#000',
            };
        }
    }

    const nextTemplesList = async () => {
        setIsLoading(true);
        last_rec = last_rec + records_per_page;
        dispatch(fetchFavourites(storedLanguage, last_rec));
    }

    function prevTemplesList() {
        last_rec = last_rec - records_per_page;
        last_rec = (last_rec < 0) ? 0 : last_rec;
        dispatch(fetchFavourites(storedLanguage, last_rec));
        window.scrollTo(0, 0);
    };

    const storeTempleDetails = (temple) => {
        const recentlyViewedTemples = JSON.parse(localStorage.getItem('recentlyViewedTemples')) || [];
        const updatedTemples = [temple, ...recentlyViewedTemples.slice(0, 10)]; // Keep only the latest 10 recently viewed temples
        localStorage.setItem('recentlyViewedTemples', JSON.stringify(updatedTemples));
    };

    let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.tid);

    function toggleFavorite(temple) {
        const index = favList.findIndex(t => t === temple.tid);
        if (index === -1) {
            addFavourites(temple.tid, temple.tname);
            setGetFavoriteTemples(!getFavouriteTemples);
            if (getToken) {
                success(temple.tname);
            }
        } else {
            deleteFavourites(temple.tid, temple.tname);
            setGetFavoriteTemples(!getFavouriteTemples);
            setRefresh(!refresh);
        }

    }
    const temple = fList;

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleNav = () => {
        // navigate(`/templeDetails/${temple.tid}?prevPage=${window.location.pathname}`)
    };

    const [modalVisible, setModalVisible] = useState(false);
    const [selectedTemple, setSelectedTemple] = useState(null);
    // Function to open the modal
    const openModal = (temple) => {
        setSelectedTemple(temple);  // Store the selected temple
        setModalVisible(true);      // Open the modal
    };

    const closeModal = () => {
        setModalVisible(false);
        setSelectedTemple(null);    // Clear the selected temple when closing the modal
    };

    const [modalPreviewVisible, setModalPreviewVisible] = useState(false);
    const [currentTempleId, setCurrentTempleId] = useState(null);

    const currentTemple = fList && Array.isArray(fList) && currentTempleId
        ? fList.find(temple => temple.tid === currentTempleId)
        : null;

    const openPreviewModal = (templeId) => {
        setCurrentTempleId(templeId);
        setModalPreviewVisible(true);
    };

    const closePreviewModal = () => {
        setModalPreviewVisible(false);
    };

    const handleNext = () => {
        if (fList && Array.isArray(fList) && currentTempleId) {
            const currentIndex = fList.findIndex(temple => temple.tid === currentTempleId);
            if (currentIndex !== -1 && currentIndex < fList.length - 1) {
                setCurrentTempleId(fList[currentIndex + 1].tid);
            } else {
                console.error("No more temples to navigate forward.");
            }
        } else {
            console.error("fList is not available or currentTempleId is invalid.");
        }
    };

    const handlePrevious = () => {
        if (fList && Array.isArray(fList) && currentTempleId) {
            const currentIndex = fList.findIndex(temple => temple.tid === currentTempleId);
            if (currentIndex > 0) {
                setCurrentTempleId(fList[currentIndex - 1].tid);
            } else {
                console.error("No more temples to navigate backward.");
            }
        } else {
            console.error("fList is not available or currentTempleId is invalid.");
        }
    };



    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <>

            {selectedTemple && modalVisible && (
                <div className="popup-bg" style={{ display: modalVisible ? 'flex' : 'none' }}>
                    <div className="popup-main animate__animated animate__jackInTheBox">
                        <div className="popup-main-header">
                            <span className="popup-main-header-text"> {selectedTemple.tname}</span>
                            <div className="popup-close" onClick={closeModal}><i className="fa-solid fa-xmark"></i></div>
                            <div className="popup-back" onClick={closeModal}><i className="fa-solid fa-chevron-left"></i></div>
                        </div>
                        <div className="popup-main-container">
                            <div className="d-block htinner-popup-main-container">
                                <TempleMap
                                    tname={selectedTemple.tname}
                                    latit={selectedTemple.latit}
                                    longi={selectedTemple.longi}
                                    zoomOutFactor={10}
                                    city={selectedTemple.city || 'Unknown City'}
                                    state={selectedTemple.st || 'Unknown State'}
                                />
                            </div>
                        </div>
                        <div className="popup-main-footer">
                            <a type="primary" className="button_move" style={{ float: 'right' }} onClick={closeModal}>
                                {t('label_cancel')}
                            </a>
                        </div>
                    </div>
                </div>
            )}
            {modalPreviewVisible && currentTemple && (
                <div className="popup-bg" style={{ display: modalPreviewVisible ? 'flex' : 'none' }}>
                    <div className="popup-main animate__animated animate__jackInTheBox">
                        <div className="popup-main-header">
                            <span className="popup-main-header-text">{currentTemple.tname}</span>
                            <div className="popup-close" onClick={closePreviewModal}>
                                <i className="fa-solid fa-xmark"></i>
                            </div>
                        </div>
                        <div className="popup-main-container">
                            <div className="d-block htinner-popup-main-container">
                                <Link className='ignore-styles' to={`/templeDetails/${currentTemple.tid}?prevPage=${window.location.pathname}`}>
                                    <img
                                        className="grp-descr-img"
                                        alt={currentTemple.tname}
                                        src={currentTemple.imgpath ? getImageAPI() + currentTemple.imgpath : deftemples}
                                    />
                                </Link>
                                <Hammer
                                    onSwipe={(event) => {
                                        if (event.direction === 2) { // Left swipe for next
                                            handleNext();
                                        } else if (event.direction === 4) { // Right swipe for previous
                                            handlePrevious();
                                        }
                                    }}
                                >
                                    <div className='diag-image-content' style={{ whiteSpace: "pre-wrap" }}>
                                        <p><strong>{t('label_temple')} :</strong> {currentTemple.tname}</p>
                                        <p><strong>{t('label_addr')} :</strong> {(currentTemple.addr1) || 'N/A'}{', ' || currentTemple.addr2 || ''}</p>
                                        <p><strong>{t('label_state_city')} :</strong> {capitalizeFirstLetter(currentTemple.city)}, {capitalizeFirstLetter(currentTemple.st)}</p>
                                        <p><strong>{t('label_deity_name')} :</strong> {currentTemple.deityname || 'N/A'}</p>
                                    </div>
                                </Hammer>
                            </div>
                        </div>
                        <div className="popup-main-footer">
                            <button
                                onClick={handlePrevious}
                                className="button_move"
                                style={{ float: 'left', marginRight: "2px" }}
                                disabled={fList.findIndex(temple => temple.tid === currentTempleId) === 0}
                            >
                                {t('label_previous')}
                            </button>
                            <button
                                onClick={handleNext}
                                className="button_move"
                                style={{ float: 'right' }}
                                disabled={fList.findIndex(temple => temple.tid === currentTempleId) === fList.length - 1}
                            >
                                {t('label_next')}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className='mainsec'>

                {contextHolder}
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                        <span className="bc-link">{t("label_my_fav")}</span>
                    </div>
                </div>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>

                    <Link style={{ color: 'black' }} to='/'><span class="bc-active-link">Home</span></Link> -
                    <Link style={{ color: 'black' }} to='/templeCtgry'><span class="bc-active-link">Temples</span></Link> -

                    <span class="bc-link">My Favourites</span>
                </div>
                <div class="mainsec-block">
                    <div class="container cat-txt ">
                        <div class="cat-detail">
                            <span class="f3 d-flex justify-content-between">
                                <div><b>{t("page_title_fav")}</b> &nbsp; &nbsp;<i class="fa-solid fa-volume-high"></i></div>
                                <div class="detail-but">{t('label_show_more')}</div>
                            </span>
                            <p class="f1">{t('label_this_is_favourite_page')}</p>
                        </div>
                    </div>
                    <div className="container search">
                        {(fLoading || fList === null) ? (<Error />) : (
                            <div class="tiles-main">
                                {fList.length === 0 ? (
                                    <Result
                                        status="404"
                                        title="No Data Found"
                                        subTitle="Sorry, no favourite temples found."
                                    />
                                ) : (
                                    fList.map(temple => (
                                        <div class="tiles-design" key={temple.tid}>
                                            <div>
                                                <Link to={`/templeDetails/${temple.tid}?prevPage=${window.location.pathname}`} onClick={() => { storeTempleDetails(); handleNav() }}>
                                                    {temple.imgpath ? (
                                                        <div className="card-img" style={{ backgroundImage: `url(${getImageAPI()}${temple.imgpath})` }}></div>
                                                    ) : (
                                                        <div class="title-img-bg"><img className="card-img" alt="temples" src={deftemples} /></div>
                                                    )}
                                                    <div class="tile-text">
                                                        <div >
                                                            <h6 className="home-temple-text" title={temple.tname}>{temple.tname}</h6>
                                                            <p className="mb-1 f-grey tile-add">{temple.city}, {temple.st}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <div className="tile-quicklink">
                                                    <a className="icon-button" onClick={() => openPreviewModal(temple.tid)}>
                                                        <i className="fa-solid fa-eye"></i>
                                                    </a>
                                                    <i class="fa-solid fa-location-dot" onClick={() => openModal(temple)}></i>

                                                    <a
                                                        className="share-button"
                                                        onClick={() => {
                                                            if (navigator.share) {
                                                                navigator.share({
                                                                    title: "Know " + temple.tname,
                                                                    text: "Know " + temple.tname,
                                                                    url: `https://www.templeswiki.com/templeDetails/${temple.tid}?prevPage=${window.location.pathname}`
                                                                })
                                                                    .then(() => console.warn("Share successful."))
                                                                    .catch((error) => console.error("Error sharing:", error));
                                                            } else {
                                                                // Fallback for browsers that do not support the Web Share API
                                                                console.error("Web Share API not supported.");
                                                            }
                                                        }}
                                                    >
                                                        <i title="Share" className="fa-solid fa-share-nodes"></i>
                                                    </a>
                                                    <Button
                                                        className="temple-fav-icon"
                                                        type="link"
                                                        onClick={() => toggleFavorite(temple, temple.title)}
                                                        icon={
                                                            favList.some(t => t === temple.tid) ? (
                                                                <i
                                                                    className="fa-solid fa-heart"
                                                                    onClick={() => toggleFilled(temple.tid)}
                                                                ></i>
                                                            ) : (
                                                                <i
                                                                    className="fa-solid fa-heart"
                                                                    onClick={() => deleteFavourites(temple.tid)}
                                                                ></i>
                                                            )
                                                        }
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        )}
                    </div>

                    <div className="next-row">
                        {fList && fList.length > 0 && (
                            <>
                                <Col>
                                    <Button
                                        className="button_move"
                                        onClick={() => prevTemplesList()}
                                        disabled={last_rec === 0}
                                    >
                                        Prev
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        className="button_move"
                                        onClick={() => nextTemplesList()}
                                        disabled={fList.length < 15}
                                    >
                                        Next
                                    </Button>
                                </Col>
                            </>
                        )}
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default SearchFavourite;
