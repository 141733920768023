import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row, Skeleton, message, Table, Popconfirm, DatePicker, Space } from 'antd';
import { getDailyHoroscopeList, getDailyHoroscopeLoading, getDonationsByMonthList, getDonationsByMonthListError, getDonationsByMonthListLoading, getDonationsList, getDonationsListError, getDonationsListLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading } from '../../redux/selectors/selectors';
import { deleteDonation, fetchDailyHoroscope, fetchDonationsByMonth, fetchDonationsList, fetchRegionalNotifi } from '../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';

const DonationsByMonth = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const [selectedMonth, setSelectedMonth] = useState(2);
    const [selectedYear, setSelectedYear] = useState(2024);

    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();

    const failure = (msg) => {
        showToast('failure', t('label_donation_monthly_failure')); // You can use 'error' directly
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getDonationsByMonthList(state),
        rLoading: getDonationsByMonthListLoading(state),
        rError: getDonationsByMonthListError(state),
    }));

     useEffect(() => {
        if (selectedMonth !== null && selectedYear !== null) {
            dispatch(fetchDonationsByMonth(selectedYear, selectedMonth));
        }
    }, [selectedMonth, selectedYear]);

    function deleteDon(did, ts) {
        dispatch(deleteDonation(did, ts));
    }

    const onChange = (date, dateString) => {
        const [year, month] = dateString.split("-");
        setSelectedMonth(parseInt(month));
        setSelectedYear(parseInt(year));
    };

    const modifiedData = rList ? rList.map((item, index) => ({ ...item, "S.No": index + 1 })) : [];

    const totalAmount = modifiedData.reduce((acc, curr) => {
        if (curr.decode === "d") {
            return acc + curr.amount;
        } else if (curr.decode === "e") {
            return acc - curr.amount;
        }
        return acc;
    }, 0);

    const downloadAsPDF = () => {
        const doc = new jsPDF();

        // Define column headers
        const headers = columns.map(column => column.title.props.children);

        // Extract data from modifiedData
        const data = modifiedData.map(item => columns.map(column => item[column.dataIndex]));

        // Add headers and data to the PDF
        doc.autoTable({
            head: [headers],
            body: data,
        });

        // Save the PDF
        doc.save('donations.pdf');
    };


    const columns = [

        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Donation ID
                </span>
            ),
            dataIndex: "deid",
            key: "projname",
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Donor Name
                </span>
            ),
            dataIndex: "dename",
            key: "dename",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text, record) => (
                <Link className="notification-link" to={`/donationDetails/${record.donid}`}><span className='donation-text' >{text}</span></Link>
            ),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Donation Type
                </span>
            ),
            dataIndex: "detype",
            key: "ctgry",
            sorter: (a, b) => a.category.localeCompare(b.category),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Date
                </span>
            ),
            dataIndex: "dedt",
            key: "sponsor",
            sorter: (a, b) => a.company.localeCompare(b.company),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("label_amount")}
                </span>
            ),
            dataIndex: "amount",
            key: "startdate",
            sorter: (a, b) => a.started.localeCompare(b.started),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Details
                </span>
            ),
            dataIndex: "decode",
            key: "startdate",
            sorter: (a, b) => a.started.localeCompare(b.started),
        },
    ];

    return (
        <div className='container main'>
            {contextHolder}
            <CustomToastContainer/>
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">Home</span></Link> -
                {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                {/* <span className="bc-link" onClick={handleGoBack}> {title}</span> - */}
                &nbsp;<span className="bc-link">{t("label_donations")}</span>
            </div>
            <div className="container-fluid" style={{ display: 'flex', justifyContent: 'end' }}>
                <Space direction="vertical">
                    <DatePicker onChange={onChange} picker="month" style={{marginTop:'9px'}}/>
                </Space>
                <Link to='/addDonations' className='donation-but'><button type="button" class="btn1 btn btn-secondary " >{t('Add Donation')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                {/*<Button className='profile-form-button' style={{ marginLeft: '5px',marginBottom:'5px',width:'9%' }} onClick={ClearALLNotifi}>
            Clear All
          </Button>*/}
            </div>
            <div className='container'>
                <Table dataSource={modifiedData} columns={columns} bordered
                    style={{ border: '2px solid black', borderCollapse: 'collapse' }}
                    footer={() => (
                        <div>
                            <p>Total Amount: {totalAmount}</p>
                        </div>
                    )}
                />
            </div>
        </div>

    );
};

export default DonationsByMonth;
