import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, DatePicker, Form, Image, Input, Modal, Row, Select, Skeleton, message } from 'antd';
import { getTempleGroupDetailsError, getTempleGroupDetailsList, getTempleGroupDetailsLoading } from '../../../../redux/selectors/selectors';
import { addTempleToGroup, editGroupDescr, editGroupName, editGroupRank, fetchTempleGroupDetails } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import { getImageAPI } from '../../../../http-common';
import deftemples from '../../../../assets/images/templeicon.jpg'
import TextArea from 'antd/es/input/TextArea';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';

const GroupDetails = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { gid } = useParams()
    const dispatch = useDispatch();
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [templeid, setTempleid] = useState('');
    const [dialogVisible, setDialogVisible] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [name, setName] = useState(null);
    const phoneRegex = /^[0-9]+$/;
    const [rank, setRank] = useState(null);
    const [description, setDescription] = useState(null);

    const success = () => {
        showToast('success', t('label_tempgrp_success'))
        // messageApi.open({
        //     type: 'success',
        //     content: 'SUCCESSFULLY EDITED',
        // });
        //form.resetFields()
    };

    const failure = (msg) => {
        showToast('fail', t('label_tempgrp_failure'))
        // messageApi.open({
        //     type: 'failed',
        //     content: msg,
        // });
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getTempleGroupDetailsList(state),
        rLoading: getTempleGroupDetailsLoading(state),
        rError: getTempleGroupDetailsError(state),
    }));
    

    useEffect(() => {
        dispatch(fetchTempleGroupDetails(storedLanguage, gid));
    }, [dispatch]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const [formData, setFormData] = useState({
        // fullname: sDetails.fullname || '',
        // gender: sDetails.gender || '',
        // lang: sDetails.lang || '',
    });

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));


    const handleCancelClick = () => {
        // Reset the form to its initial values
        // form.resetFields();
        setButtonText('Edit');
        setEditable(false);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("label_cancel"));
            // Populate the input field with the current value when entering edit mode
            setFormData({ ...formData, deityname: rList && rList.deityname });
            setFormData({ ...formData, otherdeities: rList && rList.otherdeities });
        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    const handleName = (e) => {
        dispatch(editGroupName(gid, { group_name: `${e.target.value}` }, () => showToast('success', ' Updated Temple Group Details Successfully', 'failure', 'Error occured')))
    };


    const handleRank = (e) => {
        dispatch(editGroupRank(gid, { grank: `${e.target.value}` }, () => showToast('success', 'Updated Temple Group Details Successfully', 'failure', 'Error occured')))
    };

    const handleDescr = (e) => {
        setDescription(e.target.value)
    };

    const updateDescr = () => {
        dispatch(editGroupDescr(gid, { descr: description }, () => showToast('success', ' Updated Temple Group Details Successfully', 'failure', 'Error occured')))
    };

    const sanitizeText = (text) => {
        if (!text) return '';
        return text
            .replace(/<BR\s*\/?>/gi, '\n')
            .replace(/<[^>]*>?/gm, '');
    };


    const openDialog = (group) => {
        setSelectedGroup(group);
        setDialogVisible(true);
    };

    const handleDialogSubmit = () => {
        dispatch(addTempleToGroup(gid, templeid, success, failure));
        setDialogVisible(false);
    };
    const handleDialogueCancel = () => {
        setDialogVisible(false)
    }

    const [currentLang, setCurrentLang] = useState('');

    const languageMap = {
        '5': 'English',
        '4': 'हिंदी',
        '1': 'తెలుగు',
        '2': 'ಕನ್ನಡ',
        '3': 'தமிழ்',
        '6': 'বাংলা',
        '7': 'Española'
    };

    useEffect(() => {
        // Get the stored language from localStorage or default to '5' (English)
        const storedLanguage = localStorage.getItem('lng') || '5';
        setCurrentLang(languageMap[storedLanguage] || languageMap['5']);

        // Listen for localStorage changes across tabs
        const handleStorageChange = (event) => {
            if (event.key === 'lng') {
                window.location.reload();  // Reload the page when language changes
            }
        };

        window.addEventListener('storage', handleStorageChange);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [languageMap]);

    // Function to handle language change from dropdown
    const handleLanguageChange = (newLang) => {
        localStorage.setItem('lng', newLang);  // Update language in localStorage
        window.location.reload();  // Reload the page after the change
    };

    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('Temple Group Details')}</span>
                </div>
                <div></div>
            </div>
            
            {contextHolder}
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> -
                {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                &nbsp; <span className="bc-active-link" onClick={handleGoBack}>{t('label_tmpl_groups_list')}</span> -
                &nbsp; <span className="bc-link">{t("Temple Group Details")}</span>
            </div>
            <div class="bg-details-highlight">
                <div class="details-headertxt container">
                    <div class="header_txt">
                        {rList && rList.group_name}
                    </div>
                    <div class="">
                        {editable ? (
                            <>

                                <a href="#" class="button_move" onClick={updateDescr} style={{ marginRight: '5px' }}>
                                    {t("label_save")}
                                </a>
                                <a href="#" class="button_move" onClick={handleCancelClick}>
                                    {t("label_cancel")}
                                </a>
                            </>
                        ) : (
                            <a href="#" class="button_move" onClick={handleEditClick}>
                                {buttonText}
                            </a>
                        )}
                        <i class="fa-solid fa-circle-plus" style={{ marginLeft: '5px' }} onClick={() => openDialog()}  ></i>
                        <Modal
                            visible={dialogVisible}
                            onOk={handleDialogSubmit}
                            onCancel={handleDialogueCancel}
                            destroyOnClose
                            maskClosable={true}
                            mask={false}
                            style={{ boxShadow: 'none' }}
                        >
                            <div class="diag-header">Enter Temple ID
                                <span class="diag-close"><i class="fa-solid fa-xmark" onClick={handleDialogueCancel}></i></span></div>

                            <Form

                                name="nest-messages"
                                labelCol={{
                                    span: 24,
                                }}
                                wrapperCol={{
                                    span: 24,
                                }}
                            >
                                <div class="active-details">
                                    <div class="tiles-designs" >
                                        <Form.Item

                                            label="Temple ID"
                                            name="templeid"
                                            rules={[
                                                { required: true, message: 'Please enter TempleID' },
                                                {
                                                    pattern: phoneRegex,
                                                    message: 'Please enter a valid Phone Number! Only numbers are allowed.',
                                                },
                                            ]}
                                        > <Input
                                                value={templeid}
                                                onChange={(e) => setTempleid(e.target.value)}
                                            />

                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>

                        </Modal>
                    </div>
                </div>

            </div>
            <div class="mainsec-block-detailspage">
                <div className='container'>
                    {/* <Link to={`/editHoroscope/${rList&&rList.hid}`}>
                    <EditOutlined className="edit-icon" title="Edit" />
                </Link> */}
                    {rLoading ? (
                        <p>Loading...</p>
                    ) : (
                        rList && (
                            <div className='horoscope-item' >
                                <div class="container" ><h5 class="card-text-title">{t('label_tmpl_group_details')} <span>| {t('label_group_id_name_desc')}</span></h5></div>
                                {/* <h2>{rList.author}</h2> */}
                                <div class="active-details">
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_group_id")}</Typography.Title>}
                                            name="st"
                                        >
                                            <Typography.Text level={5} className='typ'>{rList.gid}</Typography.Text>
                                        </Form.Item>

                                    </div>
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_group_name")}</Typography.Title>}
                                            name="group_name"
                                        >

                                            {editable ? (
                                                <Input style={{ height: '30px' }}
                                                    defaultValue={rList && rList.group_name}
                                                    onChange={handleName}
                                                />
                                            ) : (

                                                <Typography.Text level={5} className='typ'>{rList.group_name}</Typography.Text>
                                            )}
                                        </Form.Item>

                                    </div>


                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_rank")}</Typography.Title>}
                                            name="grank"
                                        >

                                            {editable ? (
                                                <Input style={{ height: '30px' }}
                                                    defaultValue={rList && rList.grank}
                                                    onChange={handleRank}
                                                />
                                            ) : (

                                                <Typography.Text level={5} className='typ'>{rList.grank}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div className="tiles-designs">
                                        <Form.Item
                                            name="lang"
                                            label={<Typography.Title level={4}>{t("label_lang")}</Typography.Title>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select a language.',
                                                },
                                            ]}
                                        >
                                            <Typography.Text level={5} className="typ">
                                                {currentLang}
                                            </Typography.Text>
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("Description")}</Typography.Title>}
                                            name="descr"
                                        >
                                            {editable ? (
                                                <TextArea
                                                    autoSize={{ minRows: 4, maxRows: 10 }}
                                                    style={{ width: '50', minWidth: '500px', maxWidth: '100%' }}
                                                    defaultValue={sanitizeText(rList && rList.descr)}
                                                    onChange={handleDescr}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ' style={{ whiteSpace: 'pre-wrap' }}>
                                                    {sanitizeText(rList && rList.descr)}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                </div>
                                <div class="container" ><h5 class="card-text-title">Gallary <span>| Contains Poster, Invitation card, Circular etc...</span></h5>
                                    <div class="active-details">
                                        <div class="tiles-designs" >
                                            <div className='detail-images ' style={{ marginTop: '2px' }}>
                                                {rList.images != null && rList.images.length > 0 ? (
                                                    <div className="tiles-grid">
                                                        <Image.PreviewGroup allowClose={true} maskClosable={true}
                                                            mask={false}>
                                                            {rList.images.map((image, index) => (
                                                                <div key={index}>
                                                                    <Image
                                                                        className="card-img"
                                                                        src={getImageAPI() + image.imgpath}
                                                                        alt="images"
                                                                        style={{
                                                                            width: '100%',
                                                                            height: '200px',
                                                                            marginBottom: '20px',
                                                                        }}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </Image.PreviewGroup>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <img
                                                            className="card-img"
                                                            alt="temples"
                                                            src={deftemples}
                                                            style={{
                                                                width: '20%',
                                                                height: '20%',
                                                                marginBottom: '2px',
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default GroupDetails;
