import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, message, Row, Col, Image, Result, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addFavourites, fetchContributions, fetchFavourites, fetchGodNames, fetchResArticles, fetchResAudios, fetchResLivetv, fetchResMantras, fetchResVideos, fetchResourceFavourites, fetchTemplesList, postBooksFavourites, postFavourites, removeBooksFavourites, removeFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { getAudioAPI, getImageAPI } from "../../../../http-common";
import deftemples from '../../../../assets/images/templeicon.jpg';
import { getArticlesError, getArticlesList, getArticlesLoading, getAudios, getAudiosError, getAudiosLoading, getFavouritesError, getFavouritesList, getFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getMantras, getMantrasError, getMantrasLoading, getResArticles, getResArticlesError, getResArticlesLoading, getResAudiosError, getResAudiosList, getResAudiosLoading, getResLivetvError, getResLivetvList, getResLivetvLoading, getResVideosError, getResVideosList, getResVideosLoading, getResourceFavouritesList, getResourceFavouritesLoading, getTemplesList, getTemplesListError, getTemplesListLoading, getVideosError, getVideosLoading } from '../../../../redux/selectors/selectors';
import Error from '../error';
import Footer from '../footer/footer';
import YouTube from './youtube';

let last_rec = 0;

const ResourceFavourite = () => {
    const records_per_page = 15;
    const [filterId, setFilterId] = useState(null);
    const localEnv = false;
    const [isLoading, setIsLoading] = useState(false);
    const [did, setDid] = useState(0);
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const dispatch = useDispatch();
    const { fList, fLoading, fError } = useSelector(state => ({
        fList: getResourceFavouritesList(state),
        fLoading: getResourceFavouritesLoading(state),
        fError: getFavouritesError(state),
    }));
    const { aList, aLoading, aError } = useSelector(state => ({
        aList: getResArticles(state),
        aLoading: getResArticlesLoading(state),
        aError: getResArticlesError(state),
    }));

    const { mList, mLoading, mError } = useSelector(state => ({
        mList: getMantras(state),
        mLoading: getMantrasLoading(state),
        mError: getMantrasError(state),
    }));

    const { audioList, audioLoading, audioError } = useSelector(state => ({
        audioList: getResAudiosList(state),
        audioLoading: getResAudiosLoading(state),
        audioError: getResAudiosError(state),
    }));

    const { lList, lLoading, lError } = useSelector(state => ({
        lList: getResLivetvList(state),
        lLoading: getResLivetvLoading(state),
        lError: getResLivetvError(state),
    }));

    const { vList, vLoading, vError } = useSelector(state => ({
        vList: getResVideosList(state),
        vLoading: getResVideosLoading(state),
        vError: getResVideosError(state),
    }));


console.log(lList)

    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage]);

    const [filled, setFilled] = useState({}); // initialize as an empty object
    const [refresh, setRefresh] = useState(false);
    const getToken = Boolean(localStorage.getItem('token'));
    const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Removed from favourites',
        });
    };

    const failure = (msg) => {
        messageApi.open({
            type: 'error',
            content: 'Please Login to see additional features',
        });
    };

    function toggleFilled(id) {
        setFilled(prevFilled => ({
            ...prevFilled,
            [id]: !prevFilled[id]
        }));
    }

    function deleteFavourites(deleteId) {
        dispatch(removeBooksFavourites(deleteId, failure));
        success();
    }

    useEffect(() => {
        dispatch(fetchResourceFavourites('books', storedLanguage, last_rec));
    }, [storedLanguage, refresh]);

    function addFavourites(rid) {
        dispatch(postBooksFavourites(rid, failure))
    }

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'));
    }, []);

    function getStyle(godCategoryId) {
        if (godCategoryId === did) {
            return {
                color: '#4096ff',
                borderColor: '#4096ff',
            };
        } else {
            return {
                borderColor: '#d9d9d9',
                color: '#000',
            };
        }
    }

    const nextTemplesList = async () => {
        setIsLoading(true);
        last_rec = last_rec + records_per_page;
        dispatch(fetchFavourites(storedLanguage, last_rec));
    }

    function prevTemplesList() {
        last_rec = last_rec - records_per_page;
        last_rec = (last_rec < 0) ? 0 : last_rec;
        dispatch(fetchFavourites(storedLanguage, last_rec));
        window.scrollTo(0, 0);
    };

    const storeTempleDetails = (temple) => {
        const recentlyViewedTemples = JSON.parse(localStorage.getItem('recentlyViewedTemples')) || [];
        const updatedTemples = [temple, ...recentlyViewedTemples.slice(0, 10)]; // Keep only the latest 10 recently viewed temples
        localStorage.setItem('recentlyViewedTemples', JSON.stringify(updatedTemples));
    };

    let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.tid);

    function toggleFavorite(temple) {
        const index = favList.findIndex(t => t === temple.rid);
        if (index === -1) {
            addFavourites(temple.rid, temple.tname);
            setGetFavoriteTemples(!getFavouriteTemples);
            if (getToken) {
                success(temple.tname);
            }
        } else {
            deleteFavourites(temple.rid, temple.tname);
            setGetFavoriteTemples(!getFavouriteTemples);
            setRefresh(!refresh);
        }

    }
    const temple = fList;

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleNav = () => {
        // navigate(`/templeDetails/${temple.tid}?prevPage=${window.location.pathname}`)
    };

    const [activeKey, setActiveKey] = useState('1');

    const onChange = (key) => {
        setActiveKey(key);
    };

    function getYouTubeVideoId(url) {
        // This function extracts the YouTube video ID from a YouTube URL
        const regex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/;
        const match = url.match(regex);
        if (match && match[1]) {
            return match[1];
        } else {
            return null;
        }
    }

    const renderTiles = (loading, resourceType, list) => {
        // Ensure list is always an array (in case it's null or undefined)
        list = list || [];
    
        // Check if the resource type is 'livetv' or 'videos'
        const isVideoOrLiveTV = resourceType === 'livetv' || resourceType === 'videos';
        const isAudio = resourceType === 'audios';
    
        return (
            <div className="container search">
                {loading ? (
                    <Error />
                ) : (
                    <div className="tiles-main">
                        {list.length === 0 ? (
                            <Result
                                status="404"
                                title="No Data Found"
                                subTitle={`Sorry, no favourite ${resourceType} found.`}
                            />
                        ) : (
                            // Conditionally render YouTube component for LiveTV and Videos
                            isVideoOrLiveTV ? (
                                list.map((resource) => (
                                    <YouTube
                                        key={resource.rid}
                                        temple={resource}
                                        toggleFavorite={toggleFavorite}
                                        toggleFilled={toggleFilled}
                                        favList={favList}
                                        getYouTubeVideoId={getYouTubeVideoId}
                                    />
                                ))
                            ) : (
                               
                                isAudio ? (
                                    list.map((resource, index) => (
                                        <div
                                            key={resource.rid}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                margin: '20px 0',
                                            }}
                                        >
                                            <audio
                                                id={'song00' + index}
                                                controls="controls"
                                                onLoadedMetadata={(event) =>
                                                    console.log("MP3 duration:", event.target.duration)
                                                }
                                            >
                                                <source
                                                    src={`${getAudioAPI()}${resource.audio_path}`}
                                                    type="audio/mpeg"
                                                />
                                            </audio>
                                        </div>
                                    ))
                                ) : (
                                    // Render tiles for other resource types like Books, Articles, Mantras, etc.
                                    list.map((resource) => (
                                        <div className="tiles-design" key={resource.rid}>
                                            <Link to={`/editResource/${resource.rid}`}>
                                                {resource.img_path ? (
                                                    <div
                                                        className="card-img"
                                                        style={{
                                                            backgroundImage: `url(${getImageAPI()}${resource.img_path})`
                                                        }}
                                                    ></div>
                                                ) : (
                                                    <div className="title-img-bg">
                                                        <img
                                                            className="card-img"
                                                            alt="resource"
                                                            src={deftemples}
                                                        />
                                                    </div>
                                                )}
                                                <div className="tile-text">
                                                    <div className="p-2">
                                                        <h6
                                                            className="home-temple-text"
                                                            title={resource.title}
                                                        >
                                                            {resource.title}
                                                        </h6>
                                                        <p className="mb-1 f-grey tile-add">
                                                            {resource.res_type}, {resource.author}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Link>
                                            <div className="tile-quicklink">
                                                <i
                                                    title="Location"
                                                    className="fa-solid fa-location-dot"
                                                ></i>
                                                <Button
                                                    className="share-button"
                                                    type="link"
                                                    onClick={() => {
                                                        if (navigator.share) {
                                                            navigator
                                                                .share({
                                                                    title: resource.title,
                                                                    text: `Check out this ${resource.res_type}!`,
                                                                    url: `https://www.templeswiki.com/editResource/${resource.rid}`
                                                                })
                                                                .then(() =>
                                                                    console.warn(
                                                                        "Share successful."
                                                                    )
                                                                )
                                                                .catch((error) =>
                                                                    console.error(
                                                                        "Error sharing:",
                                                                        error
                                                                    )
                                                                );
                                                        } else {
                                                            console.warn(
                                                                "Web Share API not supported."
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <i
                                                        title="Share"
                                                        className="fa-solid fa-share-nodes"
                                                    ></i>
                                                </Button>
                                                {favList && (
                                                    <Button
                                                        className="temple-fav-icon"
                                                        type="link"
                                                        onClick={() => {
                                                            toggleFavorite(
                                                                resource,
                                                                resource.title
                                                            );
                                                        }}
                                                        icon={
                                                            favList.some(
                                                                (t) => t === resource.rid
                                                            ) ? (
                                                                <i
                                                                    className="fa-solid fa-heart"
                                                                    onClick={() =>
                                                                        toggleFilled(resource.rid)
                                                                    }
                                                                ></i>
                                                            ) : (
                                                                <i
                                                                    className="fa-solid fa-heart"
                                                                    onClick={() =>
                                                                        deleteFavourites(
                                                                            resource.rid
                                                                        )
                                                                    }
                                                                ></i>
                                                            )
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    ))
                                )
                            )
                        )}
                    </div>
                )}
            </div>
        );
    };
    
    
    


    useEffect(() => {
        if (activeKey === '1') {
            dispatch(fetchResourceFavourites('books', storedLanguage, last_rec));
        } else if (activeKey === '2') {
            dispatch(fetchResArticles(storedLanguage, last_rec));
        } else if (activeKey === '3') {
            dispatch(fetchResMantras(storedLanguage, last_rec));
        }
        else if (activeKey === '4') {
            dispatch(fetchResAudios(storedLanguage, last_rec));
        }
        else if (activeKey === '5') {
            dispatch(fetchResLivetv(storedLanguage, last_rec));
        }
        else if (activeKey === '6') {
            dispatch(fetchResVideos(storedLanguage, last_rec));
        }
    }, [activeKey, storedLanguage, last_rec, dispatch]);

    const LIBRARY_ITEMS = [
        {
            key: '1',
            label: 'Books',
            children: renderTiles(fLoading, 'books', fList),
        },
        {
            key: '2',
            label: 'Articles',
            children: renderTiles(aLoading, 'articles', aList), 
        },
        {
            key: '3',
            label: 'Mantras',
            children: renderTiles(mLoading, 'mantras', mList), 
        },
        {
            key: '4',
            label: 'Audios',
            children: renderTiles(audioError, 'audios', audioList), 
        },
        {
            key: '5',
            label: 'LiveTV',
            children: renderTiles(lLoading, 'livetv', lList), 
        },
        {
            key: '6',
            label: 'Videos',
            children: renderTiles(vLoading, 'videos', vList), 
        },
    ];
    
    



    return (
        <div className='mainsec'>
            {contextHolder}
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span className="bc-link">{t("label_library_fav")}</span>
                </div>
            </div>
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>

                <Link style={{ color: 'black' }} to='/'><span class="bc-active-link">{t('Home')}</span></Link> -
                <Link style={{ color: 'black' }} to='/library'><span class="bc-active-link">{t('label_library')}</span></Link> -

                <span class="bc-link">{t('label_library_fav')}</span>
            </div>
            <div class="mainsec-block">
                <div class="container cat-txt ">
                    <div class="cat-detail">
                        <span class="f3 d-flex justify-content-between">
                            <div><b>{t("label_library_fav")}</b> &nbsp; &nbsp;<i class="fa-solid fa-volume-high"></i></div>
                            <div class="detail-but">{t('label_show_more')}</div>
                        </span>
                        <p class="f1">{t('label_this_is_favourite_page')}</p>
                    </div>
                </div>
                <div className='container'>
                    <section className='h-100 d-flex'>


                        <div className="search-rt-sec ">
                            {/* <div class="d-flex search-breadcrumb">
                            <aside>
                                <span class="search-link-main d-none d-sm-block">
                                    <a class="search-link ">Andhra Pradesh</a> &nbsp;-&nbsp;
                                    <a class="search-link">East Godavari</a> &nbsp;-&nbsp;
                                    <span>Kakinada</span> &nbsp;-&nbsp;
                                    <span>( All Gods )</span>&nbsp;
                                </span>
                                <span class="search-link-main d-block d-sm-none">
                                    <span >Kakinada</span> &nbsp;-&nbsp;
                                    <span>( All Gods )</span>&nbsp;
                                </span>
                                <span className='color-yellow'><i class="fa-regular fa-pen-to-square"></i></span>
                            </aside>
                            <div class="d-flex justify-content-center">
                                <span className='select-god'>Near By</span>&nbsp;
                                <span className='select-god'>Recently Viewed</span>&nbsp;
                                <span className='select-god'>{t("label_sel_god")}</span>&nbsp;
                                <span>
                                    <input type="checkbox" /><span>&nbsp;Famous</span>
                                </span>

                            </div>
                        </div> */}
                            <div class="w-100 search-temples-list h-100">
                                <Tabs defaultActiveKey="1" centered items={LIBRARY_ITEMS} onChange={onChange} />
                            </div>
                        </div>
                    </section>
                </div>
                {/* <div class="container" ><h5 class="card-text-title">{t("label_books")} </h5></div>
                <div class="active-details"></div>
                <div className="container search">
                    {(fLoading || fList === null) ? (<Error />) : (
                        <div class="tiles-main">
                            {fList.length === 0 ? (
                                <Result
                                    status="404"
                                    title="No Data Found"
                                    subTitle="Sorry, no favourite temples found."
                                />
                            ) : (
                                fList.map(temple => (
                                    <div class="tiles-design" key={temple.tid}>
                                        <div>
                                            <Link to={`/editResource/${temple.rid}`}>
                                                {temple.imgpath ? (
                                                    <div className="card-img" style={{ backgroundImage: `url(${getImageAPI()}${temple.imgpath})` }}></div>
                                                ) : (
                                                    <div class="title-img-bg"><img className="card-img" alt="temples" src={deftemples} /></div>
                                                )}
                                                <div class="tile-text">
                                                    <div className="p-2">
                                                        <h6 className="home-temple-text" title={temple.title}>{temple.title}</h6>
                                                        <p className="mb-1 f-grey tile-add">{temple.res_type}, {temple.author}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                            <div class="tile-quicklink">
                                                <i title="Location" class="fa-solid fa-location-dot"></i>
                                                <Button
                                                    className="share-button"
                                                    type="link"
                                                    onClick={() => {
                                                        if (navigator.share) {
                                                            navigator
                                                                .share({
                                                                    title: temple.tname,
                                                                    text: "Check out this temple!",
                                                                    url: `https://www.templeswiki.com/editResource/${temple.rid}`
                                                                })
                                                                .then(() => console.warn("Share successful."))
                                                                .catch((error) => console.error("Error sharing:", error));
                                                        } else {
                                                            console.warn("Web Share API not supported.");
                                                        }
                                                    }}
                                                >
                                                    <i title="Share" class="fa-solid fa-share-nodes"></i>
                                                </Button>
                                                {favList && <Button
                                                    className="temple-fav-icon"
                                                    type="link"
                                                    onClick={() => {
                                                        toggleFavorite(temple, temple.title);
                                                    }}
                                                    icon={
                                                        favList.some(t => t === temple.rid) ? (
                                                            <i className="fa-solid fa-heart" onClick={() => toggleFilled(temple.rid)} ></i>
                                                        ) : (
                                                            <i className="fa-solid fa-heart" onClick={() => deleteFavourites(temple.rid)}></i>
                                                        )
                                                    }
                                                />}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </div>
                <div class="container" ><h5 class="card-text-title">{t("label_articles")} </h5></div>
                <div class="active-details"></div>
                <div className="container search">
                    {(fLoading || fList === null) ? (<Error />) : (
                        <div class="tiles-main">
                            {fList.length === 0 ? (
                                <Result
                                    status="404"
                                    title="No Data Found"
                                    subTitle="Sorry, no favourite temples found."
                                />
                            ) : (
                                fList.map(temple => (
                                    <div class="tiles-design" key={temple.tid}>
                                        <div>
                                            <Link to={`/editResource/${temple.rid}`}>
                                                {temple.imgpath ? (
                                                    <div className="card-img" style={{ backgroundImage: `url(${getImageAPI()}${temple.imgpath})` }}></div>
                                                ) : (
                                                    <div class="title-img-bg"><img className="card-img" alt="temples" src={deftemples} /></div>
                                                )}
                                                <div class="tile-text">
                                                    <div className="p-2">
                                                        <h6 className="home-temple-text" title={temple.title}>{temple.title}</h6>
                                                        <p className="mb-1 f-grey tile-add">{temple.res_type}, {temple.author}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                            <div class="tile-quicklink">
                                                <i title="Location" class="fa-solid fa-location-dot"></i>
                                                <Button
                                                    className="share-button"
                                                    type="link"
                                                    onClick={() => {
                                                        if (navigator.share) {
                                                            navigator
                                                                .share({
                                                                    title: temple.tname,
                                                                    text: "Check out this temple!",
                                                                    url: `https://www.templeswiki.com/templeDetails/${temple.tid}`
                                                                })
                                                                .then(() => console.warn("Share successful."))
                                                                .catch((error) => console.error("Error sharing:", error));
                                                        } else {
                                                            console.warn("Web Share API not supported.");
                                                        }
                                                    }}
                                                >
                                                    <i title="Share" class="fa-solid fa-share-nodes"></i>
                                                </Button>
                                                {favList && <Button
                                                    className="temple-fav-icon"
                                                    type="link"
                                                    onClick={() => {
                                                        toggleFavorite(temple, temple.title);
                                                    }}
                                                    icon={
                                                        favList.some(t => t === temple.tid) ? (
                                                            <i className="fa-solid fa-heart" onClick={() => toggleFilled(temple.tid)} ></i>
                                                        ) : (
                                                            <i className="fa-solid fa-heart" onClick={() => deleteFavourites(temple.tid)}></i>
                                                        )
                                                    }
                                                />}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </div> */}
                {/* <div className="container search">
                    {(fLoading || fList === null) ? (<Error />) : (
                        <div class="tiles-main">
                            {fList.length === 0 ? (
                                <Result
                                    status="404"
                                    title="No Data Found"
                                    subTitle="Sorry, no favourite temples found."
                                />
                            ) : (
                                fList.map(temple => (
                                    <div class="tiles-design" key={temple.tid}>
                                        <div>
                                            <Link to={`/editResource/${temple.rid}`}>
                                                {temple.imgpath ? (
                                                    <div className="card-img" style={{ backgroundImage: `url(${getImageAPI()}${temple.imgpath})` }}></div>
                                                ) : (
                                                    <div class="title-img-bg"><img className="card-img" alt="temples" src={deftemples} /></div>
                                                )}
                                                <div class="tile-text">
                                                    <div className="p-2">
                                                        <h6 className="home-temple-text" title={temple.title}>{temple.title}</h6>
                                                        <p className="mb-1 f-grey tile-add">{temple.res_type}, {temple.author}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                            <div class="tile-quicklink">
                                                <i title="Location" class="fa-solid fa-location-dot"></i>
                                                <Button
                                                    className="share-button"
                                                    type="link"
                                                    onClick={() => {
                                                        if (navigator.share) {
                                                            navigator
                                                                .share({
                                                                    title: temple.tname,
                                                                    text: "Check out this temple!",
                                                                    url: `https://www.templeswiki.com/templeDetails/${temple.tid}`
                                                                })
                                                                .then(() => console.warn("Share successful."))
                                                                .catch((error) => console.error("Error sharing:", error));
                                                        } else {
                                                            console.warn("Web Share API not supported.");
                                                        }
                                                    }}
                                                >
                                                    <i title="Share" class="fa-solid fa-share-nodes"></i>
                                                </Button>
                                                {favList && <Button
                                                    className="temple-fav-icon"
                                                    type="link"
                                                    onClick={() => {
                                                        toggleFavorite(temple, temple.title);
                                                    }}
                                                    icon={
                                                        favList.some(t => t === temple.tid) ? (
                                                            <i className="fa-solid fa-heart" onClick={() => toggleFilled(temple.tid)} ></i>
                                                        ) : (
                                                            <i className="fa-solid fa-heart" onClick={() => deleteFavourites(temple.tid)}></i>
                                                        )
                                                    }
                                                />}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </div> */}
                <div className="next-row">
                    {fList && fList.length > 0 && (
                        <>
                            <Col>
                                <Button
                                    className="button_move"
                                    onClick={() => prevTemplesList()}
                                    disabled={last_rec === 0}
                                >
                                    Prev
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    className="button_move"
                                    onClick={() => nextTemplesList()}
                                    disabled={fList.length < 15}
                                >
                                    Next
                                </Button>
                            </Col>
                        </>
                    )}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default ResourceFavourite;
