import {
    Button, Form, Input, Typography, message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Footer from '../pages/Homepage/footer/footer';
import { postAddTempleAdmin, fetchContributions } from '../../redux/actions/acions';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';

const AddTempleAd = () => {
    const { t } = useTranslation();
    const [tid, setTid] = useState('');
    const [userid, setUserid] = useState('');
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'));
        dispatch(fetchContributions(0, failure));
    }, [dispatch]);

    const success = () => {
        showToast('success', t('label_addtemple_AD_success'));
        form.resetFields();
    };

    const failure = (msg) => {
        showToast('fail', t('label_addtemple_AD_failure'));
    };

    const handleModalSubmit = () => {
        dispatch(postAddTempleAdmin(tid, userid, success));
    };

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        
        form.resetFields();
    };

    

    return (
        <>
            
            <div className='main'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_add_temple_admin')}</span>
                </div>
                <div></div>
            </div>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link> -
                    &nbsp; <span className="bc-link">{t("label_add_temple_admin")}</span>
                </div>
                <div className="bg-details-highlight">
                    <div className="details-headertxt container">
                        <div className="header_txt"> {t('label_add_temple_admin')}</div>
                        <div className="">
                            <a onClick={handleCancelClick} class="button_move" style={{ marginRight: '5px' }}>
                                {t("label_cancel")}
                            </a>
                            <a type="primary" class="button_move" onClick={handleModalSubmit}>
                                Save
                            </a>
                        </div>
                    </div>
                </div>

                <div className="mainsec-block-addtemple-detailspage">
                    <div className='container'>
                        <Form form={form} name="nest-messages">
                            <div className="float-left w-100 mt-4">
                                <h5 className="card-text-title">{t("label_details")} <span>| Contains Temple ID, User ID etc</span></h5>
                            </div>
                            <div className="active-details">
                                <div className="tiles-designs">
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t('label_temple_id')}</Typography.Title>}
                                        name="templeid"
                                        rules={[
                                            { required: true, message: 'Please enter TempleID' },
                                        ]}
                                    >
                                        <Input
                                            value={tid}
                                            onChange={(e) => setTid(e.target.value)}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="tiles-designs">
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t('label_user_id')}</Typography.Title>}
                                        name="userid"
                                        rules={[
                                            { required: true, message: 'Please enter UserID' },
                                        ]}
                                    >
                                        <Input
                                            value={userid}
                                            onChange={(e) => setUserid(e.target.value)}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default AddTempleAd;
