import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Image, Modal, Result, Row, Select, Typography, message } from 'antd';
import TempleCard from './card';
import { Link, useNavigate } from 'react-router-dom';
import aum from '../../assets/icons/om.jpg'
import { useDispatch, useSelector } from 'react-redux';
import { getFavouritesError, getFavouritesList, getFavouritesLoading, getTempleFavouritesError, getTempleFavouritesList, getTempleFavouritesLoading } from '../../redux/selectors/selectors';
import { deleteTemple, fetchFavourites, fetchTempleFavourites, postFavourites, removeFavourites } from '../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import Error from '../pages/Homepage/error';
import Footer from '../pages/Homepage/footer/footer';
import NearBy from '../pages/Homepage/Temples/nearby';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';
import TemplesListMap from '../pages/Homepage/Temples/templesListMap';
import Images from './images';
import deityImages from './godImages';
import TemplePreviewModal from './templePreviewModal';

const TemplesPage = ({ title, description, gList, tLoading, templesList, tList, setFilterId, godCtgryList, filterTemplesList, image, filterId, selectedGod, temple, nextTemplesList, prevTemplesList, last_rec, handleDelete, show }) => {

    const { t } = useTranslation();
    const records_per_page = 15;

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    }, [])

    function getStyle(did) {
        if (did === filterId) {
            return {

                boxShadow: '#d3d3d3 3px -2px 4px',
                borderRadius: '8px',
                filter: 'saturate(1)',
                border: '3px solid var(--colororange2)',
            };
        } else {
            return {
                border: '1px solid #ddd',
            };
        }
    }

    const storeTempleDetails = (temple) => {
        const recentlyViewedTemples = JSON.parse(localStorage.getItem('recentlyViewedTemples')) || [];
        const updatedTemples = [temple, ...recentlyViewedTemples.slice(0, 10)]; // Keep only the latest 10 recently viewed temples
        localStorage.setItem('recentlyViewedTemples', JSON.stringify(updatedTemples));
    };


    const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const storedLanguage = localStorage.getItem('lng') || 5;
    const [selectedFilterGod, setSelectedFilterGod] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [isGodModalVisible, setIsGodModalVisible] = useState(false);

    const handleFilterGodClick = () => {
        if (window.location.pathname.includes('/templeGroup')) {
            setHideGodSection(false);
        }
        else {
            setIsGodModalVisible(true);
        }
    };

    const handleFilterGodCancel = () => {
        setIsGodModalVisible(false);
    };

    const success = (tname) => {
        showToast('success', `Added ${tname} to favorites`)

    };
    const warning = (tname) => {
        showToast('info', `Removed ${tname} from favourites`)

    };

    const failure = () => {
        showToast('error', t(`label_fav_error`))

    };

    const dispatch = useDispatch();
    const { fList, fLoading, fError } = useSelector(state => ({
        fList: getTempleFavouritesList(state),
        fLoading: getTempleFavouritesLoading(state),
        fError: getTempleFavouritesError(state),
    }));


    // useEffect(() => {
    //     if (getToken) {
    //         dispatch(fetchTempleFavourites());
    //     }
    // }, [getToken]);

    useEffect(() => {
        if (getToken) {
            const cachedFavourites = JSON.parse(localStorage.getItem('favourites'));
            if (cachedFavourites) {
                setFavList(cachedFavourites);
            } else {
                dispatch(fetchTempleFavourites());
            }
        }
    }, [getToken]);

    useEffect(() => {
        if (fList && fList.fav_list) {
            setFavList(fList.fav_list);
            localStorage.setItem('favourites', JSON.stringify(fList.fav_list));
        }
    }, [fList]);


    function addFavourites(id, tname) {
        dispatch(postFavourites(id));
        setFavList(prevFavList => [...prevFavList, id]);
        localStorage.setItem('favourites', JSON.stringify([...favList, id]));
        success(tname);
    }

    function deleteFavourites(id, tname) {
        dispatch(removeFavourites(id));
        setFavList(prevFavList => prevFavList.filter(favId => favId !== id));
        localStorage.setItem('favourites', JSON.stringify(favList.filter(favId => favId !== id)));
        warning(tname);
    }

    const [favList, setFavList] = useState([]);


    // useEffect(() => {
    //     if (fList && fList.fav_list) {
    //         setFavList(fList.fav_list);
    //     }
    // }, [fList]);


    // let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.tid);

    const getToken = Boolean(localStorage.getItem('token'));

    function toggleFavorite(temple) {
        if (getToken) {
            const templeId = temple.tid;
            if (favList.includes(templeId)) {
                // Temple is in favList, remove it
                deleteFavourites(templeId, temple.tname);
                // warning(temple.tname);
                // Update favList by removing the temple ID
                // setFavList(prevFavList => prevFavList.filter(id => id !== templeId));
            } else {
                // Temple is not in favList, add it
                addFavourites(templeId, temple.tname);
                // success(temple.tname);
                // Update favList by adding the temple ID
                // setFavList(prevFavList => [...prevFavList, templeId]);
            }
        } else {
            // Handle the case when there's no token (e.g., show a message or redirect to login)
            failure("Please log in to favorite temples");
        }
    }


    const [filled, setFilled] = useState({});

    function toggleFilled(id) {
        setFilled(prevFilled => ({
            ...prevFilled,
            [id]: !prevFilled[id]
        }));
    }

    // const tid = temple&&temple.tid;

    // function handleDelete(temple) {
    //     const templeId = temple.tid;
    //     dispatch(deleteTemple(118006, temple.ludt, failure))
    // }

    // const isTempleInFavorites = (templeId) => {
    //     return favList.includes(templeId);
    // }

    const god = {
        1: 'Ganesha',
        2: 'Vishnu',
        3: 'Lakshmi',
        4: 'Durga',
        5: 'Sri Rama',
        6: 'Shiva',
        7: 'Krishna',
        8: 'Kartikeya',
        9: 'Hanuma',
        10: 'Brahma',
        11: 'Saraswati',
        12: 'Ayyappa',
        13: 'Saibaba',
        14: 'Narasimha',
        15: 'Surya',
        16: 'Budha',
        17: 'Naga',
    };

    const isGroup = () => {
        // Replace the following logic with your own to determine the page type
        const isGroupPage = window.location.pathname.includes('/templeGroup');

        // Return true if the location button should be visible, false otherwise
        return (isGroupPage);
    };

    const displayList = isGroup() ? null : godCtgryList;

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const [hideGodSection, setHideGodSection] = useState(false);
    const [hideNext, setHideNext] = useState(false);

    useEffect(() => {
        if (title === 'Famous Temples') {
            setHideGodSection(true);
        }
        if (title === 'Australia' || 'Thailand') {
            setHideGodSection(true);
        }
        // if (title === 'Temple Group') {
        //     setHideGodSection(false);
        // }
        else {
            setHideGodSection(false);
        }
    }, [title]);

    useEffect(() => {
        if (title === 'Recently viewed Temples') {
            setHideNext(true);
        }
    })

    useEffect(() => {
        if (tList && tList.length > 0) {
            // Scroll to the top of the page after the component renders or when tList changes
            window.scrollTo(10, 10);
        }
    }, [tList]);

    const isNearbyVisible = () => {
        // Replace the following logic with your own to determine the page type
        const isNearbyPage = window.location.pathname.includes('/nearby');


        // Return true if the edit button should be visible, false otherwise
        return getToken ? (isNearbyPage) : false;
    };

    function handleGoBack() {
        setFilterId(null);
        localStorage.removeItem('filterId');
        navigate(-1)
    }

    const navigate = useNavigate()


    const [modalVisible, setModalVisible] = useState(false);
    const [iconsVisible, setIconsVisible] = useState(false);
    const toggleIcons = () => setIconsVisible(!iconsVisible);
    // Function to open the modal
    const openModal = () => {
        setModalVisible(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setModalVisible(false);
    };

    const isLocationButtonVisible = () => {
        // Replace the following logic with your own to determine the page type
        const isBookPage = window.location.pathname.includes('/books');
        const isLiveTVPage = window.location.pathname.includes('/templeDetails');
        const isArticlesPage = window.location.pathname.includes('/articles');
        const isMantrasPage = window.location.pathname.includes('/mantras');

        // Return true if the location button should be visible, false otherwise
        return !(isBookPage || isLiveTVPage || isArticlesPage || isMantrasPage);
    };

    const [isPreviewModalVisible, setPreviewModalVisible] = useState(false);

    const openPreviewModal = () => {
        setPreviewModalVisible(true);
    };

    const closePreviewModal = () => {
        setPreviewModalVisible(false);
    };

    const handleClick = () => {
        // Check if the link corresponds to the books or articles page
        // if (target === '_blank') {
        //     // If it does, do nothing else
        //     return;
        // }
        // For other pages, execute storeTempleDetails
        storeTempleDetails(temple);
    };

    return (
        <div class="mainsec">
            {contextHolder}
            {/*  */}
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span className="bc-link">{title}</span>
                   
                     <i className="fa-solid fa-location-dot" onClick={openModal}></i>
                </div>

                <div class="right-sec-mob-nav d-flex align-items-center">

                  
                    {window.location.pathname !== '/templeGroup' && (
                        <button className="declineButton god-filter-btn" onClick={handleFilterGodClick}>{t("label_sel_god")}
                        <i class="fa-solid fa-om animate__animated animate__heartBeat" style={{ display:'block'}}></i>
                        
                        {filterId && (
                        <>
                            <div className="detail-container">
                                <span className="god-button">{god[filterId]}</span>
                                <CloseCircleOutlined
                                    className="close-icon"
                                    onClick={() => setFilterId(null)}
                                />
                            </div>
                        </>
                    )}
                    {filterId === null && (
                        <div className="detail-container d-flex align-items-center">
                            <span className="detail-but-god ml-2">{t("label_all")}</span>
                        </div>
                    )}
                        
                        </button>
                       
                    )}
               

                     </div>
            </div>

            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>
                <div class="d-flex">
                    {/* <Link to='/' style={{ color: 'black' }} className='search-back'><i class="fa-solid fa-angle-left"></i></Link> */}
                    <span >
                        <Link style={{ color: 'black' }} to='/' onClick={handleGoBack}>
                            <span className="bc-active-link">{t('Home')}</span>
                        </Link> -{' '}
                        <Link style={{ color: 'black' }} to="/templeCtgry" onClick={handleGoBack}>
                            <span className="bc-active-link">{t('label_temples_list')}</span>
                        </Link> -{' '}
                    </span>
                    {isNearbyVisible() && (
                        <>
                            <span onClick={show} className="bc-active-link">{t('label_map_location')}</span>
                            - {' '}
                        </>
                    )}
                    <span className="bc-link mr-2">{title}</span>{' '}
                    |&nbsp; <i className="fa-solid fa-location-dot ml-2 f2" onClick={openModal}></i>&nbsp;
                    |&nbsp;
                    
                    {!window.location.pathname.startsWith('/templeGroup') && (
                        <>
                         <button className="declineButton god-filter-btn" onClick={handleFilterGodClick}>{t("label_sel_god")}
                        <i class="fa-solid fa-om animate__animated animate__heartBeat" style={{ display:'block'}}></i>
                        
                        {filterId && (
                        <>
                            <div className="detail-container">
                                <span className="god-button">{god[filterId]}</span>
                                <CloseCircleOutlined
                                    className="close-icon"
                                    onClick={() => setFilterId(null)}
                                />
                            </div>
                        </>
                    )}
                    {filterId === null && (
                       <div className="detail-container d-flex align-items-center">
                       <span className="detail-but-god ml-2">{t("label_all")}</span>
                   </div>
                    )}
                        
                        </button>
                         
                        </>
                    )}

                  
                </div>
            </div>
            {hideGodSection && (
                <>
                    <div class="popup-bg" style={{ display: isGodModalVisible ? 'flex' : 'none' }}>
                        <div class="popup-main-filter animate__animated animate__fadeInUp ">
                            <div class="popup-main-header-filter">
                                <span class="popup-main-header-text-filter">Filters </span>
                                <div class="popup-close" onClick={handleFilterGodCancel}><i class="fa-solid fa-xmark"></i></div>
                                <div class="popup-back" onClick={handleFilterGodCancel}><i class="fa-solid fa-chevron-left"></i></div>
                            </div>
                            <div class="popup-main-container-filter">
                                <span className="f13-fw-600 d-block float-left">{t('label_god_categ')}</span>
                                <div class="d-block float-left content-filter">
                                    <div className=" god-category-list">
                                        {/* Add the "All" option */}
                                        <div class="all-gods-sec">
                                            {t("label_all")}
                                        </div>
                                        <div class="god_select">
                                            <div class="god_pic">
                                                <img
                                                    style={getStyle(null)}
                                                    className="dietyImg"
                                                    src={aum}
                                                    alt="All"
                                                    onClick={() => {

                                                        filterTemplesList(null);
                                                        setIsGodModalVisible(false);
                                                    }}
                                                />
                                                {/* <p>All</p> */}
                                            </div>
                                        </div>
                                        <div class="all-gods-sec">
                                            {t('label_filter_by_god')}
                                        </div>
                                        {/* Display other gods in rows */}
                                        <Row gutter={[16, 16]}>
                                            {displayList &&
                                                displayList.map((item, index) => (
                                                    <Col key={item.avatar}>
                                                        <div className="god_select">
                                                            <div className="god_pic">
                                                                <img
                                                                    style={getStyle(item.avatar)}
                                                                    src={deityImages[item.avatar]} // Choose the correct image
                                                                    onClick={() => {
                                                                        filterTemplesList(item.avatar);
                                                                        setIsGodModalVisible(false);
                                                                    }}
                                                                />
                                                            </div>
                                                            <p>{item.deity}</p>
                                                        </div>
                                                    </Col>
                                                ))}
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            <div class="popup-main-footer">
                                <a className="button_move" onClick={handleFilterGodCancel}>
                                    {t("label_cancel")}
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* <Modal visible={isGodModalVisible} footer={null} >
                        <div className="diag-header">
                            {t('label_god_categ')}
                            <span class="diag-close">
                                <i class="fa-solid fa-xmark" onClick={handleFilterGodCancel}></i>
                            </span>
                        </div>

                        <div className="dialog-content god-category-list">
                            
                            <div class="all-gods-sec">
                                {t("label_all")}
                            </div>
                            <div class="god_select">
                                <div class="god_pic">
                                    <img
                                        style={getStyle(null)}
                                        className="dietyImg"
                                        src={aum}
                                        alt="All"
                                        onClick={() => {

                                            filterTemplesList(null);
                                            setIsGodModalVisible(false);
                                        }}
                                    />
                                   
                                </div>
                            </div>
                            <div class="all-gods-sec">
                                {t('label_filter_by_god')}
                            </div>
                            
                            <Row gutter={[16, 16]}>
                                {displayList &&
                                    displayList.map((item, index) => (
                                        <Col key={item.avatar}>
                                            <div className="god_select">
                                                <div className="god_pic">
                                                    <img
                                                        style={getStyle(item.avatar)}
                                                        src={deityImages[item.avatar]} // Choose the correct image
                                                        onClick={() => {
                                                            filterTemplesList(item.avatar);
                                                            setIsGodModalVisible(false);
                                                        }}
                                                    />
                                                </div>
                                                <p>{item.deity}</p>
                                            </div>
                                        </Col>
                                    ))}
                            </Row>
                        </div>
                    </Modal> */}
                </>
            )}

            <div class="mainsec-block">
                {/* {!hideGodSection && (<div class="gods-section">
                    <span class="filter-god-txt">{t("label_filter_by_god")}</span>
                    {godCtgryListLoading && godCtgryListLoading ? (
                        <p>Loading...</p>
                    ) : (<div style={{ display: 'flex' }}>
                        <div class="god_select">
                            <div class="god_pic">

                                <Image style={getStyle(null)} className='dietyImg' src={aum} alt="All"
                                    onClick={() => {
                                        setFilterId(null);
                                    }}
                                />
                            </div>
                        </div>

                        {godCtgryList && godCtgryList.map(godCtgry => (
                            <div class="god_select">

                                <div class="god_pic" key={godCtgry.avatar}>

                                    <Image style={getStyle(godCtgry.avatar)} src={deityImages[godCtgry.avatar]}
                                        onClick={() => {
                                            filterTemplesList(godCtgry.avatar);

                                        }}
                                    />

                                    <p>{godCtgry.diety}</p>
                                </div>
                            </div>
                        ))}

                    </div>
                    )}
                </div>
                )} */}


                <div className={`container cat-txt`}>
                    <div className="cat-detail">
                        <span className="f3 d-flex justify-content-between">
                            <div>
                                <b>{`About ${title}`}</b> &nbsp; &nbsp;<i className="fa-solid fa-volume-high"></i>
                            </div>

                            {description && description.length >= 50 && (
                                <div className="detail-but" onClick={showModal}>
                                    {t('label_show_more')}
                                </div>
                            )}
                        </span>
                        <p className="f1" >{description ? description.split("<BR>").join("\n") : ''}</p>

                        <div class="popup-bg" style={{ display: isModalVisible ? 'flex' : 'none' }}>
                            <div class="popup-main animate__animated animate__fadeInUp ">
                                <div class="popup-main-header">
                                    <span class="popup-main-header-text">{`About ${title}`}</span>
                                    <div class="popup-close" onClick={handleCancel}><i class="fa-solid fa-xmark"></i></div>
                                    <div class="popup-back" onClick={handleCancel}><i class="fa-solid fa-chevron-left"></i></div>
                                </div>
                                <div class="popup-main-container">
                                    <div class="d-block htinner-popup-main-container">

                                        <div >
                                            <img className="grp-descr-img" src={image} alt="Image Alt Text" />
                                        </div>
                                        <div class="diag-image-content" style={{ whiteSpace: "pre-wrap" }}>{description ? description.split("<BR>").join("\n") : ''}</div>

                                    </div>
                                </div>
                                <div class="popup-main-footer">
                                    <a className="button_move" onClick={handleCancel}>
                                        {t("label_cancel")}
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* <Modal
                            visible={isModalVisible}
                            onCancel={handleCancel}
                            footer={null}
                        >
                            <div className="diag-header">
                                {`About ${title}`}
                                <span className="diag-close">
                                    <i className="fa-solid fa-xmark" onClick={handleCancel}></i>
                                </span>
                            </div>

                            <div className="dialog-content cat-discription font_main ">
                                <div >
                                    <img className="grp-descr-img" src={image} alt="Image Alt Text" />
                                </div>
                                <div class="diag-image-content" style={{ whiteSpace: "pre-wrap" }}>{description ? description.split("<BR>").join("\n") : ''}</div>
                            </div>
                            <div className="dialog-buttons-footer">
                                <Button className="login-form-button" onClick={handleCancel}>
                                    {t("label_cancel")}
                                </Button>
                            </div>
                        </Modal> */}
                    </div></div>
                <div className="tile-quicklinks">
                    {/* {isLocationButtonVisible() && (
                        <i class="fa fa-list-alt" aria-hidden="true" onClick={toggleIcons} style={{marginRight:'8px',fontSize:'20px'}}></i>
                    )}
                    {iconsVisible && (
                        <div className={`additional-icons ${iconsVisible ? 'visible' : ''}`}>
                           
                            
                            <i class="fa fa-bars" aria-hidden="true" style={{fontSize:'20px'}}></i>
                        </div>
                    )} */}
                    <div class="popup-bg" style={{ display: modalVisible ? 'flex' : 'none' }}>
                        <div class="popup-main animate__animated animate__fadeInUp ">
                            <div class="popup-main-header">
                                <span class="popup-main-header-text"> {title}</span>
                                <div class="popup-close" onClick={closeModal}><i class="fa-solid fa-xmark"></i></div>
                                <div class="popup-back" onClick={closeModal}><i class="fa-solid fa-chevron-left"></i></div>
                            </div>
                            <div class="popup-main-container">
                                <div class="d-block htinner-popup-main-container">
                                    <TemplesListMap templesList={templesList} mapContainerStyle={{ width: '100%', height: '55vh' }} />
                                </div>
                            </div>
                            <div class="popup-main-footer">

                                <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { closeModal() }}>
                                    {t("label_cancel")}
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* <Modal
                        visible={modalVisible}
                        onCancel={closeModal}
                        footer={null}
                        width={750}
                    >
                        <div className="diag-header">
                            {title}
                            <span className="diag-close">
                                <i className="fa-solid fa-xmark" onClick={closeModal}></i>
                            </span>
                        </div>
                        <TemplesListMap templesList={templesList} mapContainerStyle={{ width: '100%', height: '60vh' }} />
                    </Modal> */}
                </div>

                <div className="container search">
                    {tLoading ? (
                        <Error />
                    ) : (
                        <div className="tiles-main">
                            {tList && tList.length > 0 ? (
                                tList.map(temple => (
                                    <TempleCard
                                        key={temple.tid}
                                        id={temple.tid}
                                        imgpath={temple.imgpath}
                                        title={temple.tname}
                                        addr1={temple.addr1}
                                        city={temple.city}
                                        state={temple.st}
                                        temple={temple}
                                        favList={favList}
                                        toggleFavorite={toggleFavorite}
                                        toggleFilled={toggleFilled}
                                        storeTempleDetails={storeTempleDetails}
                                        latit={temple.latit}
                                        longi={temple.longi}
                                        link={`/templeDetails/${temple.tid}?prevPage=${window.location.pathname}`}
                                        handleDelete={handleDelete}
                                        handleClick={handleClick}
                                        tList={tList}
                                    />
                                ))
                            ) : (
                                <Result
                                    status="404"
                                    title="No Data Found"
                                    subTitle="Sorry, no temples found."
                                />
                            )}
                        </div>
                    )}
                </div>

                {!hideNext && (
                    <div className="next-row">
                        {tList && tList.length > 0 ? (
                            <Col>
                                <a
                                    className="button_move"
                                    onClick={last_rec === 0 ? null : prevTemplesList}
                                    disabled={last_rec === 0}
                                >
                                    Prev
                                </a>
                            </Col>
                        ) : ''}
                        {tList && tList.length > 0 ? (
                            <Col>
                                <a
                                    className="button_move"
                                    onClick={tList.length < 15 ? null : nextTemplesList}
                                    disabled={tList && tList.length < 15}
                                >
                                    Next
                                </a>
                            </Col>
                        ) : ''}

                        {/* <Col>
                            <a
                                href="#"
                                className="button_move"
                                onClick={(e) => {
                                    if (!tList || tList.length === 0) {
                                        e.preventDefault(); 
                                        return; 
                                    }
                                    openPreviewModal();
                                }}
                                style={{ pointerEvents: !tList || tList.length === 0 ? 'none' : 'auto', opacity: !tList || tList.length === 0 ? 0.5 : 1 }}
                            >
                                Preview
                            </a>
                        </Col> */}


                        {/* <TemplePreviewModal
                            tList={tList && tList}
                            isVisible={isPreviewModalVisible}
                            onClose={closePreviewModal}
                        /> */}
                    </div>
                )}
                <Footer />
            </div>
        </div>
    )
}

export default TemplesPage;